export const YoutubeIcon = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_541_7774)">
      <path
        d="M24.8117 7.36302C24.8117 7.36302 24.572 5.67078 23.8338 4.92773C22.899 3.94979 21.8539 3.94499 21.3745 3.88746C17.9421 3.63818 12.7887 3.63818 12.7887 3.63818H12.7791C12.7791 3.63818 7.6257 3.63818 4.19329 3.88746C3.7139 3.94499 2.66884 3.94979 1.73403 4.92773C0.995778 5.67078 0.760878 7.36302 0.760878 7.36302C0.760878 7.36302 0.511597 9.35248 0.511597 11.3371V13.1972C0.511597 15.1818 0.756084 17.1713 0.756084 17.1713C0.756084 17.1713 0.995778 18.8635 1.72924 19.6066C2.66404 20.5845 3.89128 20.551 4.43778 20.6564C6.40326 20.8434 12.7839 20.9009 12.7839 20.9009C12.7839 20.9009 17.9421 20.8913 21.3745 20.6468C21.8539 20.5893 22.899 20.5845 23.8338 19.6066C24.572 18.8635 24.8117 17.1713 24.8117 17.1713C24.8117 17.1713 25.0562 15.1866 25.0562 13.1972V11.3371C25.0562 9.35248 24.8117 7.36302 24.8117 7.36302ZM10.2479 15.4551V8.55669L16.8779 12.0179L10.2479 15.4551Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_541_7774">
        <rect
          width="24.5446"
          height="24.5446"
          fill="white"
          transform="translate(0.511475)"
        />
      </clipPath>
    </defs>
  </svg>
);
