import { Row, Col, Progress, Alert } from "antd";
import { NLView } from "@newstackdev/iosdk/dist/types";
import { Link } from "react-router-dom";

const KeyVal: NLView<{ title?: string, val?: string | number, units: string }> = ({ title, val, units }) => <Row justify="space-between">
  <Col span={12}>
    {title || ""}
  </Col>
  <Col span={12} className="text-right">
    {val || ""} {units}
  </Col>
</Row>


export const Resource: NLView<{ resource: string, usage: number, max: number, units: string }> = ({ resource, usage, max, units }) => {
  const percent = Math.floor(100 * (usage && max ? usage * 100 / max : 0)) / 100;
  return <>
    <KeyVal title={`${resource} Max`} val={max} units={units} />
    <KeyVal title={`${resource} Used`} val={usage} units={units} />
    <Progress percent={percent} />
    {percent > 80 ? <Alert message={
      <>
        You are running out of {resource}.
        To avoid service interruptions <Link to={`/${resource.toLowerCase()}-market`}>buy more {resource} now</Link></>
    } type="warning" /> : ""}
  </>
}