export enum UserSocials {
  // SOUNDCLOUD = "soundcloud",
  // INSTAGRAM = "instagram",
  TWITTER = "twitter",
  // FACEBOOK = "facebook",
  // PINTEREST = "pinterest", // delete
  // TUMBLR = "tumblr", // phone instagram (gmail twitter - signup only for now)
  // YOUTUBE = "youtube",
  // DISCORD = "discord",
}

export const SOCIAL_MEDIA = [
  // UserSocials.SOUNDCLOUD,
  // UserSocials.INSTAGRAM,
  UserSocials.TWITTER,
  // UserSocials.FACEBOOK,
  // UserSocials.PINTEREST,
  // UserSocials.TUMBLR,
  // UserSocials.YOUTUBE,
  // UserSocials.DISCORD,
];


export enum CryptoNetworks {
  ETHEREUM = "evm/0x1"
}

export const CRYPTO_NETWORKS = {
  // UserSocials.SOUNDCLOUD,
  ETH: CryptoNetworks.ETHEREUM
};

