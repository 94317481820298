import { Row, Col, Tabs, Radio } from "antd";
import { useState, useEffect, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { useActions, useAppState } from "../overmind/overmind";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { SearchWidget } from "../Components/SearchWidget";
import InviteIcon from "../Icons/ConsoleInviteIcon.svg";
import twitterUsers from "./SocialMedia/top.twitter.newgraph.json";
import UserInviteInfoDialog from "./Invite/UserInviteInfoDialog";
import { fischerYates } from "../utils/random";
import { ContentCard } from "../Components/ContentCard";
import FullScreenLoading from "../Components/FullScreenLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingAnimation from "../assets/Icons/loading-animation.gif";
import { UserCardWithPowerup } from "../Components/UserCardWithPowerup";

export const explore = {
  bool: {
    must: [
      { terms: { status: ["registered", "imported", "admitted"] } },
      {
        exists: {
          field: "contentUrl",
        },
      },
    ],
  },
};

const filtersQueries = {
  ["explore"]: explore,
};

export const filterOrderBy = {
  ["Hot"]: "watts",
  ["Recent"]: "lastSessionTime",
  ["Popular"]: "powered",
  ["New"]: "created",
};

export const handleOpenTwitterDM = (inviteHash: string, userId: string) => {
  const text = encodeURIComponent(
    `Hello here is an invite to newsafe.org ${window.location.origin}/signup?invite=${inviteHash}`
  );
  const twitterURL = `https://twitter.com/messages/compose?recipient_id=${userId}&text=${text}`;
  window.open(twitterURL, "_blank");
};

const Members: React.FC<{ mode: "requests" | "invited" }> = ({ mode }) => {
  const state = useAppState();
  const action = useActions();
  const [inviteProcessing, setInviteProcessing] = useState(false);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [inviteDialogVisible, setInviteDialogVisible] = useState(false);
  const [invitedUserHash, setInvitedUserHash] = useState("");
  const [shuffledUsers, setShuffledUsers] = useState([...twitterUsers]);

  const selected10Users = shuffledUsers?.slice(0, 10);

  const [users, setUsers] = useState<UserReadPublicResponse[]>();

  useEffect(() => {
    const loadTwitterUsers = async () => {
      //@ts-ignore
      const u = await state.api.client.outgraph.inviteesList();
      if (!u.data.length) return; // some defaults
      setShuffledUsers(
        //@ts-ignore
        u.data.map((i) => [i.handler || "", i.watts || 0], i.remoteId || 0)
      );
      // return []
    };
    loadTwitterUsers();
  }, []);

  useMemo(() => {
    setShuffledUsers((users) => fischerYates(users));
  }, []);

  const loadInvitees = async () => {
    setIsUsersLoading(true);
    const invitees = (await state.api.client.user.inviteesList()).data
      .value as UserReadPublicResponse[];
    setUsers(invitees);
    setIsUsersLoading(false);
  };

  const loadRequestees = async () => {
    setIsUsersLoading(true);
    const requestees = await action.user.getUserRateRequestList({
      userId: state.api.auth.user.id || "",
    });
    setUsers(requestees);
    setIsUsersLoading(false);
  };

  const handleInvite = async (indexOfSuggestionToRemove: number) => {
    setInviteProcessing(true);
    const responseHash = await action.api.user.invite({
      //@ts-ignore
      userInvite: {
        fullName: selected10Users[indexOfSuggestionToRemove][0] as string,
      },
    });
    setShuffledUsers((users) => [
      ...users.slice(0, indexOfSuggestionToRemove),
      ...users.slice(indexOfSuggestionToRemove + 1),
    ]);
    setInviteProcessing(false);
    if (responseHash) {
      handleOpenTwitterDM(
        responseHash || "",
        selected10Users[indexOfSuggestionToRemove][2] as string
      );
    }
    setInvitedUserHash(responseHash || "");
    setInviteDialogVisible(true);
  };

  useEffect(() => {
    if (mode === "invited") {
      loadInvitees();
    } else if (mode === "requests") {
      loadRequestees();
    }
  }, []);

  return (
    <>
      {!isEmpty(users) ? (
        (users as UserReadPublicResponse[])?.map(
          (user, i) =>
            // (user.fullName || user.twitter) && ( TODO: maybe we need this

            user.id && <UserCardWithPowerup user={user} key={i} />
        )
      ) : isUsersLoading ? (
        <FullScreenLoading />
      ) : (
        <Row justify="center" className="paragraph-1">
          No users
        </Row>
      )}

      {mode === "invited" && (
        <>
          <Row className="paragraph-1 u-margin-top-large u-margin-bottom-medium">
            Suggestions
          </Row>
          {inviteProcessing ? (
            <FullScreenLoading />
          ) : (
            selected10Users.map((twitterUsernameUser, i) => (
              <ContentCard
                title={
                  //@ts-ignore
                  twitterUsernameUser.handler ??
                  (twitterUsernameUser[0] as string)
                }
                onClick={() => handleInvite(i)}
                description={`Invite ${
                  //@ts-ignore
                  twitterUsernameUser.handler ??
                  (twitterUsernameUser[0] as string)
                } and gain Watts when they gain.`}
                buttonTitle="INVITE"
              />
            ))
          )}
          <UserInviteInfoDialog
            visible={inviteDialogVisible}
            hash={invitedUserHash}
            setVisible={setInviteDialogVisible}
            disableFooter
          />
        </>
      )}
    </>
  );
};

const Explore: React.FC<{ filter: string }> = ({ filter }) => {
  const state = useAppState();
  const action = useActions();
  const [page, setPage] = useState("0");
  const [hasMore, setHasMore] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [exploreFilter, setExploreFilter] = useState<
    "New" | "Recent" | "Hot" | "Popular"
  >("New");
  const admitted = state.api.auth.admitted;

  const handleExploreFilter = (
    filter: "New" | "Recent" | "Hot" | "Popular"
  ) => {
    setExploreFilter(filter);
  };

  const [users, setUsers] = useState<UserReadPublicResponse[]>();

  const loadUsers = async (
    exploreFilter: "New" | "Recent" | "Hot" | "Popular",
    page?: string
  ) => {
    setIsUsersLoading(true);
    const loadedUsers = (
      await state.api.client.user.listSearchList({
        q: JSON.stringify(filtersQueries[filter]),
        page: page,
        orderBy: filterOrderBy[exploreFilter],
      })
    ).data.value as UserReadPublicResponse[];

    if (!loadedUsers || loadedUsers?.length < 1) {
      setHasMore(false);
    }
    setPage((page) => `${+page + 1}`);
    setUsers(
      (users) =>
        (users
          ? loadedUsers
            ? [...users, ...loadedUsers]
            : users
          : loadedUsers) as UserReadPublicResponse[]
    );
    setIsUsersLoading(false);
  };

  useEffect(() => {
    setUsers([]);
    loadUsers(exploreFilter, "0");
  }, [exploreFilter]);

  return (
    <Row justify="center" style={{ height: "100%" }}>
      <Col style={{ width: "100%" }}>
        {admitted && (
          <Row className="u-margin-bottom-medium">
            <SearchWidget
              onChange={(v) => {
                action.routing.historyPush({
                  location: `/user/${v}?isConnection=true`,
                });
              }}
            />
          </Row>
        )}
        {filter === "explore" && (
          <Row className="u-margin-bottom-large">
            <ContentCard
              title="Invite Friends"
              description="Invite other creators and gain Watts when they gain."
              onClick={() =>
                action.routing.historyPush({ location: "/invite" })
              }
              buttonTitle="INVITE"
              logoRedirectLink="/invite"
              logoUrl={InviteIcon}
            />
          </Row>
        )}
        {!isEmpty(users) ? (
          <>
            <Radio.Group
              onChange={(e) => {
                handleExploreFilter(e.target.value);
              }}
              defaultValue={exploreFilter}
              className="u-margin-bottom-medium"
            >
              <Radio.Button value="New">New</Radio.Button>
              <Radio.Button value="Popular">Popular</Radio.Button>
              <Radio.Button value="Recent">Recent</Radio.Button>
              <Radio.Button value="Hot">Hot</Radio.Button>
            </Radio.Group>
            <InfiniteScroll
              className="ns-infinite-scroll"
              dataLength={users ? users.length : 0}
              next={() => loadUsers(exploreFilter, page)}
              hasMore={admitted ? hasMore : false}
              loader={
                <div
                  className="u-margin-top-medium"
                  style={{ textAlign: "center" }}
                >
                  <img src={LoadingAnimation} className="loading-icon" />
                </div>
              }
            >
              {users?.map(
                (user, i) =>
                  (filter === "explore" || user.fullName || user.twitter) && (
                    <UserCardWithPowerup user={user} key={i} />
                  )
              )}
            </InfiniteScroll>
          </>
        ) : isUsersLoading ? (
          <FullScreenLoading />
        ) : (
          <Row justify="center" className="paragraph-1">
            No users
          </Row>
        )}
      </Col>
    </Row>
  );
};

export const People = () => {
  const state = useAppState();
  const admitted = state.api.auth.admitted;
  const url = new URLSearchParams(window.location.search);
  const reviewRequests = url.get("reviewRequests") === "true";
  const [activeKey, setActiveKey] = useState(
    reviewRequests ? "requests" : "explore"
  );

  return admitted ? (
    <Tabs
      defaultActiveKey={activeKey}
      type="line"
      onChange={(activeKey) => {
        setActiveKey(activeKey);
      }}
      style={{
        border: "none",
      }}
    >
      <Tabs.TabPane tab="Explore" key="explore">
        <Explore filter={"explore"} key="explore" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Requests" key="requests">
        <Members mode={"requests"} key="requests" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Invited" key="invited">
        <Members mode={"invited"} key="invited" />
      </Tabs.TabPane>
    </Tabs>
  ) : (
    <Explore filter={"explore"} key="explore" />
  );
};
