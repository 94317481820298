import { Row, Col, Button } from "antd";
import { useCallback } from "react";
import { MediaComponent } from "@newstackdev/iosdk/dist/Components/MediaComponents/MediaComponent";
import { TwitterIcon } from "../../assets/Icons/TwitterIcon";
import LogoOnlyIcon from "../../assets/Icons/LogoOnlyIcon";
import { NewEnergy } from "../../assets/Icons/NewEnergy";
import { useAppState } from "../../overmind/overmind";
import { getWattIcon } from "../Powerup";

export const NotificationButton: React.FC<{
  type?: string;
  notificationImg?: string;
  messageMode?: boolean;
  middleIcon?: React.ReactNode;
}> = ({ type, notificationImg, messageMode, middleIcon }) => {
  const state = useAppState();
  const getNotificationIcon = useCallback(
    (type?: string) => {
      switch (type) {
        case "twitter":
          return (
            <TwitterIcon
              style={{ height: 50, width: 50, borderRadius: "50px" }}
            />
          );
        case "powerup-notification": {
          return (
            <img
              src={notificationImg}
              style={{ height: 50, width: 50, borderRadius: "50px" }}
            />
          );
        }
        default:
          return <LogoOnlyIcon height="50" width="50" />;
      }
    },
    [type]
  );

  const getUserIcon = () => (
    <div
      style={{
        height: 50,
        width: 50,
        borderRadius: "50px",
        background: "rgb(66, 66, 66)",
      }}
    >
      <MediaComponent
        style={{
          height: 50,
          width: 50,
          borderRadius: "50px",
        }}
        {...state.api.auth.user}
      />
    </div>
  );

  const userWattIcon = getWattIcon(state.api.auth.user);

  return (
    <Row>
      <Button
        className="ns-notification-button"
        type="primary"
        onClick={() => {}}
        style={{ padding: "10px 10px", background: "white" }}
      >
        <Row justify="space-between" align="middle" gutter={10}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {messageMode ? (
              <img
                style={{ height: "48px", width: "48px" }}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  userWattIcon
                )}`}
              />
            ) : (
              <NewEnergy />
            )}
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {messageMode ? getUserIcon() : getNotificationIcon(type)}
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {middleIcon ?? (
              <svg
                className="flash-icon"
                width="28"
                height="38"
                viewBox="0 0 18 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.201172 15.9855L14.1006 0.99585L9.46747 11.8974H17.6436L1.29132 27.7046L7.83224 15.9855H0.201172Z"
                  fill="black"
                />
              </svg>
            )}
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {messageMode ? getNotificationIcon(type) : getUserIcon()}
          </Col>
        </Row>
      </Button>
    </Row>
  );
};
