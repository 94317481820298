import { Provider } from "overmind-react";
import { overmind } from "./overmind/overmind";
import "antd/dist/antd";
import { ConfigProvider } from "antd";
import "./styles/index.css";

import { NLView } from "@newstackdev/iosdk/dist/types";
import { Auth } from "@newstackdev/iosdk/dist/Pages/Auth/Auth";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Welcome } from "./Pages/Welcome";
import { AppLayout } from "./Components/AppLayout";
import { Helpline } from "./Pages/Helpline";
import { RAMMarket } from "./Pages/RAMMarket";
import { Home } from "./Pages/Home";
import { Quotas } from "./Pages/Quotas";
import { Wallet } from "./Pages/Wallet";
import { Networks } from "./Pages/Networks";
import { Energy } from "./Pages/Energy/Energy";
import { InviteesList, UserInvite } from "./Pages/Invite/UserInvite";
import { EditProfile } from "./Pages/Profile/EditProfile";
import { SignIn } from "./Pages/Auth/SignIn";
import { daoRoutes } from "@newstackdev/iosdk/dist/Pages/Dao";
import { SelectSwapAmount } from "./Pages/Swap/SelectSwapAmount";
import { AccountInfo } from "./Pages/AccountInfo";
import { DomainSelector, Done, LinkHash } from "./Pages/Auth";
import { Product } from "./Pages/Auth/Onboarding/Product";
import { UserCreate } from "./Pages/Auth/Onboarding/UserCreate";
import { OnboardingTypeSelector } from "./Pages/Auth/Onboarding/OnboardingTypeSelector";
import { NotInvited } from "./Pages/Auth/Onboarding/NotInvited";
import { LegacyImport } from "./Pages/Auth/Onboarding/LegacyImport";
import { StakeKiosk } from "./Pages/Stake";
import { OAuthConnect } from "./Pages/Connect";
import { Pool } from "./Pages/Pool";
import { SignOut } from "./iosdk/Pages/SignOut";
import { ActivityStream } from "@newstackdev/iosdk/dist/Components/ActivityStream";
import { GettingStarted } from "./Pages/GettingStarted";
import { BasicOAuthFlow } from "./Pages/Auth/BasicOAuthFlow";
import { Privacy } from "./Pages/Privacy";
import { TOS } from "./Pages/TOS";
import { SignInCrosswalk } from "./Pages/Auth/SignInCrosswalk";
import { Console } from "./Pages/Console";
import { People } from "./Pages/People";
import { UserProfile } from "./Pages/Profile/UserProfile";
import { EVMAuth, EVMContext } from "./Components/Evm";
import InstallPWA from "./Components/PWAInstallPrompt";
import { MetamaskProvider } from "./hooks/useMetamask";

const om = overmind();

export const _App: NLView = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {},
        },
        token: {
          fontFamily: "NewfoundationWhyte-Regular",
          fontSize: 14,
          colorBgLayout: "white",
          colorPrimaryBg: "#fafafa",
          colorLink: "black",
          colorLinkHover: "#101010",
          colorLinkActive: "#333333",
          colorPrimaryBorderHover: "black",
          boxShadow: "none",
        },
      }}
    >
      <MetamaskProvider>
        <Provider value={om}>
          <Router>
            {/* <InstallPWA /> */}
            <AppLayout>
              <Switch>
                <Route
                  key="tos"
                  exact
                  path="/terms_of_service"
                  component={TOS}
                />
                ,
                <Route
                  key="pp"
                  exact
                  path="/privacy_policy"
                  component={Privacy}
                />
                ,
                <Route key="e" exact path="/welcome" component={Welcome} />
                <Route key="c" exact path="/console" component={Console} />
                <Route key="ex" exact path="/" component={Welcome} />
                <Route key="s" exact path="/signout" component={SignOut} />
                <Route key="t" exact path="/signup" component={LinkHash} />
                <Route
                  key="u"
                  exact
                  path="/oauth2/authorize"
                  component={BasicOAuthFlow}
                />
                <Route key="space" exact path="/space" component={RAMMarket} />
                <Route key="quota" exact path="/quota" component={Quotas} />
                <Route
                  key="rm"
                  exact
                  path="/swap/:source/:target"
                  component={SelectSwapAmount}
                />
                <Route key="swap" exact path="/swap" component={StakeKiosk} />
                <Route
                  key="rm"
                  exact
                  path="/swap/:source/:target"
                  component={SelectSwapAmount}
                />
                <Route key="a" exact path="/auth" component={Auth} />
                <Route key="w" exact path="/wallet" component={Wallet} />
                <Route key="h" exact path="/helpline" component={Helpline} />
                <Route key="ho" exact path="/home" component={Home} />
                <Route key="ap" exact path="/networks" component={Networks} />
                {/* <Route key="dao" exact path="/dao" component={Dao} /> */}
                <Route key="e" exact path="/energy" component={Energy} />
                <Route
                  key="oauth"
                  exact
                  path="/connect"
                  component={OAuthConnect}
                />
                <Route key="b" exact path="/pool" component={Pool} />
                <Route key="nw" exact path="/people" component={People} />
                <Route
                  key="ep"
                  exact
                  path="/editProfile"
                  component={EditProfile}
                />
                <Route key="up" exact path="/profile" component={UserProfile} />
                <Route
                  key="upu"
                  path="/user/:username"
                  component={UserProfile}
                />
                {/* <Route key="su" exact path="/signup" component={SignUp} /> */}
                <Route key="si" exact path="/SignIn" component={SignIn} />
                <Route
                  key="sicw"
                  exact
                  path="/SignInCrosswalk"
                  component={SignInCrosswalk}
                />
                <Route key="si" exact path="/account" component={AccountInfo} />
                <Route key="ui" exact path="/invite" component={UserInvite} />
                <Route
                  key="uil"
                  exact
                  path="/inviteesList"
                  component={InviteesList}
                />
                {/** onboarding */}
                <Route
                  key="ms"
                  exact
                  path="/signup/managementService"
                  component={() => <SignInCrosswalk signupMode />}
                />
                <Route
                  key="sud"
                  exact
                  path="/signup/domain"
                  component={DomainSelector}
                />
                <Route
                  key="suwp"
                  exact
                  path="/signup/phoneVerification"
                  component={() => <SignIn signupMode />}
                />
                <Route
                  key="sus"
                  exact
                  path="/signup/subscribe"
                  component={Product}
                />
                <Route
                  key="suc"
                  exact
                  path="/signup/create"
                  component={UserCreate}
                />
                <Route key="sudn" exact path="/signup/done" component={Done} />
                <Route
                  key="suots"
                  exact
                  path="/signup/metamask"
                  component={OnboardingTypeSelector}
                />
                <Route key="suots" exact path="/evm" component={EVMAuth} />
                <Route
                  key="suni"
                  exact
                  path="/signup/notInvited"
                  component={NotInvited}
                />
                <Route
                  key="ue"
                  exact
                  path="/auth/newlife-members"
                  component={LegacyImport}
                />
                <Route
                  key="as"
                  exact
                  path="/activity/stream"
                  component={ActivityStream}
                />
                <Route
                  key="as"
                  exact
                  path="/getting-started"
                  component={GettingStarted}
                />
                <>{daoRoutes}</>
              </Switch>
            </AppLayout>
          </Router>
        </Provider>
      </MetamaskProvider>
    </ConfigProvider>
  );
};

export const App = () => (
  <EVMContext>
    <_App />
  </EVMContext>
);

export default App;
