import React from "react";
import CountUp from "react-countup";
// import { WattIconLeft } from "../../assets/Icons/WattIconLeft";
// import { WattIconRight } from "../../assets/Icons/WattIconRight";
import { getWattIcon } from "../Powerup";

const styles = {
  ratingBarWrapper: {
    width: "100%",
    top: "5vh",
    display: "flex",
    zIndex: 1000,
    border: "1px solid #C6C6C6",
    height: "70px",
    borderRadius: "60px",
    padding: "5px",
  },

  ratingBar: {
    height: "58px",
    borderRadius: "50px",
    background:
      "linear-gradient(180deg, #E3FF93 0%, #F3FFD1 0.01%, #BAE835 100%)",
    border:
      "1px solid linear-gradient(180deg, #E3FF93 0%, #F3FFD1 0.01%, #BAE835 100%)",
    transition: "width 0.1s, opacity 0.3s",
  },

  ratingBarInfo: {
    position: "absolute" as any,
    left: "50%",
    transform: "translate(-50%, 0)",
    height: "58px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  leftWattIcon: {
    position: "absolute" as any,
    top: "50%",
    left: "6%",
    margin: "auto",
    transform: "translateY(-50%)",
    display: "flex",
    alignItems: "center",
    height: "27px",
    width: "30px",
  },

  rightWattIcon: {
    position: "absolute" as any,
    top: "50%",
    right: "6%",
    margin: "auto",
    transform: "translateY(-50%)",
    display: "flex",
    alignItems: "center",
    height: "27px",
    width: "30px",
  },
};

export const ProgressBar: React.FC<{
  percentage: number;
  wattAmount: number;
  prevWattAmount: number;
  animate?: boolean;
  onEnd?: () => void;
  onStart?: () => void;
}> = ({ percentage, prevWattAmount, wattAmount, animate, onEnd, onStart }) => {
  const wattIcons = getWattIcon(undefined, {
    currentLevel: Math.floor(wattAmount / 10),
    nextLevel: Math.floor(wattAmount / 10) + 1,
  });

  const wattIconLeft = wattIcons.currentLevel;
  const wattIconRight = wattIcons.nextLevel;

  return (
    <div style={styles.ratingBarWrapper}>
      <img
        style={styles.leftWattIcon}
        src={`data:image/svg+xml;utf8,${encodeURIComponent(wattIconLeft)}`}
      />
      <div
        className="rating-bar"
        style={{
          ...styles.ratingBar,
          opacity: [0].includes(percentage) ? 0 : 100,
          width: `${percentage || 0}%`,
        }}
      ></div>
      <div className="paragraph-1" style={styles.ratingBarInfo}>
        <div>
          {animate ? (
            <CountUp
              useEasing
              end={wattAmount}
              decimals={6}
              decimal="."
              onStart={onStart}
              onEnd={onEnd}
            />
          ) : (
            prevWattAmount.toFixed(6) ?? 0
          )}{" "}
          WATT
        </div>
      </div>
      <img
        style={styles.rightWattIcon}
        src={`data:image/svg+xml;utf8,${encodeURIComponent(wattIconRight)}`}
      />
    </div>
  );
};
