import { useActions, useAppState } from "../overmind/overmind";
import { NLView } from "@newstackdev/iosdk/dist/types";
import { Layout, Row, Col, Drawer, Button } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Hamburger } from "../assets/Icons/Hamburger";
import { CloseIcon } from "../assets/Icons/CloseIcon";
import { MainMenu } from "./MainMenu";
import Logo from "../assets/Icons/Logo.svg";
import { MediaComponent } from "@newstackdev/iosdk/dist/Components/MediaComponents/MediaComponent";
import { useCachedUser } from "@newstackdev/iosdk/dist/hooks/useCached1";
import FullScreenLoading from "./FullScreenLoading";
import { useMediaQuery } from "react-responsive";
import HomePageIcons from "../Icons/homepageIcons.svg";
import { Footer } from "./Footer";

const { Header, Sider, Content } = Layout;

export const TwolineSandwich = () => (
  <div style={{}}>
    <div
      style={{
        minHeight: 15,
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
      }}
    ></div>
  </div>
);

export const AppLayout: NLView = ({ children }) => {
  const state = useAppState();
  const actions = useActions();
  const history = useHistory();
  const user =
    state.api.auth.user?.username ||
    (state.api.auth.admitted && state.api.auth.user.id); // || state.firebase.user?.phoneNumber;
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });

  useEffect(() => {
    actions.routing.setHistory({ history });
    const p: any = new URLSearchParams(state.routing.location.slice(2));
    const path = p.get("path");
    if (path) {
      actions.routing.historyPush({ location: path });
    }
  }, []);

  const attempted = state.api?.auth?.attempted;

  const signupInProgress = state.routing.location.match(/^\/signup/);

  const u = useCachedUser({ username: state.api?.auth?.user?.username }, true);
  const admitted = state.api?.auth?.admitted;
  return (
    <>
      <Header>
        <Row justify="space-between" style={{ height: "44px" }}>
          {/* {broken && <Col span={1} className="text-center"><MenuFoldOutlined onClick={() => setOpen(!open)} /></Col>} */}

          <Link to={admitted ? "/console" : "/welcome"}>
            <Row
              style={{ gap: "5px" }}
              align="middle"
              justify="center"
              className="ns-header-logo"
            >
              <img src={Logo} />
              <div
                style={{
                  fontSize: "20px",
                  fontFamily: "NewfoundationWhyte-Light",
                }}
              >
                Newsafe
              </div>
            </Row>
          </Link>
          {/* {admitted && isMobile && (
            <Col>
              <div
                style={{
                  display: "flex",
                }}
              >
                {getBreadCrumb(state.routing.location, isMobile)}
              </div>
            </Col>
          )} */}
          <Col>
            {user ? (
              <Row align="middle">
                {!isMobile && (
                  <Link to="/profile">
                    <Row align="middle">
                      <div className="small-profile-icon">
                        <MediaComponent {...u} />
                      </div>
                    </Row>
                  </Link>
                )}
                {isMobile && <MobileDrawer addmitted={admitted} />}
                {/* <Button onClick={() => actions.auth.logout()}>Sign out</Button> */}
              </Row>
            ) : isMobile ? (
              <MobileDrawer addmitted={admitted} />
            ) : (
              <Row gutter={12}>
                {!(
                  state.api.auth.user.id ||
                  signupInProgress ||
                  state.auth.authenticated
                ) ? (
                  <>
                    <img
                      src={HomePageIcons}
                      alt={HomePageIcons}
                      className="u-margin-right-small"
                    />
                    <Link to="/SignInCrosswalk">
                      <Button
                        type="primary"
                        style={{ width: "110px", height: "40px", padding: 0 }}
                      >
                        Sign in
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Col>
                      <Link to="/signout">
                        {signupInProgress ? "Return to start" : "Sign Out"}
                      </Link>
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Header>
      <Layout>
        {!isMobile ? (
          <Sider
            style={{
              position: "static",
              zIndex: 1000,
              marginTop: "114px",
            }}
          >
            <MainMenu admitted={admitted} />
          </Sider>
        ) : (
          <></>
        )}
        <Content style={{ paddingBottom: "40px" }}>
          <div className="main-content horizontal-margins-auto">
            {attempted || state.routing.isAllowed ? (
              children
            ) : (
              <FullScreenLoading />
            )}
          </div>
          {window.location.pathname === "/" && <Footer />}
        </Content>
      </Layout>
    </>
  );
};

const MobileDrawer = ({ addmitted }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Hamburger onClick={showDrawer} />
      {/* @ts-ignore */}
      <Drawer
        placement="left"
        onClose={onClose}
        visible={open}
        closable
        destroyOnClose
        title={<img src={Logo} height={"25px"} width={"25px"} />}
        closeIcon={<CloseIcon />}
        className="ns-drawer"

        // extra={<LogoOnlyIcon width="50px" height="50px" />}
      >
        <Sider>
          <MainMenu onClose={onClose} admitted={addmitted} isMobile />
        </Sider>
      </Drawer>
    </>
  );
};
