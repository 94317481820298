export const Eth = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 38">
      <g id="Group 7200">
        <path
          id="XMLID_8_"
          d="M10.5085 0.577148L4.76709 10.657L10.5085 14.2634L16.2362 10.6228L10.5085 0.577148Z"
          stroke="black"
          stroke-width="0.684702"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="XMLID_31_"
          d="M10.5083 20.4226V16.043"
          stroke="black"
          stroke-width="0.684702"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="XMLID_12_"
          d="M10.508 20.4225L4.7666 12.4366L10.508 16.0429L16.2357 12.4023L10.508 20.4225Z"
          stroke="black"
          stroke-width="0.684702"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="XMLID_30_"
          d="M10.508 7.96777L4.7666 10.6571L10.508 14.2634L16.2357 10.6229L10.508 7.96777Z"
          stroke="black"
          stroke-width="0.684702"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="XMLID_32_"
          d="M10.5083 0.577148V14.2634"
          stroke="black"
          stroke-width="0.684702"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="XMLID_192_"
          d="M10.5085 0.577148L4.76709 10.657L10.5085 14.2634L16.2362 10.6228L10.5085 0.577148Z"
          stroke="black"
          stroke-width="0.684702"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);
