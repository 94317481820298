import { Tag, Checkbox } from "antd";
import { useActions, useAppState } from "../../overmind/overmind";
import { NLView } from "@newstackdev/iosdk/dist/types";
import { Row, Col, Button } from "antd";
import { useCachedApplication } from "../../hooks/useCached";
import { json } from "overmind";
import { useState, useEffect } from "react";

export const BasicOAuthFlow: NLView = () => {
  const state = useAppState();
  const actions = useActions();
  const user =
    state.api.auth.user?.username || state.firebase.user?.phoneNumber;
  const userHasUsername = !!state.api.auth.user?.username;

  const [remember, setRemember] = useState(true);

  const sp = new URLSearchParams(
    (
      window.localStorage.getItem("redirectAuthRequest") ||
      state.newgraphAuth.originalQuery ||
      window.location.search
    ).replace(/^\??/, "")
  );
  const { requestor, referer, redirectUrl } = {
    requestor: sp.get("requestor") || "",
    referer: sp.get("referer") || "",
    redirectUrl: sp.get("redirectUrl") || "",
  };

  useEffect(() => {
    window.localStorage.removeItem("newsafe-auth-redirect-request");
  }, []);

  const app = useCachedApplication({ owner: requestor }, true);
  const goToRedirectUrl = () => {
    (window as any).location =
      redirectUrl + "?newsafe_jwt=" + state.newgraphAuth.sessionToken.jwt; // security hole, must be checked against user
  };
  const authorize = async () => {
    if (remember)
      await actions.newgraphAuth.approveAppAccess({
        username: app.author?.username || "",
      });

    await actions.newgraphAuth.generateSessionToken({
      referer,
      appOwner: requestor,
      redirectUrl,
      scopes: json(app.consumerScopes || []),
    });

    window.localStorage.removeItem("redirectAuthRequest");

    goToRedirectUrl();
  };

  const env = state.config.env.env;
  const _newlifeUrl = env == "prod" ? "" : `${env}.`;
  const newlifeUrl = `${_newlifeUrl}newlife.io`;

  const isRedirect =
    (requestor || referer || redirectUrl) &&
    !(requestor && referer && redirectUrl);

  useEffect(() => {
    const app = state.newgraphAuth.approvedApps[requestor]; //?.expiry;
    if (app && app.expiry > new Date().toISOString()) authorize();
  }, [Object.keys(state.newgraphAuth.approvedApps).length, requestor]);

  if (isRedirect)
    return <>Invalid redirect, likely the sign in link is wrong.</>;

  if (isRedirect && !app.id)
    return <>Loading information about the referrer url {redirectUrl}...</>;

  <>
    <p className="header-1 text-center">Welcome!</p>
    <p className="header-2 text-center">Please authenticate</p>

    <div className="text-center">
      <Button
        // style={{ fontSize: 30, height: 60, marginTop: 60 }}
        onClick={() =>
          actions.routing.historyPush({ location: "/SignInCrosswalk" })
        }
      >
        Authenticate
      </Button>
    </div>
  </>;
  if (userHasUsername) {
    console.log(state);
    debugger;
  }
  if (userHasUsername && (!requestor || !referer || !redirectUrl)) {
    setTimeout(() => {
      actions.routing.historyPush({ location: "/console" });
    });
    return <></>;
  }

  if (userHasUsername && !(requestor && referer && redirectUrl))
    return (
      <>
        Bad request
        <br />
        go back to where you came from and try again.
      </>
    );

  if (!userHasUsername && user) {
    actions.routing.historyPush({ location: "/signout" });
  }

  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        /*backgroundColor: "#f5f5f5"*/ padding: 10,
      }}
    >
      <div
        style={{
          maxWidth: "600px",
          borderRadius: 30,
          background: "white",
          padding: 30,
          margin: "30px auto",
          boxShadow: "0px 0px 15px #cccccc",
          textAlign: "center",
        }}
      >
        <div className="header-1b">
          <b>{app.name}</b>
        </div>
        <div className="paragraph-1">operated by {app.author?.username}</div>
        <br />
        <div className="header-2">
          <b></b> is requesting access to your personal data:
          {/* , pin codes for your credit cards and private keys for your crypto accounts. */}
        </div>
        <div style={{ fontSize: "1.5em" }}>
          {app.consumerScopes?.map((scope) => (
            <Tag>{scope}</Tag>
          ))}
        </div>

        <br />
        <b>
          Clicking Authorize will generate an access token and redirect you to{" "}
          {referer} allowing it access to your data.{" "}
        </b>
        <br />
        <br />
        <Checkbox checked={true} onChange={(e) => setRemember(e.target.value)}>
          Trust {requestor} for 30 days
        </Checkbox>
        {/* { JSON.stringify(state.newgraphAuth.sessionToken) } */}
        <br />
        <br />
        <Row
          style={{ margin: "auto" }}
          align="top"
          className="text-center"
          justify="space-between"
        >
          <Col>
            <Button
              style={{ padding: 10, width: "90px" }}
              className="font-large"
              onClick={() => authorize()}
            >
              Authorize
            </Button>
          </Col>
          <Col>
            <Button
              style={{ padding: 10, width: "90px" }}
              className="font-large"
              onClick={() => goToRedirectUrl()}
            >
              Reject
            </Button>
          </Col>
          <Col>
            <Button
              style={{ padding: 10, width: "90px" }}
              className="font-large"
              onClick={() => {
                actions.routing.historyPush({
                  location: "/helpline",
                });
              }}
            >
              Info
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
