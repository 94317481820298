import { Input } from "antd";
import { UserCreateRequest } from "@newstackdev/iosdk-newgraph-client-js";
import { useEffect, useState } from "react";
import Form from "antd/lib/form";
import { ContentLayout } from "@newstackdev/iosdk/dist/Components/ContentLayout";
import { CrossCircle } from "../../../assets/Icons/CrossCircle";
import { EmbeddableControl, NLView } from "@newstackdev/iosdk/dist/types";
import { FieldData } from "rc-field-form/lib/interface";
import { ProgressButton } from "@newstackdev/iosdk/dist/Components/ProgressButton";
import { RowCheckbox } from "../../../Components/RowCheckbox";
import { useActions, useAppState } from "../../../overmind/overmind";
import { useForm } from "antd/lib/form/Form";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import { IOnboarding } from "@newstackdev/iosdk/dist/overmind/flows/user/onboarding/state";

const defaultCreateFormValues = [
  "consentEmail",
  "consentTestgroup",
  "description",
  "discord",
  "displayName",
  "email",
  "facebook",
  "id",
  "instagram",
  "pinterest",
  "soundcloud",
  "tumblr",
  "twitter",
  "website",
  "youtube",
];

export const CrossCircleErr: NLView<{ children?: JSX.Element }> = ({
  children,
}) => {
  return (
    <>
      {children}
      <div className="error-circle-form ">
        <CrossCircle />
      </div>
    </>
  );
};

export const UserCreate: NLView<
  EmbeddableControl & {
    hideUsername?: boolean;
    noRouing?: boolean;
  }
> = ({ hideUsername, noRouing, embedded, setNext }) => {
  const state = useAppState();
  const actions = useActions();
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);
  const [formErrors, setFormErrors] = useState(0);

  const [form] = useForm();

  const username = state.onboarding.form.username;

  useEffect(() => {
    actions.routing.setBreadcrumbs([{ text: "Create your profile" }]);
    // For imported users, if they have email, they should not be able to change it
    setIsEmailAvailable(!isEmpty(state.onboarding.form.email));
    actions.api.user.getCurrent();
  }, []);

  useEffect(() => {
    const u = state.api.auth.user;
    const f = state.onboarding.form;
    // actions.onboarding.updateForm(u);
    const upd = Object.keys(u)
      .filter((k) => u[k] && !f[k])
      .reduce((r, c) => ({ ...r, [c]: u[c] }), {});
    form.setFieldsValue(upd);
    // actions.onboarding.updateForm(state.api.auth.user);
  }, [state.api.auth.user]);

  const setNextEmbedded = () => {
    (!state.api.auth.user?.username || state.onboarding.legacyToken) && //["invited", "imported", "known"].includes(state.api.auth.user.status || "") &&
      setNext &&
      setNext({
        text: "Next",
        command: () => form.submit(),
      });

    return () => setNext && setNext(undefined);
  };

  const sf = state.onboarding.form;

  useEffect(setNextEmbedded, [sf]);

  const onFinish = async (values: IOnboarding["form"]) => {
    const defaultUser = {
      username: "",
      newcoinTicker: "",
      displayName: "",
      email: "",
      description: "",
      website: "",
      twitter: "",
      discord: "",
      instagram: "",
      tumblr: "",
      soundcloud: "",
      consentPrivacyPolicy: "",
      phone: "",
      legacyToken: "",
    };

    console.log("Creating:", values);

    try {
      await form.validateFields();
    } catch (e: any) {
      console.log(e.errorFields.length);
      setFormErrors(e.errorFields.length);
      return;
    }

    if (state.onboarding.metamaskFlow) {
      await actions.api.user.update({
        //@ts-ignore
        user: {
          ...pick(state.api.auth.user, defaultCreateFormValues),
          id: state.api.auth.user.id || "",
          ...values,
        },
      });
      await actions.routing.historyPush({ location: "/connect" });
      return;
    } else {
      await actions.onboarding.create({
        noRouting: !!noRouing,
        user: {
          ...defaultUser,
          ...omitBy(values, isNil),
        },
        legacyToken: state.onboarding.legacyToken,
        inviteHash: values.inviteHash,
        couponCode: values.couponCode,
      });
    }

    // actions.routing.historyPush({ location: "/wallet" });
  };

  // const onFinishFailed = (errorInfo: any) => {
  //     console.log('Failed:', errorInfo);
  //     effects.ux.message.error(JSON.stringify(errorInfo))
  // };

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <ContentLayout>
      {/* <p
				className="super-size font-variant-none"
				style={{ marginBottom: "40px" }}
			>
				{username}
			</p> need to find out if we want to keep this*/}

      <div className="nl-onboarding-title text-center u-margin-bottom-mega">
        <div className="header-1 u-margin-bottom-medium">
          Create your profile
        </div>
        <p className="header-2">{username}</p>
      </div>
      <Form
        scrollToFirstError
        name="sign-up-form"
        form={form}
        // labelCol={{ span: 6 }}
        wrapperCol={{ span: 24 }}
        // value={{ state }}
        onFinish={onFinish}
        // noValidate={true}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        onFieldsChange={(_ch, all) => {
          const upd = _ch.reduce(
            (r, c: FieldData) =>
              // @ts-ignore
              ({ ...r, [c.name[0]]: c.value || c.values }),
            sf as Partial<UserCreateRequest>
          );

          actions.onboarding.updateForm(upd as UserCreateRequest);
        }}
        initialValues={sf}
      >
        <Form.Item
          name="couponCode"
          hidden={true}
          rules={[
            {
              // required: !hideUsername,
              // validator: (_, v) => (/^[A-Za-z0-9\.]{4,12}$/.test(v) ? Promise.resolve() : Promise.reject()),
              // validator: (_, v) => (/^[\w](?!.*?\.{2})[\w.]{1,9}[\w]$/.test(v)) ? Promise.resolve() : Promise.reject(),
              // message: "Please input your username!",
            },
          ]}
        >
          <Input readOnly={true} suffix={<CrossCircleErr />} />
        </Form.Item>
        <Form.Item
          name="username"
          hidden={hideUsername}
          rules={[
            {
              required: !hideUsername,
              validator: (_, v) =>
                /^[A-Za-z0-9\.]{4,12}$/.test(v)
                  ? Promise.resolve()
                  : Promise.reject(),
              // validator: (_, v) => (/^[\w](?!.*?\.{2})[\w.]{1,9}[\w]$/.test(v)) ? Promise.resolve() : Promise.reject(),
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            placeholder="username"
            readOnly={true}
            suffix={<CrossCircleErr />}
          />
        </Form.Item>

        <Form.Item
          name="displayName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="name" suffix={<CrossCircleErr />} />
        </Form.Item>
        {/* <Form.Item
					name="firstName"
					rules={[
						{
							required: true,
							message: "Please enter your first name.",
						},
					]}
				>
					<Input placeholder="name" suffix={<CrossCircleErr />} />
				</Form.Item> */}
        <Form.Item
          name="email"
          rules={[
            {
              // required: true,
              pattern: new RegExp(re),
              message: "Please input valid email.",
            },
          ]}
        >
          <Input
            placeholder="email"
            suffix={<CrossCircleErr />}
            disabled={
              isEmailAvailable && state.onboarding.isLegacyUpdateOngoing
            }
          />
        </Form.Item>

        {/* <Form.Item
					name="lastName"
					rules={[
						{
							required: true,
							message: "Please input your last name!",
						},
					]}
				>
					<Input
						placeholder="last name"
						suffix={<CrossCircleErr />}
					/>
				</Form.Item> */}
        <Form.Item name="description">
          <Input.TextArea placeholder="bio" />
        </Form.Item>
        <Form.Item name="website">
          <Input placeholder="website" />
        </Form.Item>
        <Form.Item
          name="consentPrivacyPolicy"
          valuePropName="checked"
          wrapperCol={{ offset: 0, span: 24 }}
          rules={[
            {
              required: true,
              message: "please confirm",
            },
          ]}
        >
          <RowCheckbox>
            <p className="paragraph-2" style={{ margin: 0 }}>
              I agree to Newlife's{" "}
              <span className="paragraph-2u">
                <a href="/privacy_policy" target="_blank">
                  privacy policy
                </a>
              </span>
            </p>
          </RowCheckbox>
        </Form.Item>
        <Form.Item
          name="consentEmail"
          valuePropName="checked"
          wrapperCol={{ offset: 0, span: 24 }}
        >
          <RowCheckbox>
            <p className="paragraph-2" style={{ margin: 0 }}>
              I consent to email communications
            </p>
          </RowCheckbox>
        </Form.Item>
        <Form.Item
          name="consentTestgroup"
          valuePropName="checked"
          wrapperCol={{ offset: 0, span: 24 }}
        >
          <RowCheckbox>
            <p className="paragraph-2" style={{ margin: 0 }}>
              I'd like to join the beta group!
            </p>
          </RowCheckbox>
        </Form.Item>
        {/* <Form.Item
					hidden={!embedded}
					wrapperCol={{ offset: 0, span: 24 }}
				>
					<IndeterminateProgressAction actionName="api.user.create" />
				</Form.Item> */}
        {formErrors > 0 ? "Please fix the errors in the form" : ""}
        <Form.Item hidden={embedded} wrapperCol={{ offset: 8, span: 16 }}>
          <ProgressButton
            actionName="onboarding.create"
            disabledWhileProgress
            htmlType="submit"
            progressText="Creating user..."
            type="primary"
          >
            Submit
          </ProgressButton>
        </Form.Item>
      </Form>
    </ContentLayout>
  );
};

// function logout() {
//     throw new Error("Function not implemented.");
// }
