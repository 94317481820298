import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { useContentImageUrl } from "./MediaComponents/useContentImageUrl";
import { useMemo } from "react";
import plusIcon from "../Icons/plus.svg";

function getRandomColor(): string {
  const colors: string[] = [
    "#CDE0A5",
    "#F49577",
    "#9DA9DF",
    "#645F50",
    "#E9FF91",
    "#D4B1A7",
    "#D2D6D1",
    "#DBCD9A",
  ];
  const randomIndex: number = Math.floor(Math.random() * colors?.length);
  return colors[randomIndex];
}

interface ContentCard {
  coverPictureUrl?: string;
  logoUrl?: string;
  logoRedirectLink?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  buttonTitle?: string;
  customButton?: React.ReactNode;
  contentUrl?: string;
  contentId?: string;
  location?: string;
  externalRedirectLink?: string;
}

export const ContentCard: React.FC<ContentCard> = ({
  coverPictureUrl,
  logoUrl,
  logoRedirectLink,
  title,
  description,
  buttonTitle,
  onClick,
  customButton,
  contentId,
  contentUrl,
  externalRedirectLink,
}) => {
  const userProfileImg =
    contentUrl && contentId
      ? useContentImageUrl({
          id: contentId,
          contentUrl,
        })
      : null;
  const color = useMemo(() => getRandomColor(), []);

  return (
    <Row align="middle" className="ns-contentCard-container">
      {coverPictureUrl && (
        <Row className="ns-contentCard-content">
          {externalRedirectLink ? (
            <a
              style={{
                cursor: "pointer",
              }}
              target="_blank"
              href={externalRedirectLink}
            >
              <img style={{ maxWidth: "100%" }} src={coverPictureUrl} />
            </a>
          ) : (
            <Link
              style={{
                cursor: "pointer",
              }}
              to={logoRedirectLink}
            >
              <img style={{ maxWidth: "100%" }} src={coverPictureUrl} />
            </Link>
          )}
        </Row>
      )}
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", height: "100%" }}
      >
        <Col xs={5} sm={5} className="ns-contentCard-content">
          {externalRedirectLink ? (
            <a style={{ cursor: "pointer" }} href={externalRedirectLink}>
              <div className="ns-contentCard-container__logo">
                {userProfileImg || logoUrl ? (
                  <img
                    src={userProfileImg ?? logoUrl}
                    alt={userProfileImg ?? logoUrl}
                    style={{ height: "100%", width: "100%" }}
                  />
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: color,
                    }}
                  >
                    <img
                      src={plusIcon}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                )}
              </div>
            </a>
          ) : (
            <Link style={{ cursor: "pointer" }} to={logoRedirectLink}>
              <div className="ns-contentCard-container__logo">
                {userProfileImg || logoUrl ? (
                  <img
                    src={userProfileImg ?? logoUrl}
                    alt={userProfileImg ?? logoUrl}
                    style={{ height: "100%", width: "100%" }}
                  />
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: color,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={plusIcon} />
                  </div>
                )}
              </div>
            </Link>
          )}
        </Col>
        <Col
          xs={13}
          sm={13}
          className="text-left ns-contentCard-title__wrapper"
          style={{ paddingRight: "10px" }}
        >
          {externalRedirectLink ? (
            <a
              className="text-black"
              style={{ cursor: "pointer" }}
              href={externalRedirectLink}
              target="_blank"
            >
              <Row className="paragraph-1 u-margin-bottom-xs">{title}</Row>
              <Row>
                <p
                  style={{ color: "#7E7E7E" }}
                  className="paragraph-2 text-ellipsis"
                >
                  {description}
                </p>
              </Row>
            </a>
          ) : (
            <Link
              className="text-black"
              style={{ cursor: "pointer" }}
              to={logoRedirectLink}
            >
              <Row className="paragraph-1 u-margin-bottom-xs">{title}</Row>
              <Row>
                <p
                  style={{ color: "#7E7E7E" }}
                  className="paragraph-2 text-ellipsis"
                >
                  {description}
                </p>
              </Row>
            </Link>
          )}
        </Col>
        <Col
          xs={6}
          sm={6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            height: "100%",
          }}
        >
          {customButton ?? (
            <Button
              className="u-margin-bottom-xs paragraph-3"
              style={{
                height: "26px",
                borderRadius: "40px",
                padding: "6px 9px",
                width: "fit-content",
              }}
              onClick={onClick}
            >
              {buttonTitle}
            </Button>
          )}
        </Col>
      </Row>
    </Row>
  );
};
