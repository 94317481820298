import { Action } from "../../iosdk/overmind/overmind";

import { NCO_BlockchainAPI } from "@newfound8ion/newcoin-sdk";

// export const newcoin = new NCO_BlockchainAPI(NCO_BlockchainAPI.defaults.devnet_urls, NCO_BlockchainAPI.defaults.devnet_services, false, true);

export const testnetUrl = "https://hyperion-dev.newcoin.org";
export const nodeosDevUrl = "https://nodeos-dev.newcoin.org";
export const testnetChainUrl = "https://testnet.newcoin.org";

import { JsonRpc } from "@eoscafe/hyperion";
import { EOSJSAccount } from "src/types";
const rpc = new JsonRpc(testnetUrl);

const getTableDataPost = async (
  endpoint: string,
  req: any,
  nodeos?: boolean
) => {
  const r = await fetch((nodeos ? nodeosDevUrl : testnetUrl) + endpoint, {
    method: "POST",
    body: JSON.stringify(req),
  });
  const res = await r.json();
  return res;
};

const getTableRows = async (req) => {
  return getTableDataPost("/v1/chain/get_table_rows", req, true);
};

const ncproxy = (state, effects) => {
  return state.config.env.env === "prod"
    ? effects.newcoin.newcoinProxyProd
    : effects.newcoin.newcoinProxy;
};
const getTableDataGet = async (endpoint, req) => {
  const p = new URLSearchParams();
  Object.keys(req).forEach((k) => p.set(k, req[k]));

  const r = await fetch(testnetUrl + endpoint + "?" + p.toString());
  const res = await r.json();
  return res;
};

const getMarketStatus: Action = async ({ effects, state }) => {
  const req = { code: "eosio", scope: "eosio", table: "rammarket", json: true };
  const res = await fetch(testnetChainUrl + "/v1/chain/get_table_rows", {
    method: "POST",
    body: JSON.stringify(req),
  });
  state.accountManagement.marketStatus = await res.json();
};

const buyRam: Action<{ amount: number }, any> = async (
  { effects, state, actions },
  { amount }
) => {
  const u = state.api.auth.user?.username || "";
  const resp = await ncproxy(state, effects).buyRam({
    payer_prv_key: `${state.newgraphAuth.sessionToken.jwt.replace(
      /^newsafe /,
      ""
    )}`,
    user: u,
    payer: u,
    ram_amt: amount,
  });

  console.log(resp);

  const username = state.api.auth.user?.username;
  username && actions.accountManagement.getAccount({ username });

  return resp;
  // effects.newcoin.hyperion(hyperionUrl + "/v1/chain/get_table_rows", { "POST", body: req });
};

import { pad } from "@newstackdev/iosdk/dist/utils/pad";
const normalizeQuantity = (q) => {
  const [whole, decimal] = q.split(/\./);
  const padded = pad(decimal || "", 4, "right");
  return [whole, padded].join(".");
};

const unstakeGnco: Action<{ amount: number }, any> = async (
  { effects, state },
  { amount }
) => {
  const u = state.api.auth.user.username || "";
  const amt = normalizeQuantity(amount.toString());
  const resp = await ncproxy(state, effects).instUnstakeMainDAO({
    payer_prv_key: `${state.newgraphAuth.sessionToken.jwt}`,
    payer: u,
    amt: `${amt} GNCO`,
  });

  console.log(resp);
  return resp;
  // effects.newcoin.hyperion(hyperionUrl + "/v1/chain/get_table_rows", { "POST", body: req });
};

const searchUsers: Action<{ q: string }> = async (
  { effects, state },
  { q }
) => {
  const res = await getTableRows({
    json: true,
    code: "pools2.nco",
    scope: "pools2.nco",
    table: "pools",
    table_key: q,
    lower_bound: q,
    upper_bound: `${q}zzz`,
    key_type: "name",
    index_position: "3",
    limit: 5,
  });
  state.accountManagement.search.users = res.rows || [];
};

const getAccount: Action<{ username: string }> = async (
  { effects, state },
  { username }
) => {
  const res = await getTableDataGet("/v2/state/get_account", {
    account: username,
  });

  state.accountManagement.accounts[username] = res || {};
  if (state.api.auth.user.username == username)
    state.accountManagement.auth.account = res || {};
};

const createPool: Action<{ ticker: string }> = async (
  { actions, state, effects },
  { ticker }
) => {
  try {
    await ncproxy(state, effects).createPool({
      ticker,
      owner: state.api.auth.user.username || "",
      owner_prv_active_key: state.newsafe.token.split(/ /)[1],
    });
  } catch (ex) {
    await actions.ux.showNotification({
      message: "Couldn't create pool " + ticker,
    });
  }
};

const onInitializeOvermind: Action = async ({ actions, reaction, state }) => {
  // actions.accountManagement.getMarketStatus();

  reaction(
    (state) => state.newgraphAuth.api,
    async () => {
      await actions.newgraphAuth.generateSessionToken({
        referer: "newgra.ph",
        redirectUrl: window.location.toString(),
        appOwner: "newgraph.io",
        scopes: [],
      });
    }
  );

  reaction(
    (state) => state.api.auth.user?.username,
    async () => {
      const username = state.api.auth.user?.username;
      // username && actions.accountManagement.getAccount({ username });
    }
  );
};

export default {
  actions: {
    searchUsers,
    getMarketStatus,
    buyRam,
    onInitializeOvermind,
    getAccount,
    unstakeGnco,
    createPool,
  },
  state: {
    marketStatus: {} as any,
    auth: {
      account: {} as EOSJSAccount,
    },
    search: {
      users: [] as any,
    },
    accounts: {} as Record<string, EOSJSAccount>,
  },
  effects: {},
};
