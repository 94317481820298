import { Action } from "./types";

const statGetter = <T>(name: string, collector: string, by?: string) => {
    const f: Action<T> = async ({ state, actions }, input) => {
        const hist = await state.api.client.history[name]((by && input ? { [by]: input[by] } : undefined));
        if (by) {
            state.history[collector][input[by]] = hist;
        } else
            state.history[collector] = hist;
    };
    return f;
}

const stakedOutTop: Action = statGetter("stakeOutTopList", "stakedOutTop")
const stakedInTop: Action = statGetter("stakeStakedInTopList", "stakedInTop")

const stakedOutTopCount: Action = statGetter("stakeStakedOutTopCountList", "stakedOutTopCount")
const stakedInTopCount: Action = statGetter("stakeStakedInTopCountList", "stakedInTopCount")
const stakedOutTopSum: Action = statGetter("stakeStakedOutTopSumList", "stakedOutTopSum")
const stakedInTopSum: Action = statGetter("stakeStakedInTopSumList", "stakedInTopSum")
const stakedRecent: Action = statGetter("stakeStakedRecentList", "stakedRecent")

const stakedOutByAccount: Action<{ account: string }> = statGetter("stakeOutByAccountList", "outAccountStats", "account")
const stakedInByTicker: Action<{ ticker: string }> = statGetter("stakeInByTickerList", "inTokenStats", "ticker")


export const history = {
    actions: {
        stakedInByTicker,
        stakedOutByAccount,
        stakedOutTop,
        stakedInTop,

        stakedOutTopCount,
        stakedInTopCount,
        stakedOutTopSum,
        stakedInTopSum,

        stakedRecent
    },
    state: {
        inTokenStats: {} as Record<string, any>,
        outAccountStats: {} as Record<string, any>,
        stakedOutTop: {} as Record<string, any>,
        stakedInTop: {} as Record<string, any>,
        stakedRecent: {} as Record<string, any>
    },
    effects: {}
};