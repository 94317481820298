import {
  PoolInfoDataRow,
  UserStake,
} from "@newstackdev/iosdk/dist/Components/UserWidget";
import {
  useCachedPool,
  useCachedUser,
} from "@newstackdev/iosdk/dist/hooks/useCached";
import { NLView } from "@newstackdev/iosdk/dist/types";
import { useAppState } from "../overmind/overmind";
import { NCPoolInfo } from "@newfound8ion/newcoin-sdk/src/types";
import { Tabs } from "antd";
import { Row, Col, Button } from "antd";
import { Wave } from "../assets/Icons/Wawe";
import { useState, useEffect } from "react";
import { json } from "overmind";
import { Token } from "src/types";
import { MediaComponent } from "@newstackdev/iosdk/dist/Components/MediaComponents/MediaComponent";
import { Link } from "react-router-dom";
import { AccountStats, TokenStats } from "@newstackdev/iosdk-newcoin-stats";
import { useMediaQuery } from "react-responsive";
// import { AccountStats, TokenStats } from "../../../iosdk-newcoin-history/dist";

const PoolLine: NLView<{ tokenInfo: Token }> = ({ tokenInfo }) => {
  const p = useCachedPool({ code: tokenInfo.symbol }, true);
  const u = useCachedUser({ username: p.owner }, true);

  return (
    <Row className="wallet-tokens-row" justify="space-between" align="middle">
      <Col xs={24} md={4}>
        <div className="horizontal-margins-auto">
          <div className="small-profile-icon">
            <MediaComponent {...u} />
          </div>
        </div>
      </Col>
      <Col xs={8} md={4} className="text-left">
        ${tokenInfo.symbol}
      </Col>
      <Col xs={8} md={4} className="text-left">
        {tokenInfo.amount}
      </Col>
      <Col xs={8} md={4} className="text-right">
        {p.owner}
      </Col>
      <Col xs={24} md={4}>
        <div style={{ paddingTop: 15 }}>
          <TokenStats
            width={90}
            height={50}
            ticker={tokenInfo.symbol.toUpperCase()}
          />
        </div>
      </Col>
      <Col xs={24} md={4} className="text-right">
        <Link to={`/swap/GNCO/${tokenInfo.symbol}`}>
          <Button>Swap</Button>
        </Link>
      </Col>

      {/* <UserProfile */}
      {/* {JSON.stringify(p)} */}
    </Row>
  );
};

const emptyArr = [] as any[];

const coreTokens = ["NCO", "GNCO"];
const isCoreToken = (t) => coreTokens.includes(t);

export const Wallet: NLView = () => {
  const state = useAppState();
  const [activeKey, setActiveKey] = useState<string>("1");

  const _tokens = state.accountManagement.auth.account.tokens || emptyArr;
  const [tokens, setTokens] = useState<typeof _tokens>([]);

  const acc = state.accountManagement.auth.account;

  const [total, setTotal] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  console.log("isMobile", isMobile);
  useEffect(() => {
    const t = json(_tokens);
    const tokens = t.filter((t) => !isCoreToken(t.symbol));
    const tot = t.reduce((r, c) => r + c.amount, 0);
    setTokens(t);
    setTotal(tot);
  }, [_tokens]);

  // if (!tokens.length) return <></>;

  const gnco = (state.newcoin.mainPool?.acc_balances || [])[0] || "0 GNCO";
  const gncoAmnt = Number(gnco.split(/ /)[0] || 0);

  const user = state.api.auth.user;
  const username = user.username || "";
  //@ts-ignore
  const watts = user.watts;

  return (
    <div className="text-center">
      <p>Total balance</p>
      <p className="header-1 u-margin-bottom-small">
        ${Math.round(gncoAmnt * 100) / 100}
        {/* {activeKey === "3" ? "$225.82" : "$847.9"} */}
      </p>
      {watts && (
        <p>Your total NewEnergy {Math.round(watts * 100) / 100} watts</p>
      )}

      {/* <p>{activeKey === "3" ? "5.4K $LIFE" : "Total balance"}</p> */}

      <div>
        <AccountStats
          showData={false}
          width={isMobile ? 300 : 600}
          height={150}
          account={username || ""}
        />
      </div>
      <br />

      <Tabs
        defaultActiveKey={activeKey}
        type="card"
        destroyInactiveTabPane={true}
        onChange={(activeKey) => setActiveKey(activeKey)}
      >
        <Tabs.TabPane tab="Tokens" key="1">
          {tokens.map((t) => (
            <PoolLine tokenInfo={t} />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transaction History" key="2">
          {/* {state.api.auth.user.username} */}
          <div className="ns-transaction-history-wrapper">
            <AccountStats
              showData={true}
              showChart={false}
              width={isMobile ? 300 : 600}
              height={150}
              account={state.api.auth.user.username || ""}
            />
          </div>

          {/* <div style={{ position: "relative" }}>
						<Wave />
						<div
							style={{
								position: "absolute",
								top: "50%",
								width: "100%",
							}}
						>
							{Array.from({ length: 10 }, (_, i) => (
								<Row
									style={{
										width: "200px",
										margin: "0 auto",
										justifyContent: "space-between",
									}}
								>
									<p>+15.8 $LIFE</p>
									<p className="text-modest">02.02.2022</p>
								</Row>
							))}
						</div>
					</div> */}
        </Tabs.TabPane>
      </Tabs>
      <div className="text-center text-modest">
        <Link to={"/account"}>Account details</Link>
      </div>
    </div>
  );
};
