import { CollapseProps } from "antd";
import HelpLineContent from "./Helpline.content.json";
import { FAQs } from "../Components/FAQs";

interface IHelpLineContent {
  category: string;
  questions: {
    title: string;
    description: string;
  }[];
}

export const Helpline = () => {
  const helplineContent = HelpLineContent as IHelpLineContent[];

  const content = helplineContent.map((content, i) => {
    const collapseItems: CollapseProps["items"] = content.questions.map(
      (question) => {
        return {
          key: i + question.title,
          label: (
            <p
              style={{
                fontSize: "16px",
                fontFamily: "NewfoundationWhyte-Medium",
                lineHeight: "22px",
              }}
            >
              {question.title}
            </p>
          ),
          children: (
            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "25.5px",
              }}
            >
              {question.description}
            </p>
          ),
        };
      }
    );

    return <FAQs category={content.category} items={collapseItems} />;
  });

  return <div>{content}</div>;
};
