import {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
  useMemo,
} from "react";
import { ethers } from "ethers";

interface MetamaskContextProps {
  isMetamaskInstalled: boolean;
  isMetamaskLoading: boolean;
  isMetamaskConnected: boolean;
  accounts: ethers.JsonRpcSigner[];
  provider: ethers.BrowserProvider;
  connectToMetamask: () => Promise<void>;
}

const MetamaskContext = createContext<MetamaskContextProps>(
  {} as MetamaskContextProps
);

export function useMetamask() {
  return useContext(MetamaskContext);
}

export function MetamaskProvider({ children }: { children: ReactNode }) {
  const [isMetamaskLoading, setIsMetamaskLoading] = useState(false);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);
  const [accounts, setAccounts] = useState<MetamaskContextProps["accounts"]>(
    []
  );
  const provider = useMemo<MetamaskContextProps["provider"]>(
    //@ts-ignore
    () => new ethers.BrowserProvider(window.ethereum, "any"),
    []
  );

  // set necessary states for the metamask wallet on mount
  useEffect(() => {
    !(async function () {
      //@ts-ignore
      if (window.ethereum) {
        setIsMetamaskLoading(true);
        setIsMetamaskInstalled(true);
        const accounts = await provider.listAccounts();
        setAccounts(accounts);
        setIsMetamaskConnected(accounts.length > 0);
        setIsMetamaskLoading(false);
      }
    })();
  }, []);

  // send `eth_requestAccounts` which will show a popup to users to connect their wallet
  async function connectToMetamask() {
    //@ts-ignore
    if (window.ethereum) {
      try {
        //@ts-ignore
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccounts(accounts);
        setIsMetamaskConnected(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Metamask not detected");
    }
  }

  const value = {
    isMetamaskInstalled,
    isMetamaskLoading,
    isMetamaskConnected,
    accounts,
    provider,
    connectToMetamask,
  };

  return (
    <MetamaskContext.Provider value={value}>
      {children}
    </MetamaskContext.Provider>
  );
}
