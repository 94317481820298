import { Link, useParams } from "react-router-dom";
import { IOView } from "@newstackdev/iosdk/dist/types";
import {
	useCachedPool,
	useCachedUser,
} from "@newstackdev/iosdk/dist/hooks/useCached";
import { MediaComponent } from "@newstackdev/iosdk/dist/Components/MediaComponents/MediaComponent";
import {
	PoolInfoDataRow,
	UserNewcoinPoolsParticipation,
	UserStake,
} from "@newstackdev/iosdk/dist/Components/UserWidget";
import { useAppState, useActions } from "../overmind/overmind";
import { Form, Input, Button, Row, Col, Result, Slider } from "antd";
import {
	SearchOutlined,
	CopyOutlined,
	RetweetOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SwapVertical } from "../assets/Icons/SwapVertical";
import { SwapIcon } from "../assets/Icons/SwapIcon";
import { rmunits } from "../functions";
import { Pair } from "./Swap/SelectSwapAmount";
import { mapValues } from "lodash";
// import { NewcoinRecept } from "@newstackdev/iosdk/dist/Components/Recepts";
import { ProgressButton } from "@newstackdev/iosdk/dist/Components/ProgressButton";

export const StakeKiosk: IOView<{
	amount: number;
	priceTotal: number;
	actionName: string;
	stake: () => Promise<void>;
	onCancel?: () => void;
}> = ({ amount, priceTotal, stake, onCancel, actionName }) => {
	const state = useAppState();
	const actions = useActions();

	const { username: usernameParam } = useParams<{ username: string }>();

	const [username, setUsername] = useState("");
	const [_username, _setUsername] = useState("");

	useEffect(() => {
		setUsername(usernameParam || "");
	}, [usernameParam]);

	const pool = useCachedPool({ owner: usernameParam });

	const kioskLink =
		location.protocol + "//" + location.host + "/swap/" + username;

	const user = useCachedUser({ username }, true);

	return (
		<div className="app-content-wrapper">
			{/* {JSON.stringify(username)} */}

			<>
				{username ? (
					username && !pool.code ? (
						<Result
							title={`user ${username} not found`}
							extra={`No such user or user has no pool`}
						/>
					) : (
						<Result
							status="success"
							icon={
								<MediaComponent
									style={{ maxWidth: 200 }}
									size="small"
									{...user}
								/>
							}
							extra={
								<>
									{/* {JSON.stringify(user)} */}
									<p className="header-2">{username}</p>
									<PoolInfoDataRow
										pool={{ code: pool.code }}
									/>
									<div className="vertical-space">
										Share this link on your web properties
										to enable stake-based access or request
										support for {username}:
									</div>
									<Input
										value={
											location.protocol +
											"//" +
											location.host +
											"/swap/" +
											username
										}
										suffix={
											<CopyToClipboard
												text={kioskLink}
												onCopy={() =>
													actions.ux.showNotification(
														{
															message:
																"Copied to clipboard",
														}
													)
												}
											>
												<CopyOutlined />
											</CopyToClipboard>
										}
									/>
								</>
							}
						/>
					)
				) : (
					<></>
				)}

				{usernameParam ? (
					<p className="header-2">
						<br />
						<br />
						Looking for someone else?
					</p>
				) : (
					<YouAreAboutTo
						amount={amount}
						actionName={actionName}
						priceTotal={priceTotal}
						onSubmit={stake}
						actionNameText="swap"
						onCancel={() => onCancel && onCancel()}
					/>
				)}
				{/* TODO look for someone */}
				{/* <div className="vertical-space"></div>
					<Form
						style={{ display: "flex" }}
						onFinish={(v) => {
							actions.routing.historyPush({
								location: "/swap/" + _username,
							});
						}}
					>
						<Form.Item name="username">
							<Input
								placeholder="type a newcoin username"
								onChange={(e) => _setUsername(e.target.value)}
								suffix={<SearchOutlined />}
							/>
							<br />
							<br />
						</Form.Item>
					</Form> */}
			</>

			{/* <UserNewcoinPoolsParticipation user={{ username: usernameParam }} /> */}
			{/* <UserStake user={{ username: usernameParam }} /> */}
		</div>
	);
};

export const YouAreAboutTo: IOView<{
	amount: number;
	priceTotal: number;
	onCancel?: () => void;
	onSubmit?: () => void;
	actionNameText: string;
	actionName: string;
}> = ({
	amount,
	priceTotal,
	onSubmit,
	onCancel,
	actionNameText,
	actionName,
}) => {
	const state = useAppState();
	const { source, target } = mapValues(useParams<Pair>(), (v) =>
		v.toUpperCase()
	);

	const acc = state.accountManagement.auth.account;
	const tokens = state.accountManagement.auth.account.tokens || [];
	const ncoBalance = Number(acc.account?.core_liquid_balance?.split(" ")[0]);
	const ncoSymbol = acc.account?.core_liquid_balance?.split(" ")[1];
	const gncoSymbol = tokens.find((t) => t.symbol == "GNCO")?.symbol;
	const targetBalance = tokens.find((t) => t.symbol == target)?.amount;
	const targetAmount = Math.round(amount * 83) / 100;

	return (
		<div className="app-content-wrapper">
			<p className="header-2 u-margin-bottom-mega text-center">
				You are about to {actionNameText}
			</p>
			<Row justify="space-between" className="text-center">
				<Col className="stake-input-square">
					<p className="paragraph-1">{source}</p>
					<p className="header-2">{amount}</p>
				</Col>
				<Col>
					<SwapIcon />
				</Col>
				<Col className="stake-input-square">
					<p className="paragraph-1">{target}</p>
					<p className="header-2">{targetAmount}</p>
				</Col>
			</Row>
			<Row
				style={{
					width: "100%",
					alignItems: "end",
					flex: 1,
				}}
				justify="center"
			>
				<Col
					style={{
						width: "100%",
					}}
				>
					<Col>
						<div className="vertical-space"></div>
						<p className="text-center">
							45% from 478 $NCO balance A 17% fee will be
							distributed to the community
						</p>
					</Col>
					<Col className="text-center">
						<div className="vertical-space"></div>
						<Button onClick={() => onCancel && onCancel()}>
							Review transaction
						</Button>
						<div className="vertical-space"></div>
						<ProgressButton
							actionName={actionName}
							disabledWhileProgress={true}
							onClick={() => onSubmit && onSubmit()}
						>
							Submit transaction
						</ProgressButton>
					</Col>
				</Col>
			</Row>
		</div>
	);
};
