import { Col, Input, Row, Tooltip } from "antd";
import { Info } from "@newstackdev/iosdk/dist/Components/Icons/Info";
import { UploadPicture } from "./UploadPicture";
import { useAppState } from "../../overmind/overmind";
import Form from "antd/lib/form";
import { useContentImageUrl } from "../../Components/MediaComponents/useContentImageUrl";
import { getWattIcon } from "../../Components/Powerup";

export const UserUpdateHeader = () => {
  const state = useAppState();

  const profilePicture = useContentImageUrl({
    id: state.api.auth.user.id,
    contentUrl: state.api.auth.user.contentUrl,
  });

  const coverPicture = useContentImageUrl({
    id: state.api.auth.user.id,
    //@ts-ignore
    contentUrl: state.api.auth.user.coverContentUrl,
  });

  const wattIcon = getWattIcon(state.api.auth.user);

  return (
    <div className="post-create-header-wrapper">
      <Col>
        <Row justify="center">
          <h2
            className="text-center header-5 u-margin-bottom-large"
            style={{ fontSize: "30px" }}
          >
            Edit your profile
          </h2>
        </Row>
        <Row
          className="u-margin-bottom-large"
          style={{ justifyContent: "space-between" }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <Form.Item name="file" style={{ margin: 0 }}>
              <UploadPicture placeholderImgSrc={profilePicture} />
            </Form.Item>
            <div
              style={{
                backgroundColor: "#dfff83",
                height: "36px",
                width: "36px",
                padding: "5px",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(wattIcon)}`}
              />
            </div>
          </div>
          <Form.Item name="coverPicture">
            <UploadPicture
              placeholderImgSrc={coverPicture}
              placeholderTxt="upload cover picture"
            />
          </Form.Item>
        </Row>
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <p
            className="header-5"
            style={{ lineHeight: "32px", marginRight: 10 }}
          >
            Domain
          </p>
          <Tooltip
            placement="rightTop"
            title={
              "This is your Newcoin blockchain name, aka your domain. You cannot edit this domain name!"
            }
          >
            <span style={{ height: 30 }}>
              <Info color="silver" />
            </span>
          </Tooltip>
        </Row>
        <Form.Item>
          <Input
            disabled
            placeholder="your domain"
            style={{ marginLeft: 10, width: "calc(100% - 10px)" }}
          />
        </Form.Item>
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="header-5">Username</p>
        </Row>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message:
                "Display name must start with a letter, be 3 - 9 chars or longer and contain only latin letters, digits and dots.",
            },
          ]}
        >
          <Input
            placeholder="username"
            style={{ marginLeft: 10, width: "calc(100% - 10px)" }}
          />
        </Form.Item>
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="header-5">Name</p>
        </Row>
        <Form.Item
          name="displayName"
          rules={[
            {
              required: true,
              message:
                "Display name must start with a letter, be 3 - 9 chars or longer and contain only latin letters, digits and dots.",
            },
          ]}
        >
          <Input
            placeholder="display name"
            style={{ marginLeft: 10, width: "calc(100% - 10px)" }}
          />
        </Form.Item>
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="header-5">Bio</p>
        </Row>
        <Form.Item name="description">
          <Input.TextArea
            placeholder="bio"
            style={{ marginLeft: 10, width: "calc(100% - 10px)" }}
          />
        </Form.Item>
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="header-5">Long Description</p>
        </Row>
        <Form.Item name="bio">
          <Input.TextArea
            placeholder="Long Description"
            style={{ marginLeft: 10, width: "calc(100% - 10px)" }}
          />
        </Form.Item>
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="header-5">Email</p>
        </Row>
        <Form.Item
          name="email"
          required={false}
          rules={[
            {
              required: false,
              // message: "Please enter your email",
            },
          ]}
        >
          <Input
            placeholder="email"
            style={{ marginLeft: 10, width: "calc(100% - 10px)" }}
          />
        </Form.Item>
      </Col>
    </div>
  );
};
