import { Avatar, Col, Button, Modal, Row } from "antd";
import { CrossCircle } from "@newstackdev/iosdk/dist/Components/Icons/CrossCircle";
import { useAppState, useActions } from "../../overmind/overmind";
import { CloseOutlined } from "@ant-design/icons";
import NewsafeIcon from "../../assets/Icons/Logo";
import { UserReadPrivateResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { useContentImageUrl } from "../MediaComponents/useContentImageUrl";
import { Powerup, getWattIcon } from "../Powerup";
import { TwitterShareButton } from "react-share";

const getTwitterSharingContent = (user: UserReadPrivateResponse) => {
  const userProfileImg = useContentImageUrl({ ...user });
  const wattIcon = getWattIcon(user);
  return (
    <Col
      style={{
        width: "296px",
        height: "296px",
        borderRadius: "24px",
        boxShadow: "0px 4.698px 29.589px 6.218px #00000028",
        padding: "15px",
      }}
    >
      <Row style={{ marginBottom: "20px" }}>
        <NewsafeIcon width="22.5px" height="22.5px" />
        <div
          style={{
            fontSize: "11px !important",
            lineHeight: "160%",
            paddingLeft: "8px",
          }}
        >
          Power me UP on Newsafe now
        </div>
      </Row>
      <Row justify={"center"}>
        {user.contentUrl && (
          <Col>
            <Avatar
              style={{
                border: "0.5px solid #EBEBEB",
                height: 192,
                width: 192,
              }}
              src={userProfileImg}
            />
            <div
              style={{
                backgroundColor: "#dfff83",
                height: "36px",
                width: "36px",
                padding: "5px",
                borderRadius: "20px",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(wattIcon)}`}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row
        justify={"space-between"}
        style={{ width: "100%", position: "relative", top: "-20px" }}
      >
        <Col style={{ display: "flex", alignItems: "end", fontSize: "20px" }}>
          {user.username}
        </Col>
        <Col>
          <Powerup
            user={user}
            styleOverride={{
              width: 45,
              height: 45,
              borderRadius: 8.5,
              justifyItems: "end",
              pointerEvents: "none",
            }}
            powerupIconHeight={30}
            powerupIconWidth={30}
          />
        </Col>
      </Row>
    </Col>
  );
};

const TwitterSharing = ({
  title,
  shareText,
  setOpen,
  open,
}: {
  title: string;
  shareText: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}) => {
  const state = useAppState();

  const user = state.api.auth.user;

  return (
    <Modal
      closeIcon={<CrossCircle />}
      footer={null}
      className="user-twitter-sharing-info_modal"
      maskClosable
      mask
      open={open}
      style={{
        pointerEvents: "all",
      }}
      onCancel={() => {
        setOpen && setOpen(false);
      }}
      modalRender={() => (
        <>
          <div
            className="user-twitter-sharing-info_close-button"
            onClick={() => {
              setOpen && setOpen(false);
            }}
          >
            <CloseOutlined className="user-twitter-sharing-info_close-icon" />
          </div>
          <div className="user-twitter-sharing-info_modal-content">
            <Row className="user-twitter-sharing-info_modal-root-row">
              <p className="header-2 text-center">{title}</p>
            </Row>
            <Row justify={"center"} style={{ marginBottom: "40px" }}>
              {getTwitterSharingContent(user)}
            </Row>
            <Row justify={"center"}>
              <TwitterShareButton
                type="button"
                url={shareText}
                title="Newsafe milestone reached: "
                className="ant-btn-primary ant-btn"
                id="share-btn-Twitter"
                style={{ fontSize: "21px", height: "55px", width: "100%" }}
              >
                Share on X
              </TwitterShareButton>
            </Row>
          </div>
        </>
      )}
    ></Modal>
  );
};

export default TwitterSharing;
