export const ArrowBottom = () => (
	<svg
		width="21"
		height="12"
		viewBox="0 0 21 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.999999 1L10.5 10L20 0.999998"
			stroke="black"
			stroke-width="1.5"
		/>
	</svg>
);
