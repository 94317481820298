import { ROUTE_ACCESS_LEVELS } from "@newstackdev/iosdk/dist/overmind/routing/state";
import {
  config as baseConfig,
  // stripeKey,
} from "@newstackdev/iosdk/dist/config";
import { stripeKeys } from "@newstackdev/iosdk/dist/config/stripeKeys";

import { Console } from "./Pages/Console";
import { FirebaseConfig } from "@newstackdev/iosdk/dist/types";
import { SignIn } from "./Pages/Auth/SignIn";

const firebaseConfigs: Record<string, FirebaseConfig> = {
  "eu-dev": {
    apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_DEV_FIREBASE_API_KEY || "",
    projectId: "newlifeio",
    storageBucket: "newlifeio.appspot.com",
    messagingSenderId:
      process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID || "",
    measurementId: "G-PJWYRPZSNM",
  },
  "eu-sit": {
    apiKey: process.env.REACT_APP_SIT_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_SIT_FIREBASE_API_KEY || "",
    projectId: "newlifeio",
    storageBucket: "newlifeio.appspot.com",
    messagingSenderId: process.env.REACT_APP_SIT_FIREBASE_API_KEY || "",
    appId: process.env.REACT_APP_SIT_FIREBASE_API_KEY || "",
    measurementId: "G-PJWYRPZSNM",
  },
  "eu-prod": {
    apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_PROD_FIREBASE_API_KEY || "",
    projectId: "newlifeio-prod",
    storageBucket: "newlifeio-prod.appspot.com",
    messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_API_KEY || "",
    appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID || "",
    measurementId: "G-YMT320RGLJ",
  },
  v1: {
    apiKey: process.env.REACT_APP_V1_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_V1_FIREBASE_API_KEY || "",
    projectId: "newlifeio-prod",
    storageBucket: "newlifeio-prod.appspot.com",
    messagingSenderId:
      process.env.REACT_APP_V1_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_V1_FIREBASE_APP_ID || "",
    measurementId: "G-YMT320RGLJ",
  },
};

import { stage as _stage } from "@newstackdev/iosdk/dist/config";

const stage = window.location.host.startsWith("localhost") ? "eu-prod" : _stage;

export const mediaBucket = "https://cdn.newgra.ph";

// const stage = "eu-prod";
const stripeKey = stripeKeys[stage];
export const firebaseConfig = firebaseConfigs[stage];
// export const stripeKey = stripeKeys[stage];
console.log(stripeKeys[stage]);

const apiBaseUrls: Record<string, string> = {
  // "eu-dev": "https://api-eu-dev.newlife.io/creator",

  "eu-dev": "https://api.newgra.ph/v1",
  "eu-sit": "https://api.newgra.ph/v1",
  "eu-prod": "https://api.newgra.ph/v1",
};
export const apiBaseUrl = apiBaseUrls[stage];

const websocketsServers: Record<string, any> = {
  // "eu-dev": `wss://wsapi-eu-dev.newlife.io/creator`,
  "eu-dev": `wss://wsapi-eu-dev.newgra.ph/v1`,
  "eu-sit": `wss://wsapi-eu-sit.newlife.io/creator`,
  "eu-prod": `wss://wsapi.newgra.ph/v1`,
};

// Customize here
export const config = {
  ...baseConfig,
  env: {
    stage,
    env: stage.split(/-/)[1],
  },
  settings: {
    newgraph: {
      baseUrl: apiBaseUrl,
      mediaBucket: mediaBucket,
      websocketsServer: websocketsServers[stage],
    },
    app: {
      newcoin: {
        domain: "",
        poolSymbol: "",
      },
    },
    firebaseConfig,
    routing: {
      defaults: {
        onAuth: "/home",
      },
      useDefaultSignout: false,
      routeAccessLevels: {
        ...ROUTE_ACCESS_LEVELS,
        "/": (st) => true, //st >= AUTH_FLOW_STATUS.AUTHENTICATED
        "/welcome": (st) => true, //st >= AUTH_FLOW_STATUS.AUTHENTICATED
        "/counter": (st) => true, //st >= AUTH_FLOW_STATUS.AUTHENTICATED
        "/SignIn": (st) => true,
        "/signin": (st) => true,
        "/SignInCrosswalk": (st) => true,
        "/signup": (st) => true,
        "/invite": (st) => true,
        "/console": (st) => true,
        "/signup/hash": (st) => true,
        "/signup/auth": (st) => true,
        "/signup/domain": (st) => true,
        "/signup/create": (st) => true,
        "/signup/managementService": (st) => true,
        "/signup/managementService/other": (st) => true,
        "/signup/phoneVerification": (st) => true,
        "/progress": (st) => true,
        "/networks": (st) => true,
        "/people": (st) => true,
        "/connect": (st) => true,
      }, //(AUTH_FLOW_STATUS.AUTHORIZED) <= st && (st < AUTH_FLOW_STATUS.AUTHENTICATED) },
    },
    indicators: {
      isWatchable: (actionName: string) =>
        /^(api|lists|auth|firebase|payments|newcoin|onboarding|accountManagement)/.test(
          actionName
        ),
    },
    stripe: {
      publicKey: stripeKey,
    },
  },
  routes: {
    // override built-in types here
    overrides: {
      "/": Console,
      "/signin": SignIn,
    },
  },
  // override fundamental building blocks here
  components: {
    layout: {
      // TopMenu: () => <>Overrides top menu</>,
      // Layout: // override layout completely
      //   (({ children }) => <>{children}</>) as NLView
    },
  },
};

export type Configuration<T = {}> = typeof config & T;
export type PartialConfiguration<T = {}> = Partial<Configuration<T>>;

// const canonicalHosts = {
//   "eu-prod": ["newsafe.org", "auth.newsafe.org"]
// };

// const stageDomainsFromList = (list: string, stage: string) =>
//   (list || "").split(/,/).reduce((r, c, o) =>
//     ({ ...r, [c]: stage })
//     , {})

// const stages: Record<string, string> = {

//   ...stageDomainsFromList(process.env.REACT_APP_IOSDK_APP_DEV_HOST || "", "eu-dev"),
//   ...stageDomainsFromList(process.env.REACT_APP_IOSDK_APP_SIT_HOST || "", "eu-sit"),
//   ...stageDomainsFromList(process.env.REACT_APP_IOSDK_APP_PROD_HOST || "", "eu-prod")

// [process.env.REACT_APP_IOSDK_APP_DEV_HOST || ""]: "eu-dev",
// [process.env.REACT_APP_IOSDK_APP_SIT_HOST || ""]: "eu-sit",
// ...(process.env.REACT_APP_IOSDK_APP_PROD_HOST || "").split(/,/).reduce((r, c, o) =>
//     ({ ...r, [c]: "eu-prod" })
// , {})
// };

// const actualCurrentHost = window.location.hostname;
// const isLocalhost = actualCurrentHost === "localhost"
// const currentHost =
//   actualCurrentHost === "localhost" ?
//     process.env.REACT_APP_IOSDK_APP_DEV_HOST || "" :
//     window.location.hostname;

// export const stage = stages[currentHost];

// const replaceHost = (host?: string) =>
//   `https://${host || window.location.host}${window.location.pathname}${window.location.search ? "?" + window.location.search : ""}`;

// if ((window.location.protocol != "https:") && !isLocalhost)
//   (window.location as any) = replaceHost();
// // `https://${window.location.host}${window.location.pathname}${window.location.search ? "?" + window.location.search : ""}`;

// const canonicalHostsForStage: string[] = canonicalHosts[stage] || [];
// if (!isLocalhost && canonicalHostsForStage.length && !canonicalHostsForStage.includes(actualCurrentHost))
//   (window.location as any) = `https://${canonicalHostsForStage[0]}${window.location.pathname}${window.location.search ? "?" + window.location.search : ""}`;
