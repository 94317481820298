export const Edit: React.FC<{ color?: string }> = ({ color }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2 29.7117H2C1.46957 29.7117 0.960859 29.501 0.585786 29.1259C0.210714 28.7509 0 28.2422 0 27.7117V4.51172C0 3.98129 0.210714 3.47258 0.585786 3.09751C0.960859 2.72243 1.46957 2.51172 2 2.51172H19V4.51172H2V27.7117H25.2V10.7117H27.2V27.7117C27.2 28.2422 26.9893 28.7509 26.6142 29.1259C26.2391 29.501 25.7304 29.7117 25.2 29.7117Z"
      fill={color || "#ffff"}
    />
    <path
      d="M28.2911 -7.43966e-05L11.4902 16.8008L12.9044 18.215L29.7053 1.41414L28.2911 -7.43966e-05Z"
      fill={color || "#ffff"}
    />
  </svg>
);
