import { Row, Button } from "antd";
import { Tabs, Col } from "antd";
import Light from "../../assets/Icons/Light.png";
import { Minerals } from "./Minerals";
import { PowerUP } from "./PowerUP";
import { useAppState, useActions } from "../../overmind/overmind";
import { useState } from "react";

export const Energy = () => {
	const state = useAppState();
	const actions = useActions();
	const [activeKey, setActiveKey] = useState<string>("1");

	const topUsers = state.lists.top.users.items;

	return (
		<Tabs
			type="card"
			activeKey={activeKey}
			onChange={(activeKey) => setActiveKey(activeKey)}
		>
			<Tabs.TabPane tab="Energy" key="1">
				<div className="text-center">
					<Row
						align="middle"
						justify="center"
						className="u-margin-bottom-medium"
					>
						<img src={Light} alt="Light" />
						<p className="header-1">4 Watts</p>
					</Row>
					<Button type="primary" className="text-center">
						Get More Watts
					</Button>
				</div>

				<Minerals maxItems={4} setActiveKey={setActiveKey} />
				<PowerUP maxItems={4} setActiveKey={setActiveKey} />
			</Tabs.TabPane>
			<Tabs.TabPane tab="PowerUP" key="2">
				<Row align="middle" justify="center">
					<Button className="u-margin-right-medium">
						<p className="header-1">{topUsers.length}</p>
					</Button>
					<p className="header-1">PowerUPs</p>
					<img src={Light} alt="Light" />
				</Row>
				<PowerUP />
				<div className="text-center u-margin-top-large u-margin-bottom-large">
					<Button
						type="primary"
						onClick={() => actions.lists.top.users({})}
					>
						Find More People to PowerUP
					</Button>
				</div>
			</Tabs.TabPane>
			<Tabs.TabPane tab="Minerals" key="3">
				{/* {JSON.stringify(badgesData)} {state.api.auth.user.username} */}
				<Row
					justify="space-between"
					align="bottom"
					className="u-margin-bottom-mega"
				>
					<Col>
						<p className="header-1 u-margin-bottom-medium">
							Experience Energy
						</p>
						<Button
							style={{
								width: 200,
								borderRadius: 45,
								background: "#E4FC61",
							}}
						>
							&nbsp;
						</Button>
					</Col>
					<Col>
						<Row>
							<p className="header-1">659&nbsp;</p>
							<span className="header-1b">$XWATT</span>
						</Row>
					</Col>
				</Row>
				<Minerals />
				<div className="text-center u-margin-top-large u-margin-bottom-large">
					<Button type="primary">Load More Minerals</Button>
				</div>
			</Tabs.TabPane>
		</Tabs>
	);
};
