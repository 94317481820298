export const ShareIcon: React.FC = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4078 5.47383L10.4805 2.54651L7.55316 5.47383L6.77991 4.70058L10.4805 1L14.1811 4.70058L13.4078 5.47383Z"
      fill="black"
    />
    <path d="M9.9281 1.77332H11.0328V13.3721H9.9281V1.77332Z" fill="black" />
    <path
      d="M16.0035 20H4.95696C4.01801 20 3.29999 19.282 3.29999 18.343V8.40115C3.29999 7.46219 4.01801 6.74417 4.95696 6.74417H8.82324V7.84882H4.95696C4.62557 7.84882 4.40464 8.06975 4.40464 8.40115V18.343C4.40464 18.6744 4.62557 18.8953 4.95696 18.8953H16.0035C16.3349 18.8953 16.5558 18.6744 16.5558 18.343V8.40115C16.5558 8.06975 16.3349 7.84882 16.0035 7.84882H12.1372V6.74417H16.0035C16.9424 6.74417 17.6604 7.46219 17.6604 8.40115V18.343C17.6604 19.282 16.9424 20 16.0035 20Z"
      fill="black"
    />
  </svg>
);
