import React, { useMemo, useRef, useState, useEffect } from "react";
import { AutoComplete, Spin, Select, Row, Col } from "antd";
import { Modal } from "antd-latest";
import type { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import {
  useCachedPool,
  useCachedPoolByCode,
} from "@newstackdev/iosdk/dist/hooks/useCached";
import { CloseModalIcon } from "../../iosdk/Icons/CloseModalIcon";
import { SearchOutlined } from "@ant-design/icons";
import { ArrowBottom } from "../../assets/Icons/ArrowBottom";
import { SearchIcon } from "../../assets/Icons/SearchIcon";
import { useCachedUser } from "@newstackdev/iosdk/dist/hooks/useCached";
import { useActions, useAppState } from "../../overmind/overmind";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { User } from "./User";

export type UserValue = {
  username: string;
  value: string;
};

export interface SearchUserTickerProps
  extends Omit<SelectProps<any>, "options" | "children"> {
  defaultValue: string;
  setTargetTicker: (ticker: string) => void;
}

export const SearchUserTicker: React.FC<SearchUserTickerProps> = (props) => {
  const { setTargetTicker, defaultValue } = props;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<UserValue[]>([]);
  const [showTickerField, setShowTickerField] = useState(true);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [typedValue, setTypedValue] = useState<string>(defaultValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string>("");
  const fetchRef = useRef(0);
  const actions = useActions();
  const state = useAppState();
  const targetPool = useCachedPoolByCode(
    { code: typedValue?.toUpperCase() },
    true
  );

  const checkTickerValidity = () => {
    if (
      (typeof targetPool === "object" && !isEmpty(targetPool.rows[0])) ||
      typedValue === "GNCO" ||
      typedValue === "NCO"
    ) {
      setError("");
    } else {
      setError("Choose a user or type in a valid ticker!");
    }
  };

  useEffect(() => {
    checkTickerValidity();
  }, [selectedValue, typedValue, defaultValue, targetPool]);

  useEffect(() => {
    handleOnBlur();
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue("");
    setTypedValue(defaultValue.toUpperCase());
    setShowTickerField(true);
  }, [defaultValue, window.history.state]);

  const fetchUsers = async (q: string): Promise<UserValue[]> => {
    await actions.accountManagement.searchUsers({ q });

    const users = state.accountManagement.search.users.map((user) => {
      return {
        username: user.owner,
        value: user.code,
      };
    });

    return users;
  };

  const debouncedSearch = useMemo(() => {
    setSelectedValue("");
    setError("");

    const searchOptions = (value: string) => {
      setTypedValue(value.toUpperCase());

      if (isEmpty(value)) {
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      // fetchUsers(value).then((newOptions) => {
      // 	if (fetchId !== fetchRef.current) {
      // 		return;
      // 	}
      // 	setOptions(newOptions);
      // 	setFetching(false);
      // });
    };

    return debounce(searchOptions, 800);
  }, []);

  const handleOnBlur = () => {
    setShowTickerField(true);
    if (isEmpty(selectedValue)) return;

    setTargetTicker(selectedValue);
    setTypedValue(selectedValue);

    return;

    // if (!isEmpty(selectedValue)) {
    // 	setTargetTicker(selectedValue);
    // } else if (!isEmpty(typedValue) && !error && targetPool) {
    // 	setShowTickerField(true);
    // 	setTargetTicker(
    // 		typeof targetPool === "object"
    // 			? targetPool.rows[0].code
    // 			: targetPool
    // 	);
    // } else if (typedValue === "NCO" || typedValue === "GNCO") {
    // 	setShowTickerField(true);
    // 	setTargetTicker(typedValue);
    // } else {
    // 	setShowTickerField(true);
    // }
  };

  // MODAL

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {!showTickerField && (
        <Modal
          title="Choose Token"
          onOk={handleOk}
          onCancel={() => {
            setShowTickerField(true);
            handleCancel();
          }}
          visible={isModalOpen}
          mask={false}
          footer={[]}
          width={386}
          closeIcon={<CloseModalIcon />}
          //@ts-ignore
          getContainer={() => document.getElementById("searchUserTicker")}
        >
          <Row
            id="area"
            align="middle"
            justify="space-between"
            style={{ position: "relative" }}
          >
            <SearchIcon />
            <Select
              {...props}
              showSearch
              value={null}
              style={{ width: "90%" }}
              optionFilterProp="children"
              // options={options}
              onBlur={handleOnBlur}
              showArrow={false}
              onSelect={(val) => {
                setSelectedValue(val.toUpperCase());
              }}
              placeholder={"Search ticker or Username"}
              defaultActiveFirstOption={false}
              dropdownRender={(menu) => (
                <div style={{ position: "relative" }}>{menu}</div>
              )}
              //@ts-ignore
              getPopupContainer={() => document.getElementById("area")}
              notFoundContent={
                fetching ? (
                  // TODO change spinning icon
                  <Spin size="small" style={{ color: "black" }} />
                ) : null
              }
              onSearch={debouncedSearch}
              filterOption={false}
            >
              {options.length > 0 &&
                options.map((option) => (
                  <Select.Option key={option.value}>
                    <User user={option.username} code={option.value} />
                  </Select.Option>
                ))}
              {targetPool &&
                targetPool?.rows !== undefined &&
                options.length === 0 && (
                  <Select.Option
                    value={targetPool?.rows[0].code}
                    key={options.length}
                  >
                    <User
                      user={targetPool?.rows[0].owner}
                      code={targetPool?.rows[0].code}
                    />
                  </Select.Option>
                )}
            </Select>
          </Row>
        </Modal>
      )}
      {showTickerField && (
        <>
          <div
            className="paragraph-1 ns-search-user-ticker-field"
            onClick={() => {
              setShowTickerField(false);
              showModal();
            }}
          >
            <Row justify="space-between">
              <Col>${typedValue}</Col>
              <Col>
                <ArrowBottom />
              </Col>
            </Row>
          </div>
        </>
      )}
      {!isEmpty(error) && (
        <div className="ns-search-user-ticker-error ">{error}</div>
      )}
    </>
  );
};
