import { Action } from "../../iosdk/overmind/overmind";
import { pipe, filter } from "overmind";

type WsEvent = {
  type: "newgraph";
  payload: any;
  updated: string;
};

const processIncomingNewgraph: Action<{ event: WsEvent }> = pipe(
  filter((_, ev) => ev?.event?.type === "newgraph"),
  ({ state }, { event }) => {
    const msg = event.payload.message;
    const msgCore = msg.replace(/_/, " ");
    const asMsg = {
      title: msgCore,
      timeStamp: event.updated,
      description: msg.error,
      original: event,
    };
    state.websockets.messages.activityStream.unshift(asMsg);
  }
);

const processIncoming: Action<{ msg: any }> = (
  { reaction, actions, effects },
  { msg }
) => {
  try {
    const ev = JSON.parse(msg);
    effects.websockets.emitter.emit(ev.type, ev);
    actions.websockets.processIncomingNewgraph({ event: ev });
  } catch (ex) {
    console.log("socketProcessIconming", ex);
  }
};

const toggleWebSocket: Action = async ({ state, effects, actions }) => {
  if (!state.api.auth.authorized) return;

  const token = state.newsafe?.token || state.firebase?.token;

  if (!token) return;

  effects.websockets.newlife.toggle(
    state.config.settings.newgraph.websocketsServer,
    token
  );

  if (!token || !effects.websockets.newlife.socket) return;

  const res = (await state.api.client.user.activityStreamList()) || {
    data: { Items: [] },
  };

  const items = [...(((res.data || {}) as any).Items || [])].reverse();

  effects.websockets.newlife.socket!.addEventListener("message", (ev) => {
    if (ev.data === "pong") return;

    actions.websockets.processIncoming({ msg: ev.data });
    // state.websockets.messages.incoming.push(ev.data);
    // effects.ux.notification.open({ message: ev.data });
  });

  await Promise.allSettled(
    items.map((ev: any) =>
      actions.websockets.processIncoming({
        msg: JSON.stringify({ ...ev, backlog: true }),
      })
    )
  );
};

export default {
  processIncoming,
  toggleWebSocket,
  processIncomingNewgraph,
};
