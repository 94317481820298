export const Discord = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6965 11.1113C9.99696 11.1113 9.4447 11.7249 9.4447 12.4736C9.4447 13.2222 10.0092 13.8358 10.6965 13.8358C11.396 13.8358 11.9483 13.2222 11.9483 12.4736C11.9605 11.7249 11.396 11.1113 10.6965 11.1113ZM15.1759 11.1113C14.4763 11.1113 13.9241 11.7249 13.9241 12.4736C13.9241 13.2222 14.4886 13.8358 15.1759 13.8358C15.8754 13.8358 16.4276 13.2222 16.4276 12.4736C16.4276 11.7249 15.8754 11.1113 15.1759 11.1113Z"
      fill="#000000"
    />
    <path
      d="M21.1403 0.814941H4.69539C3.30862 0.814941 2.17957 1.94399 2.17957 3.34304V19.9352C2.17957 21.3342 3.30862 22.4633 4.69539 22.4633H18.6122L17.9618 20.1929L19.5326 21.6533L21.0176 23.0278L23.6561 25.3596V3.34304C23.6561 1.94399 22.527 0.814941 21.1403 0.814941ZM16.4032 16.8426C16.4032 16.8426 15.9614 16.3149 15.5932 15.8485C17.2009 15.3944 17.8145 14.3881 17.8145 14.3881C17.3113 14.7195 16.8327 14.9526 16.4032 15.1122C15.7896 15.3699 15.2005 15.5417 14.6237 15.6399C13.4455 15.8608 12.3656 15.7994 11.4452 15.6276C10.7456 15.4926 10.1443 15.2963 9.64113 15.0999C9.35887 14.9895 9.05206 14.8545 8.74525 14.6826C8.70843 14.6581 8.67162 14.6458 8.6348 14.6213C8.61025 14.609 8.59798 14.5967 8.58571 14.5845C8.36481 14.4617 8.24209 14.3758 8.24209 14.3758C8.24209 14.3758 8.83116 15.3576 10.3897 15.824C10.0216 16.2903 9.56749 16.8426 9.56749 16.8426C6.85531 16.7567 5.82444 14.9772 5.82444 14.9772C5.82444 11.0255 7.59165 7.82243 7.59165 7.82243C9.35886 6.49702 11.0402 6.53384 11.0402 6.53384L11.1629 6.6811C8.95388 7.31926 7.93528 8.28878 7.93528 8.28878C7.93528 8.28878 8.20527 8.14151 8.65934 7.93288C9.97248 7.35608 11.0156 7.19654 11.4452 7.15972C11.5188 7.14745 11.5802 7.13518 11.6538 7.13518C12.4024 7.037 13.2492 7.01246 14.1328 7.11064C15.2987 7.24563 16.5504 7.58926 17.8268 8.28878C17.8268 8.28878 16.8572 7.36835 14.771 6.73019L14.9428 6.53384C14.9428 6.53384 16.6241 6.49702 18.3913 7.82243C18.3913 7.82243 20.1585 11.0255 20.1585 14.9772C20.1585 14.9772 19.1153 16.7567 16.4032 16.8426V16.8426Z"
      fill="#000000"
    />
  </svg>
);
