// import { CreatorApi } from "../../types";
import { Api, ErrorResponse, SessionTokenRequest } from "../../lib/api-client";

// const baseUrl = newlifeBaseUrl; //"https://api-eu-sit.newlife.io/creator";

export default (() => {
  let api: Api<{ token: string }>;
  return {
    initialize(baseUrl?: string) {
      api = new Api({
        baseUrl,
        securityWorker: (securityData: { token: string } | null) => {
          return !securityData ? {} : { headers: { Authorization: securityData.token } };
        },
      });
      return api;
    },
    authRequestQuerystring: "",
    updateToken(token: string) {
      api.setSecurityData({ token });
    },
    api: () => api
  };
})();
