import { useAppState } from "../../overmind/overmind";
import { ContentImage } from "@newstackdev/iosdk/dist/Components/Image";
import { Col, Row, Avatar, Button } from "antd";
import { toMonthName } from "./Minerals";

type Props = {
  maxItems?: number;
  setActiveKey?: React.Dispatch<React.SetStateAction<string>>;
};

export const PowerUP = ({ maxItems, setActiveKey }: Props) => {
  const state = useAppState();
  const creators = maxItems
    ? state.lists.top.users.items.slice(0, maxItems)
    : state.lists.top.users.items;

  const totalCount = state.lists.top.users.items.length;

  return (
    <div className="u-margin-top-mega">
      <Row
        align="middle"
        justify="space-between"
        className="u-margin-bottom-medium"
      >
        <Col>
          <Row align="middle">
            <p className="header-1 text-left u-margin-right-large">
              Experience PowerUPs
            </p>
            {maxItems && (
              <Button onClick={() => setActiveKey!("2")}>
                See All PowerUPs
              </Button>
            )}
          </Row>
        </Col>
        {totalCount && (
          <Col>
            <Button>
              <p className="header-2">{totalCount}</p>
            </Button>
          </Col>
        )}
      </Row>
      <Row wrap className="ns-badges-wrapper" gutter={[0, 40]}>
        {creators.map((creator) => {
          const dt = new Date(creator.created || "");

          const date = `${toMonthName(
            dt.getMonth() + 1
          )} ${dt.getDate()}, ${dt.getFullYear()}`;

          return (
            <Row className="ns-badge">
              <Col className="u-margin-bottom-small">
                <Avatar src={<ContentImage {...creator} />} />
              </Col>
              <Row gutter={5} className="typography-overflow" justify="center">
                <p className="paragraph-1b">{creator.username}</p>
              </Row>
              <Col>
                <p>{date}</p>
              </Col>
            </Row>
          );
        })}
      </Row>
    </div>
  );
};
