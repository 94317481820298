export const TwitterIcon = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 36">
      <g id="layer1">
        <path
          id="path1009"
          d="M2.56289 3.78125L8.73241 12.0307L2.52393 18.7379H3.92121L9.35672 12.8656L13.7485 18.7379H18.5035L11.9868 10.0244L17.7656 3.78125H16.3684L11.3625 9.18945L7.31789 3.78125H2.56289ZM4.61769 4.81052H6.80215L16.4483 17.7084H14.2639L4.61769 4.81052Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
);
