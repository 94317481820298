import websocket from "./effects";
import EventEmitter from "events";
import actions from "./actions";

export default {
  actions,
  effects: {
    newlife: websocket(
      (wsServer, token) => `${wsServer}?token=${encodeURIComponent(token)}`
    ),
    emitter: new EventEmitter(),
  },
  state: {
    socket: null,
    messages: {
      activityStream: [],
    },
  },
};
