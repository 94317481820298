import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

function RotatableImage({ src }) {
  const canvasRef = useRef<any>(null);

  useEffect(() => {
    // Load the image texture
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(src);
    texture.format = THREE.RGBAFormat;

    // Create the sphere geometry and material
    const geometry = new THREE.SphereGeometry(6, 64, 64);
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      color: texture,
    });

    // Create the sphere mesh and add it to the scene
    const sphere = new THREE.Mesh(geometry, material);
    const scene = new THREE.Scene();
    scene.add(sphere);

    // Create the camera and renderer
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.autoClear = false;
    renderer.setClearColor(texture);

    // Add the renderer to the canvas and set the camera position
    canvasRef.current.appendChild(renderer.domElement);
    camera.position.z = 10;

    // Add the orbit controls to the scene
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;

    // Render the scene
    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
    animate();

    // Clean up
    return () => {
      scene.remove(sphere);
      renderer.dispose();
      controls.dispose();
    };
  }, [src]);

  return <div ref={canvasRef} />;
}

export default RotatableImage;
