import { Button, Col, Form, Input, Row } from "antd";
import { ContentLayout } from "@newstackdev/iosdk/dist/Components/ContentLayout";
import { Link } from "react-router-dom";
import { NLView } from "@newstackdev/iosdk/dist/types";
import { ProgressButton } from "@newstackdev/iosdk/dist/Components/ProgressButton";
import { Spin } from "@newstackdev/iosdk/dist/Components/Spin";
import { capFirst } from "@newstackdev/iosdk/dist/utils/capFirst";
import { useActions, useAppState } from "../../../overmind/overmind";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import SupportBox from "@newstackdev/iosdk/dist/Components/SupportBox";

const STATUS = {
  NONE: 0,
  LINK_NO_EMAIL: 1,
  LINK_EMAIL: 2,
  LINK_REQUESTED: 3,
  ERROR: 4,
  AUTHENTICATED_CANTPROCEED: 5,
  AUTHORIZED: 6,
};

export const LegacyLogin: NLView = () => {
  const [form] = useForm();
  const actions = useActions();
  const state = useAppState();

  const [status, setStatus] = useState<number>(STATUS.NONE);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  // const indicators = state.indicators.specific;
  // const authorizing = state.auth.status > 0 && !state.auth.authorized && !state.auth.authenticated;
  // oobCode &&
  // ["auth.newlifeAuthorize", "auth.refreshApiToken", "auth.firebaseSignInWithEmailLink"]
  //     .find(n => indicators[n]);
  const oobCode = new URLSearchParams(window.location.search).get("oobCode");

  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const signIn = async (email: string) => {
    try {
      await actions.firebase.signInWithEmailLink({ email });
    } catch (ex) {
      setStatus(STATUS.ERROR);
      setError(
        capFirst(
          (ex as { code: string }).code
            .replace(/^auth\//, "")
            .replace(/-/g, " ")
        )
      );
      actions.auth.logout({ noRouting: true });
    }
  };

  const tryAgain = () => {
    actions.routing.historyPush({ location: "/auth/newlife-members" });
    setStatus(STATUS.NONE);
    setError("");
    actions.auth.logout({ noRouting: true });
  };

  useEffect(() => {
    if (state.onboarding.legacyToken)
      actions.onboarding.stopLegacyImport({ noRedirect: true });

    if (oobCode) {
      const email: string = window.localStorage.getItem("emailForSignIn") || "";
      setStatus(email ? STATUS.LINK_EMAIL : STATUS.LINK_NO_EMAIL);

      email && signIn(email);
    } else if (state.auth.authenticated) {
      if (
        !state.api.auth.authorized &&
        state.api.auth.user?.id &&
        state.api.auth.user?.status !== "imported"
      ) {
        // actions.routing.historyPush({ location: "/signup" });
        setStatus(STATUS.AUTHENTICATED_CANTPROCEED);
        actions.auth.logout();
      }
    }
    // setStatus(state.api.auth.authorized ? STATUS.AUTHORIZED : STATUS.NONE);
  }, []);

  useEffect(() => {
    // if(state.api.auth.user.status == "admitted") {
    //   actions.routing.historyPush({ location: "/wallet" });
    //   return;
    // }

    if (
      !(oobCode && (state.auth.status || 0) > 0) ||
      state.indicators.specific["firebase.handleAuthChange"]
    ) {
      return;
    }

    const st =
      state.auth.authenticated &&
      (state.api.auth.user.status === "imported"
        ? STATUS.AUTHORIZED
        : STATUS.AUTHENTICATED_CANTPROCEED);

    if (st) {
      setStatus(st);
    }
  }, [
    state.api.auth.user.status,
    state.auth.status,
    state.api.auth.authorized,
    state.indicators.specific["firebase.handleAuthChange"],
  ]);

  const onFinish = ({ email }: { email: string }) => {
    switch (status) {
      case STATUS.NONE:
        if (!email) return;
        window.localStorage.setItem("emailForSignIn", email);
        setStatus(STATUS.LINK_REQUESTED);
        setEmail(email);

        actions.firebase.requestEmailLink({ email });
        break;
      case STATUS.LINK_NO_EMAIL:
        signIn(email);
    }
  };

  if (state.indicators.isWorking) return <Spin />;

  if (error)
    return (
      <div className="text-left">
        <p className="header-2">Oops! {error}</p>
        <div className="section-divider" />
        <Col className="u-margin-bottom-mega">
          <Button
            type="primary"
            onClick={tryAgain}
            className="secondary-button"
          >
            <span className="paragraph-2">Try again</span>
          </Button>
        </Col>
        <Col>
          <p
            className="paragraph-2"
            onClick={() =>
              actions.routing.historyPush({
                location: "/",
              })
            }
          >
            Try
            <span className="paragraph-2u"> on mobile</span>
          </p>
        </Col>
      </div>
    );
  return (
    <>
      {/* authorized status {state.auth.status}
        {JSON.stringify(state.api.auth.user)} */}
      local status {status}
      {JSON.stringify(state.api.auth.user.status)}
      {state.auth.authenticated ? (
        <>
          {status == STATUS.AUTHORIZED ? (
            <>
              <div className="section-divider" />
              <p className="header-2">
                Hi{" "}
                {state.api.auth.user?.username ||
                  state.api.auth.user?.displayName}
                , we've been missing you!
              </p>
              <div className="section-divider" />
              <p>
                Please take a few steps to access Newcoin - the blockchain-based
                ecosystem based on the core ideas of the original Newlife.
              </p>
              <div className="section-divider" />
              <Button
                type="primary"
                onClick={() => {
                  actions.onboarding.startLegacyImport();
                }}
              >
                Continue
              </Button>
            </>
          ) : status == STATUS.AUTHENTICATED_CANTPROCEED ? (
            <div className="text-left">
              <p className="header-2">
                Oops! Looks like you didn’t have an existing account.
              </p>
              <br />
              <ul style={{ padding: 0 }}>
                <li className="u-margin-bottom-mega">
                  {email ? (
                    <>
                      You used the email {email}
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <b>Please check your email address is correct.</b>
                  <div className="section-divider" />
                  <Button
                    type="primary"
                    onClick={tryAgain}
                    className="secondary-button"
                  >
                    <span className="paragraph-2">Try again</span>
                  </Button>
                </li>
                <li>
                  <b>Or make sure you haven’t already connected to Newcoin</b>
                  <div className="section-divider" />
                  <Row>
                    <Button
                      type="primary"
                      onClick={() =>
                        actions.routing.historyPush({
                          location: "/SignInCrosswalk",
                        })
                      }
                      className="secondary-button u-margin-right-medium"
                    >
                      Sign in
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        actions.routing.historyPush({
                          location: "/",
                        })
                      }
                      className="secondary-button"
                    >
                      <p className="paragraph-2">
                        Join <span className="paragraph-2">on mobile</span>
                      </p>
                    </Button>
                  </Row>
                </li>
              </ul>
              <br />
              <br />
              <br />
              <br />
              <span className="paragraph-2b">
                Still having issues? Email{" "}
                <span className="paragraph-2u">info@newlife.io</span>
              </span>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {status === STATUS.LINK_REQUESTED && (
        <ContentLayout customClass="app-content-layout">
          <p className="super-size text-center nl-legacyImport-title">
            check your inbox
          </p>
          <div className="nl-onboarding-form" />
          <div className="nl-onboarding-footer">
            <p className="paragraph-2b text-center nl-legacyImport-checkYourEmail-description">
              <Link to="/">I don't have an account yet!</Link>
              <div className="section-divider" />
              <SupportBox />
            </p>
          </div>
        </ContentLayout>
      )}
      {status === STATUS.NONE && !state.api.auth.user?.id ? (
        <>
          <ContentLayout customClass="app-content-layout">
            <p className="super-size text-center nl-legacyImport-title">
              Import account
            </p>
            <Form
              form={form}
              name="basic"
              initialValues={{
                email: "igor.rubinovich@gmail.com",
              }} // +420111111111
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="nl-onboarding-form"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    pattern: new RegExp(regexEmail),
                    message: "Please input valid email.",
                  },
                ]}
                required={true}
                className="nl-onboarding-form-item"
              >
                <Input
                  placeholder="e-mail"
                  className="nl-onboarding-input nl-legacyImport-email-input"
                />
              </Form.Item>
            </Form>
            <div className="nl-onboarding-footer">
              <div className="text-center">
                <ProgressButton
                  type="primary"
                  actionName="auth.firebaseRequestEmailLink"
                  htmlType="submit"
                  onClick={() => form.submit()}
                  progressText="Connecting..."
                >
                  Connect my account
                </ProgressButton>
              </div>
              <div>
                <p className="paragraph-2b text-center">
                  <Link to="/">I don't have an account yet!</Link>
                </p>
                <div className="section-divider" />
                <SupportBox />
              </div>
            </div>
          </ContentLayout>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const LegacyImport = () => {
  return <LegacyLogin />;
};
