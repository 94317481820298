import LogoOnlyIcon from "../assets/Icons/LogoOnlyIcon";
import { useAppState, useActions } from "../overmind/overmind";
// import { AUTH_FLOW_STATUS } from "@newstackdev/iosdk/dist/overmind/auth/state"; // refer to AUTH_FLOW_STATUS for more details
import { Button } from "antd";

export const Home = () => {
  const state = useAppState();
  const actions = useActions();

  if (state.api?.auth?.user?.username)
    actions.routing.historyPush({ location: "/console" });

  return (
    <>
      <div className="text-center">
        <LogoOnlyIcon />

        <p className="header-2">Newsafe Web</p>

        <div className="text-center">
          Newsafe is your keyholder, your wallet and your fanny pack. You can
          use it to access apps, store your badges, sign transactions and
          transfer your assets.
          <div className="vertical-space">&nbsp;</div>
          <h3>Progressive web3 onboarding</h3>
          Virtualize your keys using IBM Hyper Protect quantum resistant
          encrypted vault. You can claim physical access to your keys at any
          time.
        </div>
        <div className="vertical-space">&nbsp;</div>
        <Button
          style={{ fontSize: 30, height: 60, marginTop: 60 }}
          onClick={() => actions.routing.historyPush({ location: "/SignInCrosswalk" })}
        >
          Authenticate
        </Button>
      </div>
    </>
  );
};
