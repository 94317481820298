import { IOView } from "@newstackdev/iosdk/dist/types";

const Logo: IOView<{ spin?: boolean, width?: string, height?: string }> = ({ width, height, spin }) => 
    <svg className={`spinable ${spin ? "spin-long" : "" }`} width={width || "147"} height={height || "148"} viewBox="0 0 147 148" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.4515 29.748L59.5133 48.0105L63.3309 46.3081L53.8127 29.5803L48.5248 5.30762C45.2102 6.50706 41.9988 7.93866 38.9292 9.58952L53.4515 29.748Z" fill="black" />
        <path d="M15.7011 29.1942L37.1493 41.6917L50.1111 55.9174L52.8969 52.822L37.3944 41.4079L22.7173 21.3914C20.1894 23.816 17.8421 26.4213 15.6882 29.1942H15.7011Z" fill="black" />
        <path d="M44.6682 34.8669L54.3927 51.4786L57.7718 49.0281L44.9777 34.6476L34.776 12C31.7967 13.8572 28.9593 15.9208 26.2896 18.1778L44.6682 34.8669Z" fill="black" />
        <path d="M25.0778 79.3513L44.3206 79.2352L43.8821 75.0823L25.0262 78.9644L0.34082 76.4752C0.456896 80.0219 0.81802 83.5171 1.42419 86.922L25.0649 79.3513H25.0778Z" fill="black" />
        <path d="M25.0262 69.1874L43.8821 73.0694L44.3206 68.9165L25.0778 68.8004L1.42419 61.2297C0.81802 64.6346 0.456896 68.1169 0.34082 71.6765L25.0262 69.1874Z" fill="black" />
        <path d="M27.1026 59.2451L44.7332 66.9706L46.0229 62.9982L27.2187 58.884L5.65441 46.5413C4.33889 49.7656 3.25552 53.106 2.4043 56.5367L27.1026 59.2451Z" fill="black" />
        <path d="M63.0984 26.5753L65.2264 45.702L69.302 44.8379L63.4724 26.4979L63.3692 1.67065C59.8612 2.14785 56.4305 2.883 53.0901 3.8503L63.0984 26.5753Z" fill="black" />
        <path d="M31.2041 49.9444L46.8485 61.165L48.9379 57.5538L31.3975 49.6219L12.8641 33.0747C10.9038 35.9637 9.13683 39.0075 7.60205 42.1673L31.2041 49.9444Z" fill="black" />
        <path d="M102.062 66.9564L119.692 59.2309L144.391 56.5225C143.539 53.0918 142.456 49.7514 141.14 46.5271L119.576 58.8569L100.772 62.9711L102.062 66.9435V66.9564Z" fill="black" />
        <path d="M115.397 49.6087L97.8569 57.5406L99.9463 61.1518L115.591 49.9312L139.193 42.1541C137.658 38.9814 135.891 35.9505 133.931 33.0615L115.397 49.6087Z" fill="black" />
        <path d="M101.816 34.6379L89.022 49.0183L92.4011 51.4688L102.126 34.8571L120.504 18.168C117.835 15.911 114.997 13.8474 112.018 11.9902L101.816 34.6379Z" fill="black" />
        <path d="M68.1548 1.16729L73.2105 25.4658L71.3146 44.6182H75.4933L73.5974 25.4658L78.6532 1.16729C76.912 1.03832 75.1709 0.960938 73.404 0.960938C71.6371 0.960938 69.8959 1.05122 68.1548 1.16729Z" fill="black" />
        <path d="M92.9948 29.593L83.4766 46.3208L87.2942 48.0232L93.3559 29.7607L107.878 9.60221C104.809 7.95136 101.597 6.51976 98.2827 5.32031L93.0077 29.6059L92.9948 29.593Z" fill="black" />
        <path d="M83.3218 26.4838L77.4922 44.8237L81.5677 45.6878L83.6958 26.5612L93.7041 3.83614C90.3637 2.86884 86.9459 2.14659 83.4249 1.65649L83.3218 26.4838Z" fill="black" />
        <path d="M124.065 21.3914L109.388 41.4079L93.885 52.822L96.6708 55.9174L109.633 41.6917L131.081 29.1942C128.927 26.4342 126.58 23.816 124.052 21.3914H124.065Z" fill="black" />
        <path d="M93.3434 118.404L87.2817 100.142L83.4641 101.844L92.9823 118.572L98.2573 142.858C101.572 141.658 104.783 140.227 107.853 138.576L93.3305 118.417L93.3434 118.404Z" fill="black" />
        <path d="M102.126 113.297L92.4011 96.6853L89.022 99.1358L101.816 113.516L112.018 136.164C114.997 134.307 117.835 132.243 120.504 129.986L102.126 113.297Z" fill="black" />
        <path d="M115.591 98.2194L99.9463 86.9988L97.8569 90.61L115.397 98.5419L133.931 115.089C135.891 112.2 137.658 109.156 139.193 105.996L115.591 98.2194Z" fill="black" />
        <path d="M131.093 118.972L109.645 106.474L96.6835 92.2485L93.8977 95.3439L109.4 106.758L124.077 126.775C126.605 124.35 128.953 121.745 131.106 118.972H131.093Z" fill="black" />
        <path d="M121.717 68.8009L102.474 68.917L102.912 73.0699L121.768 69.1879L146.454 71.677C146.338 68.1303 145.976 64.6351 145.37 61.2302L121.73 68.8009H121.717Z" fill="black" />
        <path d="M121.768 78.9644L102.912 75.0823L102.474 79.2352L121.717 79.3513L145.357 86.922C145.964 83.5171 146.325 80.0348 146.441 76.4752L121.755 78.9644H121.768Z" fill="black" />
        <path d="M119.692 88.9208L102.062 81.1953L100.772 85.1677L119.576 89.2819L141.14 101.612C142.456 98.3874 143.539 95.047 144.391 91.6163L119.692 88.9079V88.9208Z" fill="black" />
        <path d="M78.6532 146.984L73.5974 122.686L75.4933 103.533H71.3146L73.2105 122.686L68.1548 146.984C69.8959 147.113 71.6371 147.191 73.404 147.191C75.1709 147.191 76.912 147.1 78.6532 146.984Z" fill="black" />
        <path d="M83.6958 121.588L81.5677 102.461L77.4922 103.326L83.3218 121.665L83.4249 146.493C86.933 146.016 90.3637 145.28 93.7041 144.313L83.6958 121.588Z" fill="black" />
        <path d="M44.9777 113.516L57.7718 99.1358L54.3927 96.6853L44.6682 113.297L26.2896 129.986C28.9593 132.243 31.7967 134.307 34.776 136.164L44.9777 113.516Z" fill="black" />
        <path d="M31.3975 98.5543L48.9379 90.6225L46.8485 87.0112L31.2041 98.2319L7.60205 106.009C9.13683 109.182 10.9038 112.213 12.8641 115.102L31.3975 98.5543Z" fill="black" />
        <path d="M22.7305 126.762L37.4076 106.745L52.9102 95.3309L50.1244 92.2356L37.1626 106.461L15.7273 118.959C17.8811 121.719 20.2284 124.337 22.7563 126.762H22.7305Z" fill="black" />
        <path d="M53.7998 118.572L63.318 101.844L59.5004 100.142L53.4386 118.404L38.9292 138.563C41.9988 140.213 45.2102 141.645 48.5248 142.845L53.7998 118.559V118.572Z" fill="black" />
        <path d="M27.2187 89.2819L46.0229 85.1677L44.7332 81.1953L27.1026 88.9208L2.4043 91.6292C3.25552 95.0599 4.33889 98.4003 5.65441 101.625L27.2187 89.2948V89.2819Z" fill="black" />
        <path d="M63.4861 121.665L69.3157 103.326L65.2401 102.461L63.1121 121.588L53.1038 144.313C56.4442 145.28 59.8619 146.003 63.3829 146.493L63.4861 121.665Z" fill="black" />
    </svg>

export default Logo;