import { Button } from "antd";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { ProgressButton } from "@newstackdev/iosdk/dist/Components/ProgressButton";
import { useActions, useAppState } from "../../../overmind/overmind";
import { useEffect, useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import SupportBox from "@newstackdev/iosdk/dist/Components/SupportBox";

export const NextButton: IOView<{
  isErrorSubmit?: boolean;
  nextProps?: { command: () => void | Promise<void>; text?: string };
  visible?: boolean;
  contentDescription?: React.ReactNode | string;
}> = ({
  isErrorSubmit = false,
  nextProps,
  visible = true,
  contentDescription,
}) => {
  const actions = useActions();
  const state = useAppState();

  const next = nextProps;

  const onClick = async () => {
    if (next) {
      await Promise.resolve(next.command());
    }
  };

  if (
    // isMember ||
    state.indicators.isWorking &&
    !state.onboarding.form.username &&
    !state.api.auth.authorized &&
    !state.api.auth.attempted &&
    state.firebase.token
  )
    return <></>;

  return (
    <div className="nl-onboarding-footer">
      <div className="app-control-surface">
        {visible ? (
          <ProgressButton
            progressText="Processing..."
            type="primary"
            actionName=""
            onClick={onClick}
            isErrorSubmit={isErrorSubmit}
          >
            {next?.text || "Next"}
          </ProgressButton>
        ) : (
          <></>
        )}
      </div>

      <div className="nl-domain-presale__info-text__wrapper">
        <Paragraph className="paragraph-2 nl-domain-presale__footer-paragraph">
          {contentDescription}
        </Paragraph>
        {/* <SupportBox /> */}
      </div>
    </div>
  );
};
