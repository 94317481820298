import { useAppState } from "../overmind/overmind";
import { Input, Row, Col } from "antd";
import { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";

// const propDisplay = (value: string, hidden: boolean) =>
// 	<Input type={hidden ? "text" : "password"} value={value} />;

const PropRow = ({
	obj,
	prop,
	hidden,
	label,
}: {
	obj: any;
	prop: string;
	hidden: boolean;
	label: string;
}) => {
	const [_hidden, _setHidden] = useState(hidden);

	return (
		<Row className="vertical-space">
			<Col xs={24} md={12}>
				{label}
			</Col>
			<Col xs={24} md={12}>
				<Input
					onBlur={() => _setHidden(hidden)}
					type={hidden && _hidden ? "password" : "text"}
					value={obj[prop]}
					suffix={
						hidden ? (
							<EyeOutlined onClick={() => _setHidden(!_hidden)} />
						) : (
							<></>
						)
					}
				/>
			</Col>
		</Row>
	);
};

const keyTypes = ["Publisher", "Active", "Owner"];

export const AccountInfo = () => {
	const state = useAppState();
	const acc = state.api.auth.user;

	const ncProps = Object.keys(acc || {})
		.filter((p) => acc[p] && p.startsWith("newcoin") && p.endsWith("Key"))
		.sort();

	return (
		<>
			<p className="header-1">
				Account info for <b>{acc?.username || ""}</b>
			</p>
			{keyTypes.map((keyType) => (
				<>
					<PropRow
						obj={acc}
						label={`Public ${keyType} Key`}
						prop={`newcoin${keyType}PublicKey`}
						hidden={false}
					/>
					<PropRow
						obj={acc}
						label={`Private ${keyType} Key`}
						prop={`newcoin${keyType}PrivateKey`}
						hidden={true}
					/>
				</>
			))}
		</>
	);
};
