import { useEffect } from "react";
import { useActions, useAppState } from "../overmind/overmind";
import WelcomeTitle from "../Icons/WelcomeTitle.svg";
import WelcomeVideo from "../Icons/Welcome-video.jpg";
import WelcomeConsole from "../Icons/Welcome-console.png";
import WelcomeConnections from "../Icons/Welcome-connections.png";
import WelcomeNetworks from "../Icons/Welcome-networks.jpg";
import WelcomeHelpline from "../Icons/Welcome-helpline.jpg";
import NCOIcon from "../Icons/nco-icon.svg";
import { AUTH_FLOW_STATUS } from "@newstackdev/iosdk/dist/overmind/auth/state";
import { Button } from "antd";

export const Welcome = () => {
  const state = useAppState();
  const actions = useActions();
  const sp = new URLSearchParams(state.newgraphAuth.originalQuery); //location.query || {};

  const { requestor, referer, redirectUrl } = {
    requestor: sp.get("requestor") || "",
    referer: sp.get("referer") || "",
    redirectUrl: sp.get("redirectUrl") || "",
  };

  const progress = state.indicators.specific["api.auth.authorize"];

  useEffect(() => {
    if (state.auth.status !== AUTH_FLOW_STATUS.AUTHORIZING) return;

    if (
      state.auth.authenticated &&
      !state.api.auth.user.id &&
      state.auth.status !== AUTH_FLOW_STATUS.AUTHORIZING
    ) {
      actions.routing.historyPush({ location: "/signup" });
    }
  }, [progress]);

  useEffect(() => {
    if ((requestor || referer || redirectUrl) && state.api.auth.admitted) {
      actions.routing.historyPush({ location: "/oauth2/authorize" });
    } else {
      window.localStorage.setItem(
        "redirectAuthRequest",
        state.newgraphAuth.originalQuery
      );
    }
  }, [state.api.auth.admitted]);

  const user =
    state.api.auth.user?.username || state.firebase.user?.phoneNumber;

  // if (!state.api.auth.user?.username && state.routing.location === "/welcome") {
  //   actions.routing.historyPush({ location: "/" });
  // }

  return (
    <div className="ns-welcome-page">
      <div
        className="ns-welcome-title"
        style={{ textAlign: "center", marginBottom: "60px" }}
      >
        <img src={WelcomeTitle} />
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#333",
          marginBottom: "60px",
        }}
      >
        Newsafe is a trusted interface to browse and interact with the Newcoin
        algorithm. You can connect with apps, people and networks allowing you
        to mine Newcoin with your creative energy.
      </div>
      <img
        src={WelcomeVideo}
        style={{ marginBottom: "60px", maxWidth: "100%" }}
      />
      {/* <Button
        type="primary"
        style={{
          width: "100%",
          maxWidth: "100%",
          margin: 0,
          marginBottom: "60px",
        }}
      >
        Request Invite
      </Button> */}
      <div
        style={{
          fontFamily: "Romie-Regular",
          fontSize: "50px",
          marginTop: "60px",
          marginBottom: "20px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        1
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#333",
          marginBottom: "60px",
        }}
      >
        Visualise your Watts increase and your Newcoin balance. The more Watts
        the more Newcoin you earn. The symbol for Newcoin is{" "}
        <img src={NCOIcon} style={{ width: "25px", marginBottom: "-1px" }} />{" "}
        NCO.
      </div>
      <img src={WelcomeConsole} style={{ maxWidth: "100%" }} />
      <div
        style={{
          fontFamily: "Romie-Regular",
          fontSize: "50px",
          marginTop: "60px",
          marginBottom: "20px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        2
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#333",
          marginBottom: "60px",
        }}
      >
        Expand your network and get your work reviewed by peers. You can
        ⚡️other creators and earn Watts by betting the future success of other
        creators.
      </div>
      <img src={WelcomeConnections} style={{ maxWidth: "100%" }} />
      <div
        style={{
          fontFamily: "Romie-Regular",
          fontSize: "50px",
          marginTop: "60px",
          marginBottom: "20px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        3
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#333",
          marginBottom: "60px",
        }}
      >
        Join Networks that use the Newcoin algorithm to measure your creative
        energy and get your work discovered. This will increase your Watts and
        therefore your NCO rewards. You can also send ⚡️requests to relevant
        networks.
      </div>
      <img src={WelcomeNetworks} style={{ maxWidth: "100%" }} />
      <div
        style={{
          fontFamily: "Romie-Regular",
          fontSize: "50px",
          marginTop: "60px",
          marginBottom: "20px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        4
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#333",
          marginBottom: "60px",
        }}
      >
        If you are ever lost in the etheric web of creative energy, the 24/7
        Helpline will always be there for you.
      </div>
      <img src={WelcomeHelpline} style={{ maxWidth: "100%" }} />
    </div>
  );
};
