export const Networks: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.06"
      y="0.06"
      width="29.88"
      height="29.88"
      stroke-width="0.12"
    />
    <circle cx="7.90358" cy="8.0237" r="2.01076" fill="black" />
    <circle cx="7.90358" cy="15.0667" r="2.01076" fill="black" />
    <circle cx="7.90358" cy="22.1116" r="2.01076" fill="black" />
    <circle cx="14.9464" cy="8.0237" r="2.01076" fill="black" />
    <circle cx="14.9464" cy="15.0667" r="2.01076" fill="black" />
    <circle cx="14.9465" cy="22.1116" r="2.01076" fill="black" />
    <circle cx="21.9893" cy="8.0237" r="2.01076" fill="black" />
    <circle cx="21.9893" cy="15.0667" r="2.01076" fill="black" />
    <circle cx="21.9895" cy="22.1116" r="2.01076" fill="black" />
  </svg>
);
