import { useCachedUser } from "@newstackdev/iosdk/dist/hooks/useCached";
import { ContentImage } from "@newstackdev/iosdk/dist/Components/Image";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { Row, Col } from "antd";

export const User: IOView<{
  user: string;
  code: string;
}> = ({ user, code }) => {
  const u: UserReadPublicResponse = useCachedUser({ username: user });

  const displayName = u.displayName;

  return (
    <Row justify="space-between" align="middle">
      <Col xs={10}>
        <p className="paragraph-1 typography-overflow">${code}</p>
      </Col>
      <Col xs={4} className="app-dropdown-content-image">
        <ContentImage {...u} width="30px" height="30px" />
      </Col>
      <Col xs={10}>
        <p className="text-grey typography-overflow">{displayName}</p>
      </Col>
    </Row>
  );
};
