export const NewlifeIcon: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2427 10.7208C19.2427 15.561 15.319 19.4846 10.4789 19.4846C5.6388 19.4846 1.71511 15.561 1.71511 10.7208C1.71511 5.88074 5.6388 1.95706 10.4789 1.95706C15.319 1.95706 19.2427 5.88074 19.2427 10.7208Z"
      stroke="black"
      stroke-width="0.976611"
    />
    <path
      d="M17.7697 5.42969C11.2001 10.5952 4.58692 7.28456 3.05786 5.56475"
      stroke="black"
      stroke-width="0.976611"
    />
    <path
      d="M8.36332 7.90918C7.34845 10.7775 7.13938 14.9848 10.3726 19.1882L10.655 19.5323"
      stroke="black"
      stroke-width="0.976611"
    />
    <path
      d="M12.6597 7.90918C13.2761 9.65143 13.5953 11.8877 13.0263 14.317"
      stroke="black"
      stroke-width="0.976611"
    />
    <ellipse cx="10.4132" cy="5.06502" rx="1.34041" ry="1.34041" fill="black" />
    <path
      d="M5.77051 14.8478C8.57629 13.6762 12.9632 13.1874 17.3765 16.4336"
      stroke="black"
      stroke-width="0.976611"
    />
  </svg>
);
