import { ContentLayout } from "@newstackdev/iosdk/dist/Components/ContentLayout";
import { Button, Input } from "antd";
import { RowCheckbox } from "../../Components/RowCheckbox";
import { UserUpdateRequest } from "@newstackdev/iosdk-newgraph-client-js";
import { useActions, useAppState, useEffects } from "../../overmind/overmind";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import Form from "antd/lib/form";
import omit from "lodash/omit";
import { UserUpdateHeader } from "./UserUpdateHeader";
import { ProfileSocialMediaVerify } from "../SocialMedia/ProfileSocialMediaVerify";

import { NLView } from "@newstackdev/iosdk/dist/types";
import { Link } from "react-router-dom";
import { ProgressButton } from "@newstackdev/iosdk/dist/Components/ProgressButton";
import { ProfileCryptoNetworkVerify } from "../SocialMedia/ProfileCryptoVerify";

const UserUpdateInfo: NLView<{ embedded: boolean | undefined }> = ({
  embedded,
}) => {
  return (
    <Form.Item
      hidden={embedded}
      style={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <ProgressButton
        actionName="api.user.update"
        type="primary"
        htmlType="submit"
        progressText="Updating info..."
      >
        Save Profile
      </ProgressButton>
    </Form.Item>
  );
};

export const EditProfile = () => {
  const state = useAppState();
  const actions = useActions();
  const effects = useEffects();

  const [form] = useForm();

  useEffect(() => {
    const updateUser = async () => {
      const url = new URLSearchParams(window.location.search);
      if (url.get("status") === "error") {
        effects.ux.message.error(url.get("message"));
      }

      await actions.api.user.getCurrent();
    };
    setTimeout(updateUser, 5000);
  }, []);

  const onFinish = async (
    values: UserUpdateRequest & { file: any; coverPicture: any; bio: string }
  ) => {
    await actions.user.update({
      //@ts-ignore
      user: omit(values, "file"),
      profilePicture: values.file?.fileList[0],
      coverPicture: values.coverPicture?.fileList[0],
      bio: values.bio,
    });

    actions.routing.historyPush({ location: "/profile" });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    effects.ux.message.error(JSON.stringify(errorInfo));
  };

  const sf = state.api.auth.user || {};

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        className="text-center"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={sf}
      >
        <UserUpdateHeader />
        <div className="post-create-form-width">
          <p
            className="header-5"
            style={{
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            My links
          </p>
          <Form.Item
            name="website"
            style={{
              width: "calc(100% - 10px)",
              marginLeft: "10px",
            }}
          >
            <Input placeholder="website" />
          </Form.Item>
          <h3 className="text-left">Crypto chains</h3>
          <ProfileCryptoNetworkVerify />

          <h3 className="text-left">Social networks</h3>
          <ProfileSocialMediaVerify />
          <Form.Item
            name="consentEmail"
            valuePropName="checked"
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <RowCheckbox>
              <p className="paragraph-2r" style={{ marginLeft: "20px" }}>
                I agree to receive relevant communication
              </p>
            </RowCheckbox>
          </Form.Item>
          <Form.Item
            name="consentTestgroup"
            valuePropName="checked"
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <RowCheckbox>
              <p className="paragraph-2r" style={{ marginLeft: "20px" }}>
                I would like to take part in the test group
              </p>
            </RowCheckbox>
          </Form.Item>
          <UserUpdateInfo embedded={false} />
          <Link to="/signout">
            <Button type="primary">Sign out</Button>
          </Link>
        </div>
      </Form>
    </div>
  );
};
