import { IOView } from "@newstackdev/iosdk/dist/types";
import NewlifeLogo from "../assets/Icons/MyApps/Newlife.svg";
import { useState, useEffect } from "react";
import { useCachedApplication } from "../hooks/useCached";
import { useActions, useAppState } from "../overmind/overmind";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import NewlifeThumbnail from "../assets/Icons/NewlifeThumbnail.png";
import { ProfileSocialMediaVerify } from "./SocialMedia/ProfileSocialMediaVerify";
import { ProfileCryptoNetworkVerify } from "./SocialMedia/ProfileCryptoVerify";
import { useContentImageUrl } from "../Components/MediaComponents/useContentImageUrl";
import { ContentCard } from "../Components/ContentCard";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingAnimation from "../assets/Icons/loading-animation.gif";

interface IAppResources {
  link?: string;
  logo?: string;
  description?: string;
  title?: string;
  redirectUrl?: string;
  requestor?: string;
  thumbnail?: string;
  isNetwork?: boolean;
  id?: string;
}

let appsResources: IAppResources[] = [
  {
    link: "https://os.newcoin.org/",
    requestor: "app.newgraph.io",
    redirectUrl: "https://os.newcoin.org/",
    logo: NewlifeLogo,
    description:
      "Newlife is a decentralised social network for creators, featured in Dazed, Vice, Paper, Coeval, Decrypt. By invite-only.",
    title: "Newlife",
    thumbnail: NewlifeThumbnail,
  },
];

export const filter = {
  bool: {
    must: {
      match: {
        isNetwork: true,
      },
    },
  },
};

export const Networks = () => {
  const [networks, setNetworks] = useState<UserReadPublicResponse[]>();
  const state = useAppState();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState("0");
  const admitted = state.api.auth.admitted;

  const getNetworks = async (page: string) => {
    const networks = (
      await state.api.client.user.listSearchList({
        q: JSON.stringify(filter),
        page: page,
        orderBy: "proScore",
        direction: "desc",
      })
    ).data.value;

    if (!networks || networks?.length < 1) {
      setHasMore(false);
    }

    setNetworks((oldNetworks) =>
      oldNetworks
        ? networks
          ? [...oldNetworks, ...networks]
          : oldNetworks
        : networks
    );
    setPage((page) => `${+page + 1}`);
  };

  useEffect(() => {
    getNetworks(page);
  }, []);

  return (
    <InfiniteScroll
      className="ns-infinite-scroll"
      dataLength={networks ? networks.length : 0} //This is important field to render the next data
      next={() => getNetworks(page)}
      hasMore={admitted ? hasMore : false}
      loader={
        <div className="u-margin-top-medium" style={{ textAlign: "center" }}>
          <img src={LoadingAnimation} className="loading-icon" />
        </div>
      }
    >
      <div className="ns-myApps-wrapper text-center">
        {admitted && (
          <>
            <p className="text-left paragraph-1 u-margin-bottom-small">
              Ethereum
            </p>
            <ProfileCryptoNetworkVerify />

            <p className="text-left paragraph-1 u-margin-bottom-small">
              Twitter
            </p>
            <ProfileSocialMediaVerify />
          </>
        )}
        {appsResources?.map((resources) => (
          <App resources={resources} />
        ))}
        {networks?.map((network) => (
          <App
            resources={{
              link: network.website,
              logo: network.contentUrl,
              description: network.description,
              title: network.displayName,
              isNetwork: true,
            }}
            network={network}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
};

const App: IOView<{
  resources: IAppResources;
  network?: UserReadPublicResponse;
}> = ({ resources, network }) => {
  const [selectedRequestor, setSelectedRequestor] = useState("");
  const actions = useActions();
  const state = useAppState();
  const appInfo = useCachedApplication({ owner: selectedRequestor });
  const userProfileImg = resources.isNetwork
    ? useContentImageUrl({
        contentUrl: network?.contentUrl,
        id: network?.id,
      })
    : null;

  useEffect(() => {
    const sa = appInfo;
    const qs = sa?.id
      ? `?referer=${resources.link}&requestor=${
          resources.requestor || "unsid.io"
        }&redirectUrl=${resources.redirectUrl || resources.link}`
      : "";

    if (selectedRequestor && qs) {
      actions.newgraphAuth.updateOriginalRedirectRequest({ query: qs });
      actions.routing.historyPush({ location: "/oauth2/authorize" });
    }

    if (selectedRequestor && !qs)
      actions.ux.showNotification({ message: "app misconfigured" });

    setSelectedRequestor("");
  }, [appInfo.id]);

  return (
    <ContentCard
      coverPictureUrl={resources.thumbnail}
      logoRedirectLink={
        !state.api.auth.admitted
          ? "/signup"
          : network?.username
          ? `/user/${network?.username}?isNetwork=true`
          : undefined
      }
      logoUrl={
        resources.isNetwork ? (userProfileImg as string) : resources.logo
      }
      title={resources.title}
      description={resources.description}
      buttonTitle={"GET"}
      onClick={() => {
        if (resources.isNetwork) {
          window.open(resources.link);
        } else {
          !state.api.auth.admitted
            ? actions.routing.historyPush({ location: "/signup" })
            : setSelectedRequestor(resources?.requestor || "");
        }
      }}
    />
  );
};
