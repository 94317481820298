import { AppearingComponent } from "@newstackdev/iosdk/dist/Components/Appearing";
import { Tag, Tooltip } from "antd";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { Link } from "react-router-dom";
import { MaskedInput } from "antd-mask-input";
import { NextButton } from "./NextButton";
import { RefObject, SyntheticEvent, useEffect, useRef, useMemo } from "react";
import { SpaceSpin } from "@newstackdev/iosdk/dist/Components/Spin";
import { estimateUsernamePrice } from "@newstackdev/iosdk/dist/utils/username";
import { isEmpty } from "lodash";
import { useActions, useAppState } from "../../../overmind/overmind";
import Paragraph from "antd/lib/typography/Paragraph";

export const DomainSelector: IOView = () => {
  const actions = useActions();
  const state = useAppState();
  const isMetamaskFlow = state.onboarding.metamaskFlow;
  const el: RefObject<typeof MaskedInput> = useRef({} as typeof MaskedInput);

  const username = state.onboarding.form.username || "";

  const fuia = state.onboarding.usernameAvailability[username] || "unavailable";

  useEffect(() => {
    actions.onboarding.checkAvailability({ username });
  }, []);

  const domainPrice = useMemo(
    () => estimateUsernamePrice(username),
    [username]
  );

  const isPaidUsername = useMemo(() => {
    const len = username.replace(/\.nco/, "").length;
    const couponCode = state.onboarding.form.couponCode;
    return (
      len > 1 &&
      len <= 5 &&
      !state.api.auth.user?.subscriptionStatus?.startsWith("io-domain-sale") &&
      // !state.onboarding.form.couponCode &&
      !(state.api.auth.user.status === "preregistered_premium")
    );
  }, [
    username,
    state.api.auth.user?.status,
    state.api.auth.user?.subscriptionStatus,
  ]);

  const isOneChar = useMemo(() => {
    const len = username.replace(/\.nco/, "").length;
    return (
      len === 1 &&
      !state.api.auth.user?.subscriptionStatus?.startsWith("io-domain-sale")
    );
  }, [username, state.api.auth.user?.subscriptionStatus]);

  useEffect(() => {
    actions.onboarding.checkAvailability({ username });
  }, [username]);

  const onChange = (v: SyntheticEvent & { target: { value: string } }) => {
    const un = v.target.value.replace(/\u200c/g, "").toLowerCase();

    actions.onboarding.updateForm({ username: un });
  };

  const bypassSubscribe = true;
  // !isPaidUsername ||
  // isMetamaskFlow ||
  // state.onboarding.isLegacyUpdateOngoing ||
  // state.api.auth.user.status == "preregistered_premium";

  const done = () => {
    if (!username) {
      console.log("no username");
      return;
    }

    console.log("Should bypass subscribe: ", bypassSubscribe);
    console.log("Username: ", username);
    const location =
      state.auth.authenticated || state.onboarding.form.couponCode
        ? bypassSubscribe
          ? "/signup/create"
          : "/signup/subscribe"
        : "/signup/auth";

    actions.routing.historyPush({ location });
  };

  return (
    <>
      <div className="nl-onboarding-title text-center u-margin-bottom-mega">
        <div className="header-1 u-margin-bottom-large">
          Verification Completed
        </div>
        <p className="header-3">
          You can now create your Newcoin username and your profile.
        </p>
      </div>
      <div className="nl-onboarding-form">
        <MaskedInput
          ref={el as any}
          readOnly={!isEmpty(state.api.auth.user.newcoinAccTx)}
          className={
            fuia === "unavailable"
              ? "nl-domain-presale__masked-input masked-input-error"
              : "nl-domain-presale__masked-input"
          }
          defaultValue={
            state.onboarding.form.username?.replace(/\.nco$/, "") || ""
          }
          value={state.onboarding.form.username?.replace(/\.nco$/, "")}
          size="large"
          mask="xxxxxxxx.nco"
          placeholderChar="&zwnj;"
          onChange={onChange}
          minLength={2}
          // onKeyUp={onChange}
          formatCharacters={{
            x: {
              validate: function (char: string) {
                return /^[a-zA-Z1-5\.]$/.test(char);
              },
            },
          }}
        />
      </div>
      {fuia === "checking" && (
        <SpaceSpin title="checking..." isRotating={fuia === "checking"} />
      )}
      <NextButton
        nextProps={
          isMetamaskFlow
            ? {
                command: () => actions.evm.sendSignedMessage(),
                text: "Transfer to us via Metamask",
              }
            : {
                command: done,
                text: "Create username",
              }
        }
        visible={
          (fuia === "available" &&
            !isMetamaskFlow &&
            !(isEmpty(state.onboarding.form.username) || isOneChar)) ||
          (isMetamaskFlow && fuia === "availableOnOpenSea")
        }
        contentDescription={
          !isMetamaskFlow && (
            <>
              {fuia === "unavailable" && (
                <Tag className="u-margin-top-medium flex-center">
                  Name is {fuia}
                </Tag>
              )}
              <Paragraph className="paragraph-2 nl-domain-presale__footer-paragraph">
                {isOneChar && !isMetamaskFlow ? (
                  <AppearingComponent seconds={1}>
                    To purchase a one character domain please{" "}
                    <Link
                      className="paragraph-2u nl-onboarding-link"
                      to="https://t.me/joinchat/Ezz_sQzaOK2j977siawwGQ"
                      target="_new"
                    >
                      contact us
                    </Link>
                    .
                  </AppearingComponent>
                ) : (
                  <></>
                )}
                {bypassSubscribe ? (
                  ""
                ) : (
                  <AppearingComponent seconds={1}>
                    <br />
                    {state.config.featureFlags.onboarding.premiumDomains ? (
                      <>
                        Premium usernames of 5 and fewer characters must be
                        purchased.
                        <br />
                        Estimated price: {domainPrice}
                        <br />
                        Click Next to continue.
                      </>
                    ) : (
                      <>
                        For early access please contact&nbsp;
                        <a
                          href="https://t.me/joinchat/Ezz_sQzaOK2j977siawwGQ"
                          target="_new"
                        >
                          our support team
                        </a>
                        .
                      </>
                    )}
                  </AppearingComponent>
                )}
                {state.onboarding.legacyToken &&
                !isMetamaskFlow &&
                (state.onboarding.form.displayName !==
                  state.onboarding.form.username ||
                  fuia === "unavailable") ? (
                  <>
                    <Tooltip
                      title={
                        <>
                          Your Newlife identity is now a part of the Newcoin
                          ecosystem and provides access to many exciting
                          services. You may keep your current username as the
                          display name on Newlife on the next dialog.
                        </>
                      }
                    >
                      <span> Why is my username changing?</span>
                    </Tooltip>
                    &nbsp;
                    <a
                      href="/signout"
                      onClick={() => actions.onboarding.stopLegacyImport()}
                    >
                      I am not{" "}
                      {state.onboarding.legacyUsername ||
                        state.api.auth.user.username ||
                        state.onboarding.form.displayName}
                    </a>
                  </>
                ) : (
                  ""
                )}
              </Paragraph>
              Choose your permanent domain name. It cannot be changed or
              deleted, and you own it forever. 8 characters max: a-z and 1-5
            </>
          )
        }
      />
    </>
  );
};
