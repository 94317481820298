export const SearchIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.09914 17.1983C13.5755 17.1983 17.1983 13.5755 17.1983 9.09914C17.1983 4.62275 13.5755 1 9.09914 1C4.62275 1 1 4.62275 1 9.09914C1 13.5755 4.62275 17.1983 9.09914 17.1983Z"
			stroke="#A7A7A7"
			stroke-width="1.2504"
		/>
		<path
			d="M15.0955 14.7832L20.9876 20.6754"
			stroke="#A7A7A7"
			stroke-width="1.2504"
			stroke-linecap="round"
		/>
	</svg>
);
