import { Button, message } from "antd";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { useAppState } from "../../overmind/overmind";
import { levels } from "./constants";
import { useState } from "react";
import { NotificationButton } from "../ConsoleComponents/NotificationButton";
import { useContentImageUrl } from "../MediaComponents/useContentImageUrl";
import LoadingAnimation from "../../assets/Icons/loading-animation.gif";

export const Flash: React.FC<{
  width?: number;
  height?: number;
  flashColor?: string;
}> = ({ width, height, flashColor }) => (
  <svg
    className="flash-icon"
    width={width ?? "18"}
    height={height ?? "28"}
    viewBox="0 0 18 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.201172 15.9855L14.1006 0.99585L9.46747 11.8974H17.6436L1.29132 27.7046L7.83224 15.9855H0.201172Z"
      fill={flashColor ?? "black"}
    />
  </svg>
);

export const getWattIcon = (
  user?: UserReadPublicResponse,
  manual?: { currentLevel: number; nextLevel: number }
) => {
  if (manual) {
    return {
      currentLevel: levels[manual.currentLevel],
      nextLevel: levels[manual.nextLevel],
    };
  }
  const level =
    user?.watts && Math.floor(user.watts / 10) > 0
      ? Math.floor(user.watts / 10)
      : 0;
  return levels[level];
};

export const Powerup: React.FC<{
  user: UserReadPublicResponse;
  styleOverride?: React.CSSProperties;
  powerupIconWidth?: number;
  powerupIconHeight?: number;
  amount?: number;
  className?: string;
}> = ({
  className,
  user,
  styleOverride,
  powerupIconWidth,
  powerupIconHeight,
  amount,
}) => {
  const state = useAppState();
  const [messageApi, contextHolder] = message.useMessage();
  const [isPowerupProcessing, setIsPowerupProcessing] = useState(false);
  const poweredUpUserImg = useContentImageUrl({
    id: user?.id,
    contentUrl: user.contentUrl,
  });

  const handlePowerup = async () => {
    try {
      setIsPowerupProcessing(true);
      await state.api.client.user.userRateCreate({
        targetId: user.id,
        value: amount || 1,
      });
      setIsPowerupProcessing(false);
      messageApi.open({
        content: (
          <NotificationButton
            type="powerup-notification"
            messageMode
            notificationImg={poweredUpUserImg}
          />
        ),
        duration: 5,
      });
    } catch (e) {
      setIsPowerupProcessing(false);
      messageApi.open({
        type: "error",
        content: "Powerup failed, please try again",
        duration: 5,
      });
    }
  };

  return (
    <>
      {contextHolder}
      {
        <Button
          type="primary"
          className={`${className} ns-profile-powerup`}
          style={styleOverride}
          onClick={handlePowerup}
          disabled={!user.username}
        >
          {isPowerupProcessing ? (
            <img
              src={LoadingAnimation}
              alt={LoadingAnimation}
              style={{ width: "28px", height: "28px" }}
            />
          ) : (
            <Flash width={powerupIconWidth} height={powerupIconHeight} />
          )}
        </Button>
      }{" "}
    </>
  );
};
