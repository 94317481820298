import { Col, Row, Input } from "antd";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Clipboard } from "../../Icons/Clipboard";

export const CopyClipboardHashInput: React.FC<{
  hash: string;
  highlight?: boolean;
}> = ({ hash, highlight }) => {
  const [showCopyText, setShowCopyText] = useState<boolean>(false);

  return (
    <CopyToClipboard
      text={window.location.origin + "/signup" + `?invite=${hash}`}
    >
      <div
        style={{
          position: "relative",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => {
          setShowCopyText(true);
          setTimeout(() => {
            setShowCopyText(false);
          }, 3000);
        }}
      >
        <Row>
          <Input
            value={window.location.origin + "/signup" + `?invite=${hash}`}
            disabled
            className="u-margin-bottom-medium"
          />
        </Row>
        <Row align="middle" justify="center">
          <Col>
            <Clipboard fill={"#000000"} />
            {(showCopyText || highlight) && (
              <p
                className="header-1"
                style={{
                  color: "#000000",
                }}
              >
                Your invite link has been copied to your clipboard!
              </p>
            )}
          </Col>
        </Row>
      </div>
    </CopyToClipboard>
  );
};
