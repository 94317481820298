import { NLView } from "@newstackdev/iosdk/dist/types";
import { useAppState, useActions } from "../../overmind/overmind";
import { useState, useEffect, useRef, useMemo } from "react";
import { Row, Select, Col, Avatar, Button } from "antd";
import isEmpty from "lodash/isEmpty";
import { ContentImage } from "@newstackdev/iosdk/dist/Components/Image";
import { handleOpenTwitterDM } from "../../Pages/People";

export const SearchWidget: NLView<{
  onChange?: (v: string) => void;
  showSearch?: boolean;
  setSelection?: React.Dispatch<React.SetStateAction<string>>;
  selection?: string;
}> = ({ onChange, setSelection, selection }) => {
  const state = useAppState();
  const actions = useActions();
  const [query, setQuery] = useState<string>("");
  const [twitterUsers, setTwitterUsers] = useState<any>([]);

  const [open, _setOpen] = useState(true);

  const setOpen = (v) => {
    _setOpen(v);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchForResults = async () => {
      if (query.length < 3) return;
      setTwitterUsers([]);
      setLoading(true);
      const queryUsers = actions.lists.searchUsers({ query });
      const twitterUsers = await state.api.client.user.listSearchList({
        q: "*" + query + "*",
      });

      const [_, twittUsers] = await Promise.all([queryUsers, twitterUsers]);

      //@ts-ignore
      setTwitterUsers(twittUsers.data.value);

      setLoading(false);
    };

    searchForResults();
  }, [query]);

  const _setSelection = (v: string = "") => {
    setSelection && setSelection(v);
    onChange && onChange(v);
  };

  const setInitialState = () => {
    setQuery("");
    setOpen(false);
  };

  const onSearch = (query: string) => {
    setQuery(query);
    setOpen(true);
  };

  const handleInvite = async (twitterUser: { handler: string }) => {
    const responseHash = await actions.api.user.invite({
      //@ts-ignore
      userInvite: {
        fullName: twitterUser.handler as string,
      },
    });
    if (responseHash) {
      handleOpenTwitterDM(responseHash || "", twitterUser.handler as string);
    }
  };

  const onSelect = (value: string) => {
    if (!value) {
      setOpen(false);
      setQuery("");
      return;
    }
    setOpen(false);
    const v = value.replace(/^[@#]/, "");

    if (v === selection?.toString()) {
      return;
    }

    _setSelection(v);

    setQuery("");
  };

  const refWrapper = useRef<any>();
  const refDropdown = useRef<any>();
  useEffect(() => {
    const outsideClick = (e) => {
      let el = e.target;
      const rs = [refWrapper.current, refDropdown.current];
      while (el) {
        if (rs.includes(el)) return;
        el = el.parentNode;
      }
      setInitialState();
    };
    document.body.addEventListener("click", outsideClick);

    return () => document.body.removeEventListener("click", outsideClick);
  });

  return (
    <Row
      ref={refWrapper}
      align="bottom"
      style={{
        width: "100%",
        height: "70px",
        cursor: "unset",
      }}
      className="search-widget-container"
    >
      <div style={{ width: "100%", height: "100%", outline: "none" }}>
        <Select
          className="search-widget"
          mode={undefined}
          allowClear
          tabIndex={1}
          notFoundContent={null}
          style={{
            borderRadius: "50px",
            height: "70px",
            outline: "none",
            padding: "0px 20px",
          }}
          showSearch
          searchValue={query}
          loading={loading}
          open={open && !isEmpty(query)}
          placeholder={"Search..."}
          onSearch={(query) => onSearch(query)}
          onSelect={(value: string) => onSelect(value)}
          suffixIcon={null}
          clearIcon={
            !loading ? (
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  color: "black",
                }}
                className="paragraph-2b search-widget-clear-icon"
                onClick={() => setInitialState()}
              >
                Cancel
              </div>
            ) : (
              <></>
            )
          }
        >
          {(state.lists.search.users?.results?.value
            ? state.lists.search.users?.results?.value.concat(twitterUsers)
            : twitterUsers
          )?.map((u) => {
            return (
              <Select.Option
                value={`@${u.handler ?? u.username}`}
                disabled={!isEmpty(u.handler)}
              >
                <Row
                  gutter={18}
                  className="app-main-full-width-only"
                  wrap={true}
                  style={{ alignItems: "center" }}
                >
                  <Col>
                    <Avatar src={<ContentImage {...u} />} />
                  </Col>
                  <Col>
                    <p className="paragraph-2b">{u.handler ?? u.username}</p>
                  </Col>
                  {u?.handler && (
                    <Col style={{ marginLeft: "auto" }}>
                      <Button
                        type="primary"
                        onClick={() => handleInvite({ handler: u?.remoteId })}
                        style={{
                          height: "26px",
                          borderRadius: "40px",
                          padding: "6px 9px",
                          width: "fit-content",
                          fontSize: "11px",
                        }}
                      >
                        Invite
                      </Button>
                    </Col>
                  )}
                </Row>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </Row>
  );
};
