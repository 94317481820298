export const TelegramIcon: React.FC = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_642_21024)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2461 16C32.2461 24.8366 25.0827 32 16.2461 32C7.40954 32 0.246094 24.8366 0.246094 16C0.246094 7.16344 7.40954 0 16.2461 0C25.0827 0 32.2461 7.16344 32.2461 16ZM16.8194 11.8122C15.2632 12.4595 12.1529 13.7993 7.4886 15.8314C6.73119 16.1326 6.33442 16.4273 6.2983 16.7154C6.23726 17.2023 6.84703 17.3941 7.67737 17.6552C7.79032 17.6907 7.90735 17.7275 8.02733 17.7665C8.84426 18.032 9.94318 18.3427 10.5145 18.355C11.0327 18.3662 11.611 18.1526 12.2496 17.7141C16.6076 14.7723 18.8573 13.2854 18.9985 13.2534C19.0981 13.2308 19.2362 13.2023 19.3298 13.2855C19.4233 13.3686 19.4141 13.5261 19.4042 13.5683C19.3438 13.8259 16.9502 16.0512 15.7115 17.2027C15.3254 17.5617 15.0515 17.8164 14.9955 17.8745C14.8701 18.0048 14.7422 18.128 14.6194 18.2465C13.8604 18.9781 13.2913 19.5268 14.6509 20.4227C15.3043 20.8533 15.8271 21.2093 16.3487 21.5645C16.9183 21.9524 17.4865 22.3394 18.2216 22.8212C18.4088 22.944 18.5877 23.0715 18.7619 23.1957C19.4248 23.6683 20.0204 24.0929 20.7562 24.0252C21.1837 23.9858 21.6254 23.5838 21.8496 22.3848C22.3797 19.5512 23.4216 13.4116 23.6624 10.8816C23.6835 10.66 23.6569 10.3763 23.6356 10.2518C23.6143 10.1272 23.5698 9.94982 23.4079 9.81847C23.2162 9.66292 22.9203 9.63012 22.7879 9.63245C22.1861 9.64305 21.2628 9.9641 16.8194 11.8122Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_642_21024">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0.246094)"
        />
      </clipPath>
    </defs>
  </svg>
);
