export const NewEnergy: React.FC<{ size?: string }> = ({ size }) => (
  <svg
    width={size ?? "66"}
    height={size ?? "66"}
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.9647 33.2542C31.861 13.4134 28.9261 10.4234 32.9646 10.4234C37.0031 10.4234 32.9647 32.9996 32.9647 33.2542Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M32.936 32.9208C34.0876 52.6157 37.1501 55.5837 32.936 55.5837C28.7219 55.5837 32.936 33.1736 32.936 32.9208Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M32.7415 32.9698C52.5822 31.8662 55.5723 28.9312 55.5723 32.9698C55.5723 37.0083 32.9961 32.9698 32.7415 32.9698Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M33.0811 32.9475C13.3863 34.0991 10.4182 37.1616 10.4182 32.9475C10.4182 28.7334 32.8284 32.9475 33.0811 32.9475Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M32.7953 33.1574C46.0444 18.3475 46.0834 14.158 48.939 17.0136C51.7947 19.8693 32.9753 32.9774 32.7953 33.1574Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M33.013 32.9049C19.9009 47.6456 19.9677 51.9098 16.9879 48.93C14.0081 45.9502 32.8343 33.0836 33.013 32.9049Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M32.8446 32.8067C47.6546 46.0558 51.8441 46.0948 48.9885 48.9505C46.1328 51.8061 33.0247 32.9868 32.8446 32.8067Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
    <path
      d="M33.0906 33.0182C18.35 19.9062 14.0857 19.973 17.0655 16.9931C20.0454 14.0133 32.9119 32.8395 33.0906 33.0182Z"
      fill="black"
      stroke="white"
      stroke-width="0.0140563"
    />
  </svg>
);
