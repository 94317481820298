import {
  UserCreateRequest,
  UserUpdateRequest,
  UserReadPublicResponse,
  ContentType,
} from "@newstackdev/iosdk-newgraph-client-js";
import { get } from "lodash";
import { Action } from "../../iosdk/overmind/overmind";

export const create: Action<{
  noRouting?: boolean;
  user: UserCreateRequest;
  preregisterCreate?: boolean;
  legacyToken?: string;
  couponCode?: string; // coupons provide special conditions
  inviteHash?: string; // a personal or non-personal invite code (not always a hash)
}> = async (
  { state, effects, actions },
  { noRouting, user, preregisterCreate, legacyToken, inviteHash, couponCode }
) => {
  try {
    const currUser = state.api.auth.user || {};
    if (currUser.id && preregisterCreate) {
      return;
    }

    if (
      currUser.id &&
      currUser.username &&
      !["imported"].includes(currUser.status || "") &&
      !state.onboarding.isLegacyUpdateOngoing
    ) {
      return;
    }
    const pn =
      state.flows.user.create.form.phone || state.firebase.user?.phoneNumber;

    if (!pn && !state.onboarding.isSocialFirstSignUp) {
      !noRouting &&
        actions.routing.historyPush({ location: "/SignInCrosswalk" });
      return;
    }

    const extUser = {
      ...user,
      legacyToken,
      couponCode, // coupons provide special conditions
      inviteHash, // a personal or non-personal invite code (not always a hash)
    };

    pn && (user.phone = pn);

    const nu = preregisterCreate
      ? await state.api.client.user.preregisterCreate(extUser)
      : await state.api.client.user.userCreate(
          extUser,
          // {
          //   ...user,
          //   // legacyToken: state.onboarding.create.legacyToken,
          //     // state.newsafe.token ||
          // }
          {
            headers: {
              Authorization: state.newsafe.token,
            },
          }
        );

    // !preregisterCreate &&
    //   effects.ux.notification.open({
    //     message: "Success!",
    //     description: "User was created successfully. Welcome to Newcoin!",
    //   });

    const refresher = {
      type: state.newsafe.token ? "newsafe" : "firebase",
      token: state.newsafe.token || state.firebase.token,
    };

    // await actions.app.signOut({ keepFbUser: true, noRouting: true });

    if (refresher.type == "newsafe")
      await actions.newgraphAuth.refreshSessionToken({ jwt: refresher.token });
    else await actions.firebase.refreshApiToken();

    await actions.api.auth.authorize();
    actions.routing.historyPush({ location: "/connect" });
  } catch (ex) {
    effects.ux.message.error(
      JSON.stringify(
        get(ex, "error.errorMessage.details") || get(ex, "message")
      )
    );
  }
};

export const update: Action<{
  user: UserUpdateRequest;
  profilePicture?: any;
  coverPicture?: any;
  bio?: string;
}> = async (
  { state, effects, actions },
  { user, profilePicture, coverPicture, bio }
) => {
  const id = state.api.auth.user?.id ?? "";

  //@ts-ignore
  await state.api.client.user.userUpdate({ ...user, bio, id });

  const hasUploadProfile = profilePicture && profilePicture.originFileObj;
  const hasUploadCover = coverPicture && coverPicture.originFileObj;
  if (hasUploadProfile) {
    //@ts-ignore
    const uploadInfo = await state.api.client.user.uploadCreate({
      filename: profilePicture.name,
      contentType: profilePicture.type,
    });

    const r = await fetch(uploadInfo.data.url as string, {
      method: "PUT",
      body: profilePicture.originFileObj,
    });
  }
  if (hasUploadCover) {
    const uploadCoverInfo = await state.api.client.request({
      path: "/user/upload/cover",
      body: {
        file: coverPicture,
        contentType: coverPicture.type,
        filename: coverPicture.name,
      },
      method: "POST",
      secure: true,
      type: ContentType.Json,
      format: "json",
    });

    const r = await fetch(uploadCoverInfo.data.url as string, {
      method: "PUT",
      body: coverPicture.originFileObj,
    });
  }
  actions.api.user.cache({
    force: true,
    user: {
      ...state.api.auth.user,
      ...user,
      id,
      ...(hasUploadProfile ? { contentUrl: "PROCESSING" } : {}),
    },
  });
};

export const userRateRequestCreate: Action<{
  userId: string;
}> = async ({ state }, { userId }) => {
  await state.api.client.request({
    path: "/user/userRate/request",
    body: {
      targetId: userId,
    },
    method: "POST",
    secure: true,
    type: ContentType.Json,
    format: "json",
  });
};

export const getUserRateRequestList: Action<
  {
    userId: string;
  },
  UserReadPublicResponse[]
> = async ({ state }, { userId }) => {
  const requestees = (
    await state.api.client.request({
      path: "/user/rated/out/users/requests",
      query: {
        id: userId,
      },
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
    })
  ).data.value;
  return requestees;
};
