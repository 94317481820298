import {
  create,
  update,
  userRateRequestCreate,
  getUserRateRequestList,
} from "./user";

export default {
  actions: { create, update, userRateRequestCreate, getUserRateRequestList },
  effects: {},
  state: {},
};
