export const SUBSCRIBE_ON_RELOAD_KEY = "_subscribeOSNow";

export const getOneSignal = () => {
  const osd = (((window || {}) as any).OneSignalDeferred =
    ((window || {}) as any).OneSignalDeferred || []);
  return osd;
};

export const getOneSignalInstance = () =>
  ((window || {}) as any).OneSignalInstance;

let OneSignalPromise: Promise<any> | null = null;
let OsInitialized = null;

export const IN_PROGRESS = Symbol("onesignal-inprogress");
export const NOT_INITIALIZED = Symbol("onesignal-inprogress");

// export const oneSignalSubscription: { value: string | typeof IN_PROGRESS | typeof NOT_INITIALIZED } = signal<string | typeof IN_PROGRESS>("");
// export const oneSignalSubscriptionTouched: { value: boolean } = signal<boolean>(false);

let calledLogin = false;

export const getServiceWorkerStatus = async () => {
  try {
    const e = await navigator.serviceWorker.getRegistration(
      "http://localhost:3000"
    );
    if (e) {
      console.log("oshelper: sw reg ok", e);
      return true;
    } else {
      // oneSignalSubscription.value = NOT_INITIALIZED;
      console.log("oshelper: sw not registered", e);
      return false;
    }
    return e;
  } catch (e) {
    console.log("oshelper: sw reg error", e);
    return false;
  }
};

export const addOsSubscriptionListener = (handler: (ev) => void) => {
  const os = getOneSignal();

  // os.emitter.on(os.EVENTS.SUBSCRIPTION_CHANGED, handler);

  getOneSignal().push(() => {
    const os = getOneSignalInstance();
    os.emitter.on(os.EVENTS.SUBSCRIPTION_CHANGED, handler);
  });
};

export const addSubsciptionEventListeners = (handler: (et) => (ev) => void) => {
  getOneSignal().push(() => {
    const os = getOneSignalInstance();
    for (const et of Object.values(os.EVENTS)) os.emitter.on(et, handler(et));
  });
};

export const initOneSignal = () => {
  if (typeof window == "undefined") return;

  const os = getOneSignal();

  const init = async function (os: any) {
    (window as any).OneSignalInstance = os;

    if (OneSignalPromise) return;

    OneSignalPromise =
      OneSignalPromise ||
      os.init({
        appId: "06f364ed-cde5-411a-9c04-efcb1b3caeb7",
        safari_web_id:
          "web.onesignal.auto.0f5650bb-bc45-4dc5-a4b8-8665864d5e48",
        //  "06f364ed-cde5-411a-9c04-efcb1b3caeb7",
        // safari_web_id:
        //     "web.onesignal.auto.2ccd5ae7-3528-4a1e-96f6-138881299499",
        notifyButton: {
          enable: false,
        },
        path: "",
        serviceWorkerParam: { scope: "" }, ///onesignal/push
        serviceWorkerPath: "OneSignalSDKWorker.js",
        // serviceWorkerPath: "OneSignalSDK.sw.js",
        allowLocalhostAsSecureOrigin: true,
      });
  };
  os.push(init);
};

// initOneSignal();

// let addAliasOnSubscriptionListener: ((e: any) => void) | null = null;
