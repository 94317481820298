export const Info: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.05" y="0.05" width="29.9" height="29.9" stroke-width="0.1" />
    <path
      d="M14.7264 3.59961C22.2522 3.59961 21.9672 8.44461 21.9672 14.9996V18.1346C21.9672 18.2296 22.0812 18.4481 22.5372 18.4481"
      stroke="black"
    />
    <path
      d="M23.1357 18.4199C23.1357 24.6899 20.5422 24.4049 13.9872 24.4049"
      stroke="black"
    />
    <path
      d="M22.2524 11.2949C23.1074 11.1999 24.3526 11.3343 24.7604 12.15C25.1683 12.9657 25.1024 13.86 25.1024 15.285"
      stroke="black"
    />
    <path
      d="M22.2522 18.4197C23.1072 18.5147 24.3524 18.3803 24.7602 17.5646C25.168 16.7489 25.1022 15.8546 25.1022 14.4296"
      stroke="black"
    />
    <path
      d="M15.2469 3.59961C7.7212 3.59961 8.00616 8.44461 8.00616 14.9996V18.1346C8.00616 18.2296 7.89216 18.4481 7.43616 18.4481"
      stroke="black"
    />
    <path
      d="M7.72119 11.2949C6.86619 11.1999 5.62104 11.3343 5.21319 12.15C4.80535 12.9657 4.87119 13.86 4.87119 15.285"
      stroke="black"
    />
    <path
      d="M7.72107 18.4197C6.86607 18.5147 5.62091 18.3803 5.21307 17.5646C4.80522 16.7489 4.87107 15.8546 4.87107 14.4296"
      stroke="black"
    />
    <rect
      x="11.707"
      y="22.4102"
      width="5.7"
      height="3.99"
      rx="1.995"
      fill="black"
    />
  </svg>
);
