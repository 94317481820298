import { useActions, useAppState } from "../../overmind/overmind";
import {
  useCachedPowerups,
  useCachedUser,
} from "@newstackdev/iosdk/dist/hooks/useCached";
import { useSetTitle } from "@newstackdev/iosdk/dist/hooks/useSetTitle";
import { useParams, Link } from "react-router-dom";
import { SOCIAL_MEDIA, UserSocials } from "../SocialMedia/constants";
import { Row, Col, Button, Avatar, Tabs, message } from "antd";
import { SocialLink } from "../SocialMedia/SocialLink";
import { useContentImageUrl } from "../../Components/MediaComponents/useContentImageUrl";
import { NewlifeIcon } from "../../Icons/socials/NewlifeIcon";
import { Powerup, getWattIcon } from "../../Components/Powerup";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import { Share } from "../../Components/ShareModal";
import FullScreenLoading from "../../Components/FullScreenLoading";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useState } from "react";
import Markdown from "react-markdown";
import { UserCardWithPowerup } from "../../Components/UserCardWithPowerup";
import LoadingAnimation from "../../assets/Icons/loading-animation.gif";
import { NotificationButton } from "../../Components/ConsoleComponents/NotificationButton";
import RequestIcon from "../../Icons/requestIcon.svg";
import InviteIcon from "../../Icons/ConsoleInviteIcon.svg";

// import { MetaMaskInpageProvider } from "@metamask/providers";

// import { AFSelector } from "../../../libs/af-widget/src/App";
import { useActivationFunctions } from "af-widget/dist/af-widget";
import { ContentCard } from "../../Components/ContentCard";

export const UserProfile = () => {
  let { username: paramsUsername } = useParams<{ username: string }>();
  const state = useAppState();
  let username = paramsUsername || state.api.auth.user?.username || "";
  const url = new URLSearchParams(window.location.search);
  const isHistoryConnection = url.get("isConnection") === "true";
  const isHistoryNetwork = url.get("isNetwork") === "true";
  const isConsole = url.get("isConsole") === "true";

  const user =
    window.location.pathname === "/profile"
      ? state.api.auth.user
      : useCachedUser({ username }, true);
  useSetTitle(user?.username);

  return user?.id ? (
    <>
      {(isHistoryConnection || isHistoryNetwork) && (
        <Link
          to={
            isConsole
              ? "/console"
              : isHistoryConnection
              ? "/people"
              : "/networks"
          }
        >
          <ArrowLeftOutlined />
        </Link>
      )}
      <UserWidgetHeading user={user} />
    </>
  ) : (
    <FullScreenLoading />
  );
};

export const UserWidgetHeading: React.FC<{
  user?: any;
}> = ({ user }) => {
  const state = useAppState();
  const action = useActions();
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const userProfileImg = useContentImageUrl({ ...user });
  const coverImg = useContentImageUrl({
    contentUrl: user.coverContentUrl,
    id: user.id,
  });
  const [activeKey, setActiveKey] = useState("powered");
  const userPowerups = useCachedPowerups(user, true);
  const poweredOut =
    userPowerups?.out?.value
      ?.sort((a, b) => (b.powered || 0) - (a.powered || 0))
      .slice(0, 20) || [];
  const poweredIn =
    userPowerups?.in?.value
      ?.sort((a, b) => (b.powered || 0) - (a.powered || 0))
      .slice(0, 20) || [];

  const getSortedMediaButtons = () => {
    const sortedSocials = user
      ? pickBy(
          user,
          (value, key) =>
            !isEmpty(value) &&
            [
              ...Object.values([
                ...SOCIAL_MEDIA,
                "evm/0x1",
                "ethAddress",
                "instagram",
                "website",
              ]),
            ].includes(key as UserSocials)
        )
      : [];

    const socialKeys = Object.keys(sortedSocials);

    return socialKeys.map((social, i) => {
      if (!isEmpty(user["evm/0x1"]) && social === "ethAddress") {
        return;
      }
      return (
        <Row style={socialKeys.length > i + 1 ? { marginBottom: "8px" } : {}}>
          <SocialLink platform={social} user={user} iconUsernameView />
        </Row>
      );
    });
  };

  const wattIcon = getWattIcon(user);

  const isCurrentUserProfile = user.id === state.api.auth.user?.id;

  const [messageApi, contextHolder] = message.useMessage();
  const requestedPowerupUser = useContentImageUrl({
    id: user?.id,
    contentUrl: user.contentUrl,
  });
  const uaf = useActivationFunctions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
      }}
    >
      {contextHolder}
      <Row
        className="u-margin-bottom-large"
        style={{ maxWidth: "100%", width: "100%" }}
        align="middle"
        justify="center"
      >
        {user.coverContentUrl && (
          <img
            style={{
              width: "100%",
              height: 183,
              borderRadius: "6px",
              backgroundColor: "#F3F3F3",
              objectFit: "cover",
            }}
            src={coverImg}
          />
        )}
      </Row>
      <Row
        className="u-margin-bottom-large"
        style={{ width: "100%" }}
        justify="space-between"
        align="middle"
      >
        {user.contentUrl && (
          <Col>
            <Avatar
              style={{
                border: "0.5px solid #EBEBEB",
                height: 113,
                width: 113,
              }}
              src={userProfileImg}
            />
            <div
              style={{
                backgroundColor: "#dfff83",
                height: "36px",
                width: "36px",
                padding: "5px",
                borderRadius: "20px",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <img
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (user["evm/0x1"] || user["ethAddress"]) {
                    window.open(
                      `https://sepolia.etherscan.io/token/0x935e8f7eace4dd560b2a32918a148a4f5218016c?a=${
                        user["evm/0x1"] ?? user["ethAddress"]
                      }`
                    );
                  }
                }}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(wattIcon)}`}
              />
            </div>
          </Col>
        )}
        <Col>
          <Powerup
            user={user}
            styleOverride={{ width: 95, height: 95, borderRadius: 10.5 }}
            powerupIconHeight={70}
            powerupIconWidth={70}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <p className="header-2 u-margin-bottom-medium u-margin-right-small">
              {user.username}
            </p>
            {isCurrentUserProfile && (
              <a href="editProfile">
                <Button
                  style={{
                    width: "55px",
                    height: "29px",
                    borderRadius: "6px",
                    padding: "0.5px",
                  }}
                  className="paragraph-2"
                  type="primary"
                >
                  Edit
                </Button>
              </a>
            )}
          </Row>
          <Row className="u-margin-bottom-large">
            <p className="paragraph-1 ">{user.description}</p>
          </Row>
        </Col>
      </Row>
      <Row justify={"space-between"} className="u-margin-bottom-large">
        <Col>
          <Row style={{ marginBottom: "8px" }}>
            <a
              href={`https://app.newlife.io/user/${user.id}`}
              // target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              target="_blank"
            >
              <p className="u-margin-right-small">
                <NewlifeIcon />
              </p>
              <p className="flex-column justify-center">{user.username}</p>
            </a>
          </Row>
          {getSortedMediaButtons()}
        </Col>
        <Col>
          <Row style={{ height: "100%" }} align="bottom" justify={"end"}>
            <Share
              urlToShare={`${window.location.host}/user/${user.username}`}
            />
          </Row>
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"} style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey={activeKey}
          type="line"
          onChange={(activeKey) => {
            setActiveKey(activeKey);
          }}
          style={{
            border: "none",
            width: "100%",
          }}
        >
          <Tabs.TabPane tab="About" key="about">
            <Markdown className="ns-markdown">{user.bio}</Markdown>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Given ⚡️" key="powering">
            {poweredOut?.map((user, i) => (
              <UserCardWithPowerup key={i} user={user} />
            ))}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Received ⚡️" key="powered">
            {poweredIn?.map((user, i) => (
              <UserCardWithPowerup key={i} user={user} />
            ))}
          </Tabs.TabPane>
        </Tabs>

        <Row style={{ width: "100%" }}>
          <Col style={{ width: "calc(100% - 70px)" }}>
            <Button
              type="primary"
              style={{
                height: "55px",
                padding: 0,
                width: "100%",
                margin: "40px 0 0 0",
              }}
              onClick={async () => {
                setIsRequestProcessing(true);
                try {
                  await action.user.userRateRequestCreate({
                    userId: user.id as string,
                  });
                  messageApi.open({
                    content: (
                      <NotificationButton
                        type="powerup-notification"
                        messageMode
                        notificationImg={requestedPowerupUser}
                        middleIcon={
                          <img
                            src={RequestIcon}
                            alt={RequestIcon}
                            style={{ width: "38px", height: "38px" }}
                          />
                        }
                      />
                    ),
                    duration: 5,
                  });
                } catch (e) {
                  messageApi.open({
                    type: "error",
                    content: "Request failed, please try again",
                    duration: 5,
                  });
                }
                setIsRequestProcessing(false);
              }}
            >
              {isRequestProcessing ? (
                <img
                  src={LoadingAnimation}
                  alt={LoadingAnimation}
                  style={{ width: "28px", height: "28px" }}
                />
              ) : (
                "REQUEST"
              )}
            </Button>
          </Col>
          <Col>
            <Powerup
              user={user}
              styleOverride={{
                width: 55,
                height: 55,
                borderRadius: 8.5,
                margin: "40px 0 0 15px",
                justifyItems: "end",
              }}
              powerupIconHeight={30}
              powerupIconWidth={30}
            />
          </Col>
        </Row>
      </Row>

      {user.username != "guildxyz.nco" ? (
        <></>
      ) : (
        <>
          <br />
          <br />

          {user.username == "guildxyz.nco" ? (
            <>
              {uaf.isConnected ? (
                <></>
              ) : (
                <Button
                  disabled={uaf.isConnecting}
                  onClick={() => uaf.connect()}
                >
                  {user?.username} is an onchain verification provider.
                  <br />
                  Click to choose a verification method.
                </Button>
              )}
              {uaf.activationFunctions.map((af, index) => {
                return (
                  <ContentCard
                    title={af.context}
                    description={af.description}
                    onClick={() => {
                      uaf.activate(index);
                      // action.routing.historyPush({ location: "/invite" })
                    }}
                    buttonTitle="ACTIVATE"
                    logoRedirectLink="/invite"
                    logoUrl={InviteIcon}
                  />
                );
              })}
            </>
          ) : (
            // <ActivationByContractAddress /> :
            <></>
          )}
        </>
      )}
    </div>
  );
};
