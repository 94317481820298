import { Row, Col, Button } from "antd";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { ProgressBar } from "../Components/ConsoleComponents/ProgressBar";
import { useActions } from "../overmind/overmind";
import importedTwitterUsers from "./SocialMedia/top.twitter.newgraph.json";
import { NotificationButton } from "../Components/ConsoleComponents/NotificationButton";
import { CircularArrow } from "../assets/Icons/CircularArrow";
import { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useAppState } from "../overmind/overmind";
import { ContentCard } from "../Components/ContentCard";
import { explore, filterOrderBy, handleOpenTwitterDM } from "./People";
import { UserCardWithPowerup } from "../Components/UserCardWithPowerup";
import FullScreenLoading from "../Components/FullScreenLoading";
import { filter as filterNetwork } from "./Networks";
import helplineContent from "./Helpline.content.json";
import { FAQs } from "../Components/FAQs";
import { Link } from "react-router-dom";
import ConsoleBottomImg from "../Icons/ConsoleBottomImage.jpg";
import TwitterSharing from "../Components/TwitterSharing";
import dynamicContent from "./Console.dynamic.json";
import { fischerYates } from "../utils/random";
import UserInviteInfoDialog from "./Invite/UserInviteInfoDialog";

type ConsoleStatus = {
  watts: number;
  ncoBalance: number;
  timeStamp: string;
};

const triggerWattMilestone = (nextWatt: number) => {
  const milestones = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const prevWatt = window.localStorage.getItem("consoleStatus")
    ? +JSON.parse(window.localStorage.getItem("consoleStatus") as string).watts
    : 0;

  if (prevWatt === 0) {
    return milestones.some((milestone) => nextWatt % milestone === 0);
  }

  if (milestones.some((milestone) => nextWatt % milestone === 0)) {
    if (milestones.every((milestone) => prevWatt % milestone !== 0)) {
      return true;
    }
  }
  return false;
};

export const Console = () => {
  const action = useActions();
  const state = useAppState();
  const [isTwitterSharingOpen, setIsTwitterSharingOpen] = useState(false);
  const [twitterSharingMilestone, setTwitterSharingMilestone] = useState("");
  const [isRecentUsersLoading, setIsRecentUsersLoading] = useState(false);
  const [isNewNetworksLoading, setIsNewNetworksLoading] = useState(false);
  const [recentUsers, setRecentUsers] = useState<UserReadPublicResponse[]>();
  const [newNetworks, setNewNetworks] = useState<UserReadPublicResponse[]>();
  const [notifications, setNotifications] = useState<
    Array<{
      badgeName: string;
      timeStamp: string;
      type: string;
      userIdFrom: string;
    }>
  >([]);
  const [twitterUsers, setTwitterUsers] = useState([
    ...importedTwitterUsers.slice(0, 3),
  ]);
  const [dynamicContentCards, setDynamicContentCards] = useState<any>();
  const [inviteProcessing, setInviteProcessing] = useState(false);
  const [visibleHashDialog, setVisibleHashDialog] = useState(false);
  const [hash, setHash] = useState<string | undefined>();

  useEffect(() => {
    const loadConsoleContent = async () => {
      //@ts-ignore
      const u = await state.api.client.outgraph.inviteesList();
      if (!u.data.length) {
        setTwitterUsers(fischerYates(importedTwitterUsers).slice(0, 3));
      } else {
        setTwitterUsers(
          u.data
            //@ts-ignore
            .map((i) => [i.handler || "", i.watts || 0, i.remoteId || 0])
            .slice(0, 3)
        );
      }
    };
    loadConsoleContent();
  }, []);

  useEffect(() => {
    setDynamicContentCards(
      fischerYates([...twitterUsers, ...dynamicContent] as any[])
    );
  }, [twitterUsers]);

  const helplineCollapseItems = helplineContent[0].questions
    .slice(0, 3)
    .map((question, i) => {
      return {
        key: i + question?.title,
        label: (
          <p style={{ fontSize: "16px", fontWeight: 500, lineHeight: "22px" }}>
            {question?.title}
          </p>
        ),
        children: (
          <p
            style={{
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "25.5px",
            }}
          >
            {question?.description}
          </p>
        ),
      };
    });

  const loadUsers = useCallback(async () => {
    setIsRecentUsersLoading(true);
    const loadedUsers = (
      await state.api.client.user.listSearchList({
        q: JSON.stringify(explore),
        page: "0",
        orderBy: filterOrderBy["Recent"],
      })
    ).data.value as UserReadPublicResponse[];

    setRecentUsers(loadedUsers.slice(0, 4));

    setIsRecentUsersLoading(false);
  }, []);

  const getNetworks = useCallback(async () => {
    setIsNewNetworksLoading(true);
    const networks = (
      await state.api.client.user.listSearchList({
        q: JSON.stringify(filterNetwork),
        page: "0",
        orderBy: "created",
      })
    ).data.value;
    setIsNewNetworksLoading(false);
    setNewNetworks(networks?.slice(0, 3));
  }, []);

  const [consoleStatus, setConsoleStatus] = useState<ConsoleStatus>();
  const [animationLoading, setAnimationLoading] = useState(false);

  const [animate, setAnimate] = useState(false);
  const [isNCODoneAnimating, setIsNCODoneAnimating] = useState(false);
  const user = state.api.auth.user;

  const handleInvite = async (username: string, twitterUser: any) => {
    setInviteProcessing(true);
    const responseHash = await action.api.user.invite({
      //@ts-ignore
      userInvite: {
        fullName: username,
      },
    });
    setHash(responseHash);
    setVisibleHashDialog(true);
    setInviteProcessing(false);
    if (responseHash) {
      console.log(twitterUser, "ggggg");
      handleOpenTwitterDM(
        responseHash || "",
        //@ts-ignore
        twitterUser.remoteId ?? (twitterUser[2] as string)
      );
    }
  };

  useEffect(() => {
    const load = async () => {
      await loadUsers();
      await getNetworks();
    };
    if (
      !isEmpty(window.localStorage.getItem("newsafe-auth-redirect-request"))
    ) {
      action.routing.historyPush({ location: "/oauth2/authorize" });
    }
    window.localStorage.getItem("consoleStatus") &&
      setConsoleStatus(
        JSON.parse(
          window.localStorage.getItem("consoleStatus") as string
        ) as ConsoleStatus
      );
    load();
  }, []);

  useEffect(() => {
    const filteredStream = state.websockets.messages.activityStream.filter(
      (activity: { title: string; timeStamp: string; original: any }) => {
        const ac = JSON.parse(JSON.stringify(activity)); // unpack proxy object

        return (
          ac?.original?.payload?.message === "badge_updated" &&
          moment(new Date(ac?.timeStamp)).isAfter(
            new Date(consoleStatus?.timeStamp || 0)
          )
        );
      }
    );

    const newNotifications = filteredStream.map((activity: any) => {
      return {
        badgeName: activity.original.payload?.badge?.name,
        timeStamp: activity.timeStamp,
        type:
          activity.original.payload?.badge?.context ||
          activity.original.payload?.badge?.label,
        userIdFrom: activity.original.payload?.badge?.target?.id,
      };
    });

    setNotifications(newNotifications);
  }, [state.websockets.messages.activityStream.length, consoleStatus]);

  const calculatePercentage = (watts: number) => {
    if (watts % 10 === 0 && watts !== 0) {
      return 100;
    } else {
      return (watts % 10) * 10;
    }
  };

  return isRecentUsersLoading || isNewNetworksLoading || inviteProcessing ? (
    <FullScreenLoading />
  ) : (
    <div>
      <Row className="u-margin-bottom-small" style={{ position: "relative" }}>
        <ProgressBar
          percentage={
            !animate && consoleStatus?.watts
              ? calculatePercentage(consoleStatus?.watts)
              : 0
          }
          wattAmount={consoleStatus?.watts || 0}
          prevWattAmount={
            window.localStorage.getItem("consoleStatus")
              ? JSON.parse(
                  window.localStorage.getItem("consoleStatus") as string
                ).watts
              : 0
          }
          animate={isNCODoneAnimating}
          onEnd={() => {
            setTimeout(() => setAnimationLoading(false), 1000);
            setAnimate(false);
            if (triggerWattMilestone(consoleStatus?.watts || 0)) {
              const milestoneNumber =
                Math.floor((consoleStatus?.watts as number) / 10) * 10;
              setTwitterSharingMilestone(
                `Congratulations on surpassing the ${milestoneNumber}WATT milestone!`
              );
              setIsTwitterSharingOpen(true);
            }
            window.localStorage.setItem(
              "consoleStatus",
              JSON.stringify(consoleStatus)
            );
          }}
        />
      </Row>
      <Row className="u-margin-bottom-large">
        <div
          className="paragraph-1"
          style={{
            width: "100%",
            top: "5vh",
            display: "flex",
            flexDirection: "column",
            zIndex: 1000,
            border: "1px solid #C6C6C6",
            height: "70px",
            borderRadius: "60px",
            padding: "5px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div>
            {animate ? (
              <CountUp
                //@ts-ignore
                end={state.api.auth.user.newcoinNCOBalance || 0}
                decimals={0}
                onEnd={() => {
                  setIsNCODoneAnimating(true);
                }}
                onStart={() => setAnimationLoading(true)}
              />
            ) : (
              consoleStatus?.ncoBalance.toFixed(0)
            )}{" "}
            NCO
          </div>
        </div>
      </Row>
      {notifications.length === 0 &&
        (animationLoading ? (
          <FullScreenLoading />
        ) : (
          <Col>
            <Row className="u-margin-bottom-small">
              <div style={{ fontSize: "20px" }}>To grow more, you can</div>
            </Row>
            {dynamicContentCards?.map((content, i) => {
              if (!content) return;
              if (content[0]) {
                return (
                  <Row
                    justify="center"
                    className={
                      i === dynamicContentCards.length - 1
                        ? "u-margin-bottom-large"
                        : "u-margin-bottom-small"
                    }
                  >
                    <ContentCard
                      title={content[0]}
                      onClick={() =>
                        handleInvite(
                          content.handler ?? (content[0] as string),
                          content
                        )
                      }
                      buttonTitle="INVITE"
                      description={`Invite ${
                        content[0] as string
                      } and gain Watts when they gain.`}
                    />
                  </Row>
                );
              }
              const {
                title,
                description,
                externalLink,
                buttonTitle,
                buttonUrl,
                logoFilename,
                logoRedirectLink,
                isTwitter,
              } = content;

              const logo = require(`../Icons/${logoFilename}`);

              return (
                <Row
                  justify="center"
                  className={
                    i === dynamicContentCards.length - 1
                      ? "u-margin-bottom-large"
                      : "u-margin-bottom-small"
                  }
                >
                  <ContentCard
                    title={title}
                    description={description}
                    onClick={() =>
                      externalLink
                        ? window.open(
                            isTwitter
                              ? `https://twitter.com/intent/tweet?url=${window.location.host}/user/${state.api.auth.user.username}`
                              : buttonUrl,
                            "_blank"
                          )
                        : (window.location.href = buttonUrl || "")
                    }
                    buttonTitle={buttonTitle}
                    logoRedirectLink={logoRedirectLink}
                    logoUrl={logo}
                  />
                </Row>
              );
            })}
            <Row className="u-margin-bottom-large">
              <div style={{ fontSize: "20px" }}>Your Profile</div>
              {user?.id && user?.contentUrl && (
                <ContentCard
                  title={user?.username}
                  description={user?.description}
                  onClick={() =>
                    // action.routing.historyPush({ location: "/editProfile" }) for some reason this doesn't work, gets stuck on screen
                    (window.location.href = "/editProfile")
                  }
                  buttonTitle="EDIT"
                  logoRedirectLink="/profile"
                  contentId={user?.id}
                  contentUrl={user?.contentUrl}
                />
              )}
            </Row>
            <Row className="u-margin-bottom-large">
              <div style={{ fontSize: "20px" }}>Recent Users</div>
              {recentUsers?.map(
                (recentUser, i) =>
                  recentUser.id !== user.id && (
                    <UserCardWithPowerup
                      user={recentUser}
                      key={`${i + (recentUser.id as string)}`}
                    />
                  )
              )}
            </Row>
            <Row className="u-margin-bottom-large">
              <div style={{ fontSize: "20px" }}>Newest Networks</div>
              {newNetworks &&
                newNetworks?.map((network, i) => (
                  <ContentCard
                    logoRedirectLink={
                      network?.username
                        ? `/user/${network?.username}?isNetwork=true&isConsole=true`
                        : undefined
                    }
                    contentUrl={network?.contentUrl}
                    contentId={network?.id}
                    title={network?.displayName}
                    description={network?.description}
                    buttonTitle={"GET"}
                    onClick={() => {
                      window.open(network?.website);
                    }}
                    key={i}
                  />
                ))}
            </Row>
            <Row className="u-margin-bottom-medium">
              <div style={{ fontSize: "20px" }}>Helpline</div>
              <FAQs
                category={helplineContent[0].category}
                items={helplineCollapseItems}
                removeBottomMargin
              />
            </Row>
            <Row justify={"center"} className="u-margin-bottom-large">
              <Link to="/helpline">
                <Button
                  style={{
                    width: "150px",
                    height: "26px",
                    fontSize: "10px",
                    padding: "6px 15px",
                  }}
                  type="primary"
                >
                  EXPLORE FULL FAQ'S
                </Button>
              </Link>
            </Row>
            <Row>
              <img src={ConsoleBottomImg} style={{ width: "100%" }} />
            </Row>
          </Col>
        ))}
      <TwitterSharing
        title={twitterSharingMilestone}
        shareText={twitterSharingMilestone}
        open={isTwitterSharingOpen}
        setOpen={setIsTwitterSharingOpen}
      />
      {notifications?.length > 0 && (
        <Row justify="space-between">
          <Col>
            {notifications?.map((notification, i) => (
              <NotificationButton type={notification?.type} key={i} />
            ))}
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setConsoleStatus({
                  watts: state.api.auth.user.watts || 0,
                  timeStamp: notifications[0]?.timeStamp,
                  //@ts-ignore
                  ncoBalance: state.api.auth.user.newcoinNCOBalance || 0,
                });
                setNotifications([]);
                setAnimate(true);
              }}
              style={{
                height: "80px",
                width: "80px",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularArrow />
            </Button>
          </Col>
        </Row>
      )}
      {visibleHashDialog && (
        <UserInviteInfoDialog
          hash={hash}
          setVisible={setVisibleHashDialog}
          visible={visibleHashDialog}
        />
      )}
    </div>
  );
};
