import { Button, Row } from "antd";
import { useActions, useAppState } from "../../overmind/overmind";
import { useState, useMemo } from "react";
import UserInviteInfoDialog from "./UserInviteInfoDialog";
import twitterUsers from "../SocialMedia/top.twitter.newgraph.json";
import { fischerYates } from "../../utils/random";
import FullScreenLoading from "../../Components/FullScreenLoading";
import { ContentCard } from "../../Components/ContentCard";
import { useAsyncMemo } from "../../hooks/useAsyncMemo";
import { handleOpenTwitterDM } from "../People";

export interface IUserInvite {
  phone: string;
  fullName: string;
  instagram: string;
  twitter?: string;
  facebook?: string;
  pinterest?: string;
  tumblr?: string;
  youtube?: string;
  discord?: string;
  soundcloud?: string;
  email?: string;
}

export const UserInvite: React.FC<{ isPostSignupInvite: boolean }> = ({
  isPostSignupInvite,
}) => {
  const actions = useActions();
  const state = useAppState();

  const [hash, setHash] = useState<string | undefined>();
  const [visibleHashDialog, setVisibleHashDialog] = useState(false);
  const [isInviteProcessing, setIsInviteProcessing] = useState(false);
  const [shuffledUsers, setShuffledUsers] = useState([...twitterUsers]);
  const user = state.api.auth.user;
  const selected10Users = shuffledUsers?.slice(0, 10);

  const users = useAsyncMemo(async () => {
    //@ts-ignore
    const u = await state.api.client.outgraph.inviteesList();
    if (!u.data.length) return; // some defaults
    setShuffledUsers(
      //@ts-ignore
      u.data.map((i) => [i.handler || "", i.watts || 0, i.remoteId || 0])
    );
    // What I need is to get updated json file with twitter ids and also get info about inviteeslist - also needs to have ids there.
    // return []
  }, []);

  useMemo(() => {
    setShuffledUsers((users) => fischerYates(users));
  }, [users]);

  const onFinish = async (suggestedUserIndex?: number) => {
    try {
      setIsInviteProcessing(true);
      //@ts-ignore
      const responseHash = await actions.api.user.invite({ userInvite: null });
      if (suggestedUserIndex) {
        setShuffledUsers((users) => [
          ...users.slice(0, suggestedUserIndex),
          ...users.slice(suggestedUserIndex + 1),
        ]);
      }
      setHash(responseHash);
      setVisibleHashDialog(true);
      setIsInviteProcessing(false);
      if (responseHash && suggestedUserIndex) {
        handleOpenTwitterDM(
          responseHash || "",
          selected10Users[suggestedUserIndex][2] as string
        );
      }
    } catch (ex) {
      setIsInviteProcessing(false);
    }
  };

  return (
    <InviteesList
      maxItems={4}
      title={"List of invited users"}
      availableInvites={user.availableInvites}
      isPostSignupInvite={isPostSignupInvite}
      onInvite={onFinish}
      visibleHashDialog={visibleHashDialog}
      setVisibleHashDialog={setVisibleHashDialog}
      hash={hash}
      isInviteProcessing={isInviteProcessing}
      suggestedUsers={selected10Users}
    />
  );
};

export const InviteesList: React.FC<{
  maxItems?: number;
  title?: string;
  availableInvites?: number;
  isPostSignupInvite?: boolean;
  onInvite: (suggestedUserIndex?: number) => void;
  visibleHashDialog: boolean;
  hash?: string;
  isInviteProcessing?: boolean;
  suggestedUsers?: (string | number)[][];

  setVisibleHashDialog: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  maxItems,
  visibleHashDialog,
  setVisibleHashDialog,
  hash,
  availableInvites,
  onInvite,
  isInviteProcessing,
  suggestedUsers,
}) => {
  return (
    <div className="text-center">
      {maxItems !== undefined && (
        <>
          <p
            className="header-1 font-variant-none"
            style={{ marginBottom: "40px" }}
          >
            You have {availableInvites || 0} invites
          </p>
          {isInviteProcessing ? (
            <FullScreenLoading />
          ) : (
            <>
              <Button type="primary" onClick={() => onInvite()}>
                Invite someone
              </Button>
              <Row className="paragraph-1 u-margin-top-large u-margin-bottom-medium">
                Suggestions
              </Row>
              {isInviteProcessing ? (
                <Row>
                  <FullScreenLoading />
                </Row>
              ) : (
                suggestedUsers?.map((twitterUsernameUser, i) => (
                  <ContentCard
                    title={twitterUsernameUser[0] as string}
                    onClick={() => onInvite(i)}
                    buttonTitle="INVITE"
                    description={`Invite ${
                      //@ts-ignore
                      twitterUsernameUser.handler ??
                      (twitterUsernameUser[0] as string)
                    } and gain Watts when they gain.`}
                  />
                ))
              )}
            </>
          )}
        </>
      )}
      {/* <CreatorsList
        users={inviteesList.value as UserInvitationReadPublicResponse[]}
      /> */}

      {visibleHashDialog && (
        <UserInviteInfoDialog
          hash={hash}
          setVisible={setVisibleHashDialog}
          visible={visibleHashDialog}
        />
      )}
    </div>
  );
};
