import { ReactNode } from "react";
import { PostReadResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { useAppState } from "../../overmind/overmind";

export type Sizes = "small" | "medium" | "full" | "";
export type ContentImageProps = {
  id?: string;
  href?: string;
  contentUrl?: string;
  coverContentUrl?: string;
  mask?: ReactNode;
  aspectRatio?: string | number;
  width?: string;
  height?: string;
  blurHash?: string;
  contentType?: string;
  size?: Sizes;
  created?: string;
  neverHide?: boolean;
  content?: string;
  ref?: any;
} & PostReadResponse;

const imageSizes: Record<Sizes, string> = {
  small: "500",
  medium: "1000",
  full: "",
  "": "",
};

export const useContentImageUrl = ({
  id,
  contentUrl,
  size,
}: ContentImageProps) => {
  const _sizer = imageSizes[size || ""];
  const sizer = _sizer ? [_sizer, _sizer].join("x") + "/" : "";

  const state = useAppState();

  return contentUrl
    ? `${state.config.settings.newgraph.mediaBucket}/images/${id}/${sizer}${contentUrl}`
    : "";
};
