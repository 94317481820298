import LoadingAnimation from "../assets/Icons/loading-animation.gif";
interface FullScreenLoadingProps {}

const FullScreenLoading: React.FC<FullScreenLoadingProps> = () => {
  return (
    <div className="full-screen-loading-mask">
      <div className="full-screen-loading-content">
        <img src={LoadingAnimation} className="loading-icon" />
      </div>
    </div>
  );
};

export default FullScreenLoading;
