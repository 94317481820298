import { useActions, useAppState } from "../../overmind/overmind";
import { Row, Button } from "antd";
import LogoOnlyIcon from "../../assets/Icons/LogoOnlyIcon";
import { SocialLink } from "../SocialMedia/SocialLink";
import { SOCIAL_MEDIA } from "../SocialMedia/constants";
import { useMetamask } from "../../hooks/useMetamask";
import { ContentType } from "@newstackdev/iosdk-newgraph-client-js";

export const SignInCrosswalk: React.FC<{ signupMode?: boolean }> = ({
  signupMode,
}) => {
  const state = useAppState();
  const actions = useActions();
  const { isMetamaskConnected, connectToMetamask } = useMetamask();

  const user =
    state.api.auth.user?.username || state.firebase.user?.phoneNumber;

  const connectWithMetamask = async () => {
    // if user is not already connected, force them to connect their wallet
    if (!isMetamaskConnected) return connectToMetamask();

    //@ts-ignore
    const selectedAddress = window.ethereum.selectedAddress;

    // sign the nonce with the selected public address of the connected wallet
    //@ts-ignore
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [
        JSON.stringify({
          address: selectedAddress,
          //@ts-ignore
          chain: `evm/${window.ethereum.chainId}`,
        }),
        selectedAddress,
      ],
    });

    // send another request to login endpoint with the signature which is signed with the user's nonce
    const jwt = (
      await state.api.client.request({
        path: "/auth/evm/generateToken",
        body: {
          payload: {
            address: selectedAddress,
            //@ts-ignore
            chain: `evm/${window.ethereum.chainId}`,
          },
          signature: `${signature}`,
        },
        method: "POST",
        secure: true,
        type: ContentType.Json,
        format: "json",
      })
    ).data.jwt;

    await actions.newsafe.authorize({ jwt });
    actions.routing.historyPush({ location: "/connect" });
    // you can return an access token for the user, or maybe temporary credentials and then sign in, it's up to you
    // ...
  };

  return (
    <>
      <Row
        style={{
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 50,
        }}
      >
        <LogoOnlyIcon spin={true} width="180px" height="180px" />
      </Row>
      <div className="u-margin-bottom-mega text-center">
        <p className="header-1 u-margin-bottom-large">
          {signupMode ? "Choose a sign up method" : "Sign in"}
        </p>
      </div>

      {!user && (
        <div className="text-center">
          {SOCIAL_MEDIA.map((social, i) => (
            <Row justify="center" key={i}>
              <SocialLink platform={social} buttonView />
            </Row>
          ))}
          <Row justify="center">
            <Button
              type="primary"
              className="header-2 ns-socialButton"
              onClick={() =>
                actions.routing.historyPush({
                  location: signupMode
                    ? "/signup/phoneVerification"
                    : "/SignIn",
                })
              }
            >
              Phone
            </Button>
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              className="header-2 ns-socialButton"
              onClick={() => connectWithMetamask()}
            >
              Metamask
            </Button>
          </Row>
        </div>
      )}
    </>
  );
};
