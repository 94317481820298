import { IOView } from "@newstackdev/iosdk/dist/types";
import { useActions, useAppState } from "../../../overmind/overmind";
import { useEffect } from "react";

export const Done: IOView = () => {
  const state = useAppState();
  const actions = useActions();

  useEffect(() => {
    if (state.api.auth.authorized) {
      actions.routing.historyPush({ location: "/welcome" });
    }
  }, [state.api.auth.authorized]);

  return <></>;
};
