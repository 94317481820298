import { IOView } from "@newstackdev/iosdk/dist/types";

export const SwapVertical: IOView<{
	swapped: boolean;
	setSwapped: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ swapped, setSwapped }) => (
	<svg
		width="40"
		height="40"
		viewBox="0 0 102 102"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			cursor: "pointer",
		}}
		onClick={() => setSwapped(!swapped)}
	>
		<circle cx="51" cy="51" r="51" fill="black" />
		<path
			d="M80.8684 62.925L67.471 75.5436L54.3072 63.0029"
			stroke="white"
			stroke-width="3.47"
			stroke-miterlimit="10"
		/>
		<path
			d="M67.471 74.5308L67.471 38.901C67.471 38.901 68.1046 27.0614 53.6598 27.0614"
			stroke="white"
			stroke-width="3.47"
			stroke-miterlimit="10"
		/>
		<path
			d="M22.2169 39.6798L35.6144 27.0613L48.7781 39.6019"
			stroke="white"
			stroke-width="3.47"
			stroke-miterlimit="10"
		/>
		<path
			d="M35.6142 28.074L35.6142 63.7039C35.6142 63.7039 34.9806 75.5435 49.4254 75.5435"
			stroke="white"
			stroke-width="3.47"
			stroke-miterlimit="10"
		/>
	</svg>
);
