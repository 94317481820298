import { useAppState, useActions } from "../../overmind/overmind";
import { Row, Col, Slider, Alert, Button } from "antd";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { SwapVertical } from "../../assets/Icons/SwapVertical";
import { IOView } from "@newstackdev/iosdk/dist/types";
import {
	useCachedPool,
	useCachedUser,
} from "@newstackdev/iosdk/dist/hooks/useCached";
import { mapValues } from "lodash";
import { StakeKiosk } from "../Stake";
import { NewcoinRecept } from "@newstackdev/iosdk/dist/Components/Recepts";
import { SearchUserTicker } from "./SearchUserTicker";

export type Pair = { source: string; target: string };

const isPairSupported = (source, target) => {
	return source == "GNCO" ? true : false;
};

const toNumber = (val: string | number) =>
	typeof val == "number" ? val : Number((val || "").split(/ /)[0] || 0);
const roundTo = (n: number, decimals: number = 2) => {
	const fact = Math.pow(10, decimals);
	return Math.round(n * fact) / fact;
};

export const SelectSwapAmount: IOView<{}> = () => {
	const [amount, setAmount] = useState<number>(1);
	const [swapped, setSwapped] = useState<boolean>(false);
	const [confirming, setConfirming] = useState<boolean>(false);
	const state = useAppState();
	const actions = useActions();
	const { source, target: targetTicker } = mapValues(useParams<Pair>(), (v) =>
		v.toUpperCase()
	);
	// const
	// const [targetTicker] = useState<string>(target);
	const [userQuery, setUserQuery] = useState<string>("");

	const targetPool = useCachedPool({ code: targetTicker?.toUpperCase() });
	const targetUser = useCachedUser({ username: targetPool.owner });

	const setTargetTicker = (ticker: string) => {
		actions.routing.historyPush({
			location: `/swap/GNCO/${ticker.toUpperCase()}`,
		});
	};

	const acc = state.accountManagement.auth.account?.account || {};
	const tokens = state.accountManagement.auth.account.tokens || [];
	// const ncoBalance = Number(acc.core_liquid_balance?.split(" ")[0]);
	const [ncoBalance, ncoSymbol] = (acc.core_liquid_balance || "")
		?.split(" ")
		.map((_, i) => (i ? _ : Number(_ || 0)));
	const gncoSymbol = tokens.find((t) => t.symbol == "GNCO")?.symbol;

	const sourceBalance =
		source == "NCO"
			? toNumber(ncoBalance)
			: toNumber((state.newcoin.mainPool?.acc_balances || [])[0]);

	// tokens.find((t) => t.symbol == "GNCO")?.amount) || 0;

	const [tx, setTx] = useState("");

	const stake = async () => {
		let _tx = "";
		try {
			if (source === "GNCO" && targetTicker === "NCO") {
				const tx = await actions.accountManagement.unstakeGnco({
					amount,
				});
				_tx = tx.TxID_unstakeMainDAO;
			}
			if (source === "GNCO" && !["GNCO", "NCO"].includes(targetTicker)) {
				const tx = await actions.api.user.stake({
					user: targetUser,
					amount: amount + ".0000",
				});
				console.log(tx);
				_tx = tx.TxID_stakePool;
			}
		} catch (ex) {
			actions.ux.showNotification({
				message: "Couln't complete the operation.",
			});
		}
		if (_tx) setTx(_tx);
		else
			actions.ux.showNotification({
				message: "Couln't complete the operation.",
			});
	};

	const msrows = state.accountManagement?.marketStatus?.rows || [];
	const ms = msrows[0];
	const { quote, base } = ms || {};
	const targetPrice = 1;

	const maxCanBuy = sourceBalance;

	const reset = async () => {
		setAmount(Math.min(maxCanBuy, 100));
		setConfirming(false);
		setTx("");
	};

	const targetAmount = roundTo(amount * 0.83);

	const priceTotal = roundTo(amount * targetPrice);
	const sourcePercentage = sourceBalance
		? Math.round((amount * 100) / sourceBalance!)
		: 0;

	const isSupported = isPairSupported(source || "", targetTicker || "");

	useEffect(() => {
		window.history.pushState(
			{},
			"",
			`${window.location.origin}/swap/${source}/${targetTicker}`
		);
	}, [targetTicker]);

	return !confirming ? (
		<div className="app-content-wrapper">
			<Col className="stake-card-wrapper u-margin-bottom-large">
				<Row
					onDoubleClick={() => setAmount(128)}
					justify="space-between"
					align="middle"
					className="stake-input-square"
				>
					<Col xs={12} sm={10}>
						<p className="paragraph-1 typography-overflow">
							{amount}
						</p>
					</Col>
					<Col xs={12} sm={10} className="text-right">
						<p className="paragraph-1 typography-overflow">
							${source}
						</p>
					</Col>
				</Row>
				<div className="stake-input-flip">
					<SwapVertical
						swapped={swapped}
						setSwapped={() => {
							actions.routing.historyPush({
								location: `/swap/${targetTicker}/${source}`,
							});
							setTargetTicker(source);
						}}
					/>
				</div>
				<Row
					justify="space-between"
					align="middle"
					className="stake-input-square"
				>
					<Col xs={12} sm={10}>
						<p className="paragraph-1 typography-overflow">
							{roundTo(priceTotal * 0.83)}
						</p>
					</Col>
					<Col id="searchUserTicker" xs={12} sm={10}>
						{/* <p className="paragraph-1">${target}</p> */}
						<SearchUserTicker
							value={userQuery}
							onChange={setUserQuery}
							defaultValue={targetTicker}
							setTargetTicker={setTargetTicker}
						/>
					</Col>
				</Row>
			</Col>
			<Col>
				{sourceBalance ? (
					<></>
				) : (
					<Alert
						type="warning"
						message={`You have no ${source} balance`}
					></Alert>
				)}

				{isSupported ? (
					<></>
				) : (
					<Alert
						type="warning"
						message="The exchange pair is not yet supported"
					></Alert>
				)}

				<Slider
					disabled={!(sourceBalance && isSupported)}
					min={sourceBalance > 100 ? 100 : 1}
					max={maxCanBuy}
					onChange={setAmount}
					trackStyle={{
						borderRadius: "0px",
						background: "black",
						border: "1px solid black",
						height: "2px",
					}}
					handleStyle={{
						background: "black",
						border: "2px solid black",
						width: "30px",
						height: "30px",
						marginTop: "-15px",
					}}
				/>
				<Col>
					<p className="text-center">
						{sourcePercentage}% from {sourceBalance} ${source}{" "}
						balance A 17% fee (${roundTo(priceTotal * 0.17)} GNCO)
						will be distributed to the community
					</p>
				</Col>
			</Col>
			<Row
				style={{
					width: "100%",
					alignItems: "end",
					flex: 1,
					bottom: 0,
					paddingTop: 50,
				}}
				justify="center"
			>
				<Col
					className="text-center"
					style={{
						width: "100%",
						paddingTop: 50,
					}}
				>
					{/* <Col className="text-center"> */}
					<Button
						disabled={
							!(sourceBalance && isSupported) || priceTotal < 100
						}
						// actionName="api.newcoin.stake"
						onClick={() => setConfirming(true)}
					>
						Confirm Swap
					</Button>
					{/* </Col> */}
				</Col>
			</Row>
		</div>
	) : (
		<>
			<StakeKiosk
				actionName="api.user.stake"
				amount={amount}
				priceTotal={priceTotal}
				stake={stake}
				onCancel={() => setConfirming(false)}
			/>

			<NewcoinRecept visible={!!tx} tx={tx} onDone={reset}>
				<div
					style={{
						width: "min(80%, 300px)",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				>
					<p className="header-2">Newfoundation</p>
					{"-".repeat(47)}
					<br />
					<Row>
						<Col span={10}>{targetTicker} Tokens</Col>
						<Col span={6} className="text-right">
							{targetAmount}
						</Col>
						<Col span={8}>&nbsp;{targetTicker}</Col>
					</Row>
					<Row>
						<Col span={10}>Main DAO Fee</Col>
						<Col span={6} className="text-right">
							{roundTo(priceTotal - targetAmount)}
						</Col>
						<Col span={8}>&nbsp;GNCO</Col>
					</Row>
					<br />
					{"-".repeat(47)}
					<br />
					<b>
						TOTAL CHARGED: {priceTotal} {source}
					</b>
					<br />
					{"-".repeat(47)}
					<br />
					Paid by {state.api.auth.user.username} via Newsafe
					<br />
					<br />
					{/* {chargeTime} */}
					<br />
					<br />
					Transaction Id:
					<br />
					{tx?.replace(/(.{4})/g, "$1 ")}
					<br />
					<br />
					{"-".repeat(47)}
					<br />
					<b>THANK YOU FOR SHOPPING AT NEWSAFE</b>
				</div>
			</NewcoinRecept>
		</>
	);
};
