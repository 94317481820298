import { Button, Modal } from "antd";
import { CrossCircle } from "../../assets/Icons/CrossCircle";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { useState } from "react";

export const UnsuccessfulLoginModal: IOView<{
  redirect: ({ location }) => void;
}> = ({ redirect }) => {
  const [isVisible, setIsVisible] = useState(
    window.localStorage.getItem("isSigningUserUknown") === "true"
  );
  const clearIsSigningUserUknown = () => {
    window.localStorage.removeItem("isSigningUserUknown");
  };
  return (
    <Modal
      closeIcon={<CrossCircle />}
      visible={isVisible}
      okText="Yes"
      cancelText="No"
      onCancel={() => {
        clearIsSigningUserUknown();
        setIsVisible(false);
      }}
      footer={false}
      className="nl-white-box-modal"
      modalRender={() => {
        return (
          <>
            <div className="text-left">
              <p className="header-1">Oops!</p>
              <p className="u-margin-top-large paragraph-1">
                Looks like this phone number is not registered.
              </p>
              <p className="u-margin-top-medium u-margin-bottom-medium  paragraph-1">
                You will need to be an existing member from the mobile app, or
                have an invite to sign up.
              </p>
              <p className="u-margin-bottom-mega paragraph-1">
                Import your account if you are registered on newlife mobile, or
                please sign up with your invite code.
              </p>
            </div>

            <Button
              className="nl-button-primary"
              onClick={() => {
                clearIsSigningUserUknown();
                redirect({ location: "/auth/newlife-members" });
              }}
            >
              <p className="paragraph-1">I have a mobile account</p>
            </Button>
            <Button
              className="nl-button-primary"
              onClick={() => {
                clearIsSigningUserUknown();
                setIsVisible(false);
              }}
            >
              <p className="paragraph-1">Sign up</p>
            </Button>
          </>
        );
      }}
    ></Modal>
  );
};
