import { useState } from "react";
import { Modal, Row, Upload, Col } from "antd";
import isEmpty from "lodash";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { Edit } from "../../Icons/Edit";
import { CrossCircle } from "@newstackdev/iosdk/dist/Components/Icons/CrossCircle";

function getBase64(file: RcFile | undefined): Promise<string> {
  if (!file) throw new Error("No data received");

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const UploadPicture: React.FC<{
  onChange?: ({ fileList: [] }) => void;
  placeholderImgSrc?: string;
  placeholderTxt?: string;
}> = ({ onChange, placeholderImgSrc, placeholderTxt }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview || "");
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
    onChange && onChange({ fileList });
  };

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false}
        multiple={false}
        maxCount={1}
        itemRender={(...args) => {
          return <>{args[0]}</>;
        }}
        openFileDialogOnClick
      >
        {fileList?.length > 0 ? null : (
          <Row>
            <Col>
              {isEmpty(fileList) && placeholderImgSrc && (
                <img
                  alt="placeholderImg"
                  src={placeholderImgSrc}
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    border: "0.5px solid #EBEBEB",
                  }}
                />
              )}
            </Col>
            <Col>
              <Row justify="start">
                {!placeholderImgSrc && (
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 34px 0px #D8D8D8",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      fontSize: "16px",
                      color: "#A8A8A8",
                      fontWeight: 350,
                      padding: "0 25px",
                    }}
                    className="u-margin-bottom-small"
                  >
                    {placeholderTxt ?? "upload picture"}
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </Upload>
      <Modal
        closeIcon={<CrossCircle />}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        modalRender={() => (
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        )}
      />
    </>
  );
};
