import { IOView } from "@newstackdev/iosdk/dist/types";
import { NewcoinRecept } from "@newstackdev/iosdk/dist/Components/Recepts";
import { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "antd";
import { useActions, useAppState } from "../overmind/overmind";
import { Slider } from "antd";
import { Link } from "react-router-dom";
import { rmunits } from "../functions";
import { YouAreAboutTo } from "./Stake";

export const RAMMarket: IOView = () => {
  const actions = useActions();
  const state = useAppState();
  const [amount, setAmount] = useState(0);
  const [chargedAmount, setChargedAmount] = useState(100);
  const [chargeTime, setChargeTime] = useState(0);
  const [tx, setTx] = useState("");

  // const buyRam = async (form) => {
  // 	console.log("Buying ram...");

  // 	try {
  // 		const r = await actions.accountManagement.buyRam({
  // 			amount: Number(amount),
  // 		});
  // 		if (!r.TxID) {
  // 			actions.ux.showNotification(r.originalResponse.errorMessage);
  // 			return;
  // 		}

  // 		const charged = r.originalResponse.processed.action_traces
  // 			.find((a) => a.act.name == "buyrambytes")
  // 			.inline_traces.find((a) => a.act.name == "transfer").act
  // 			.data.quantity;

  // 		setChargedAmount(charged);
  // 		setChargeTime(r.originalResponse.processed.block_time);
  // 		setTx(r.TxID);
  // 	} catch (ex) {
  // 		actions.ux.showNotification({
  // 			message: "Couln't complete the operation.",
  // 		});
  // 	}
  // };

  const msrows = state.accountManagement?.marketStatus?.rows || [];
  const ms = msrows[0];
  const { quote, base } = ms || {};
  const ramPrice =
    quote && base
      ? (rmunits(quote.balance) * ms.quote.weight) /
        (rmunits(base.balance) * base.weight)
      : 0;

  const [form] = Form.useForm();

  const reset = () => {
    setTx("");
    setAmount(0);
    setConfirming(false);
    form.resetFields();
  };
  const acc = state.accountManagement.auth.account;
  useEffect(() => {
    form.setFieldsValue({ amount });
  }, [amount]);

  const [confirming, setConfirming] = useState(false);

  const priceTotal = ramPrice * amount;

  const ncoBalance =
    Number(acc.account?.core_liquid_balance?.split(" ")[0]) || 0;

  const percentOfNco = Math.round((priceTotal * 100) / ncoBalance);

  const maxCanBuy = ncoBalance / ramPrice;

  const divider = () => <span className="text-cut">{"-".repeat(75)}</span>;

  return (
    <>
      {confirming ? (
        <YouAreAboutTo
          amount={amount}
          priceTotal={priceTotal}
          //   onSubmit={() => buyRam(form)}
          actionNameText="buy space"
          actionName="actions.accountManagement.buyRam"
          onCancel={() => setConfirming(false)}
        />
      ) : (
        <div>
          <div className="text-center">
            You own
            <br />
            <p className="header-1">
              {Math.round(acc?.account?.cpu_limit.max / 1000) / 1000} MB
            </p>
            of RAM Space
          </div>

          {/* <h2 className="text-center">Buy</h2> */}
          <br />
          <Row gutter={24}>
            <Col span={24}>
              <Slider
                min={100}
                max={maxCanBuy}
                onChange={setAmount}
                trackStyle={{
                  borderRadius: "0px",
                  background: "black",
                  border: "1px solid black",
                  height: "2px",
                }}
                handleStyle={{
                  background: "black",
                  border: "2px solid black",
                  width: "30px",
                  height: "30px",
                  marginTop: "-15px",
                }}
              />
            </Col>
            <Col className="text-center" span={24}>
              {amount / 1000} MB
            </Col>
          </Row>

          <div className="text-center" style={{ marginBottom: "150px" }}>
            <>Estimate price: </>
            {amount && <>{Math.round(priceTotal)} NCO</>}
            {!amount && (
              <>{Math.round(100 * 1000 * 1000 * ramPrice) / 100} NCO / MB</>
            )}
            <br />

            {/* {acc.account.core_liquid_balance} */}
            <div>{percentOfNco}% of your NCO balance</div>
          </div>

          {/* {JSON.stringify(state.accountManagement.marketStatus)} */}
          <Form
            className="text-center"
            form={form}
            onFinish={
              // buyRam
              () => {}
            }
          >
            <Form.Item name="amount" label="">
              {/* <Input placeholder="type an amount to get a price estimate" onKeyUp={(e) => setAmount(Number(e.target.value))} suffix="bytes" /> */}
            </Form.Item>
            <Form.Item>
              {/* <Link to="/swap/nco/life"> */}
              <Button
                htmlType="submit"
                onClick={() => setTx("true")}
                disabled={false}
              >
                Buy Space
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Form>
          <div className="text-center text-modest">
            <Link to={"/Quota"}>Used space quota</Link>
          </div>
        </div>
      )}

      <NewcoinRecept visible={!!tx} tx={tx} onDone={reset}>
        <div>
          <p className="header-2 text-center u-margin-bottom-large u-margin-top-large">
            Newfoundation
          </p>
          <Row justify="space-between">
            <Col>RAM</Col>
            <Col>{amount} bytes</Col>
            <Col>{chargedAmount}</Col>
          </Row>
          <br />
          {divider()}
          <Row justify="space-between">
            <Col>
              <p className="u-margin-zero">TOTAL CHARGED:</p>
            </Col>
            <Col>{chargedAmount}</Col>
          </Row>
          {divider()}
          <p className="u-margin-top-medium text-center">
            Paid by <b>{state.api.auth.user.username}</b> via Newsafe
          </p>
          <p className="text-center">charge time: {chargeTime}</p>
          <div className="text-center" style={{ margin: "auto 15%" }}>
            <p className="u-margin-zero">Transaction Id:</p>
            <p className="u-margin-zero">{tx?.replace(/(.{4})/g, "$1 ")}</p>
          </div>
          <br />
          <div>
            {divider()}
            <p className="u-margin-zero text-center">
              THANK YOU FOR SHOPPING AT NEWSAFE
            </p>
            {divider()}
          </div>
        </div>
      </NewcoinRecept>
    </>
  );
};
