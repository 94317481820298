import { Link } from "react-router-dom";
import { Card, Row, Col } from "antd";

export const GettingStarted = () => {
	return (
		<div>
			<p className="header-1">Welcome to Newsafe!</p>
			<br />
			<br />
			<p className="header-2">Next steps</p>
			<br />
			<Row
				gutter={30}
				wrap={true}
				className="text-center card-square-container"
			>
				<Col>
					<Link to="/apps">
						<Card className="card-square">
							Check some of our apps
						</Card>
					</Link>
				</Col>
				<Col>
					<Link to="/pool">
						<Card className="card-square">
							<div>Create your pool</div>
						</Card>
					</Link>
				</Col>
			</Row>
			<br />
			<br />
			<p className="header-2">Understand the ecosystem</p>
			<br />
			<Row
				gutter={30}
				wrap={true}
				className="text-center card-square-container"
			>
				<Col>
					<a href="https://www.newcoin.org/" target="_blank">
						<Card className="card-square">
							Learn more about Newcoin
						</Card>
					</a>
				</Col>
				<Col>
					<a href="https://new.foundation/" target="_blank">
						<Card className="card-square">
							Learn more about Newfoundation
						</Card>
					</a>
				</Col>
			</Row>
		</div>
	);
};
