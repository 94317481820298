import { NLView } from "@newstackdev/iosdk/dist/types";
import { Discord } from "../../Icons/socials/Discord";
import { FacebookIcon } from "../../Icons/socials/FacebookIcon";
import { Instagram } from "../../Icons/socials/Instagram";
import { PinterestIcon } from "../../Icons/socials/PinterestIcon";
import { TumblrIcon } from "../../Icons/socials/TumblrIcon";
import { TwitterIcon } from "../../Icons/socials/TwitterIcon";
import { YoutubeIcon } from "../../Icons/socials/YouTubeIcon";
import { Website } from "../../Icons/socials/Website";
import { useAppState } from "../../overmind/overmind";
import { Eth } from "../../Icons/socials/Eth";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { Button } from "antd";

export const SocialLink: NLView<{
  platform: string;
  buttonView?: boolean;
  iconUsernameView?: boolean;
  user?: any;
}> = ({ platform, buttonView, iconUsernameView, user }) => {
  const state = useAppState();
  const [evmInfo, setEvmInfo] = useState("");

  const getAddressENS = async (address: string) => {
    const firstFourDigits = address.substring(0, 4);
    const lastFourDigits = address.substring(address.length - 4);

    const shortenedAddress = firstFourDigits + "..." + lastFourDigits;
    //@ts-ignore
    if (window.web3) {
      try {
        //@ts-ignore
        const provider = new ethers.JsonRpcProvider(
          //@ts-ignore
          "https://1rpc.io/eth"
        );

        const name = await provider?.lookupAddress(address);
        setEvmInfo(name ?? shortenedAddress);
      } catch (e) {
        console.log(e);
        setEvmInfo(shortenedAddress);
      }
    }
  };

  useEffect(() => {
    if (platform === "evm/0x1" || platform === "ethAddress") {
      getAddressENS(user[platform] ?? user["ethAddress"]);
    }
  }, user);

  const getSocialIcon = () => {
    switch (platform) {
      case "instagram":
        return <Instagram />;
      case "tumblr":
        return <TumblrIcon />;
      case "twitter":
        return <TwitterIcon />;
      case "youtube":
        return <YoutubeIcon />;
      case "pinterest":
        return <PinterestIcon />;
      case "facebook":
        return <FacebookIcon />;
      case "discord":
        return <Discord />;
      case "website":
        return <Website />;
      case "evm/0x1":
        return <Eth />;
      case "ethAddress":
        return <Eth />;
    }
  };

  const getSocialLink = (user?: any) => {
    if (user) {
      switch (platform) {
        case "tumblr":
          return `https://${user[platform]}.${platform}.com`;
        case "youtube":
          return `https://${platform}.com/c/${user[platform]}`;
        case "website":
          return user[platform];
        case "evm/0x1":
          return `https://etherscan.io/address/${user[platform]}`;
        case "ethAddress":
          return `https://etherscan.io/address/${user[platform]}`;
        default:
          return `https://${platform}.com/${user[platform]}`;
      }
    } else
      return `${state.config.settings.newgraph.baseUrl}/auth/provider/${
        platform === "twitter" || platform === "tumblr"
          ? `${platform}2`
          : platform
      }?token=${state.firebase.token}&redirect_url=${
        window.location.origin
      }/connect&inviteHash=${state.onboarding.form.inviteHash}`;
  };

  return buttonView ? (
    <Button
      type="primary"
      className="header-2 ns-socialButton"
      style={{
        fontWeight: 300,
        border: "1px solid rgb(0 0 0 / 10%)",
        boxShadow: "none",
      }}
      onClick={() => (window.location.href = getSocialLink(user))}
    >
      {platform.charAt(0).toUpperCase() + platform.slice(1)}
    </Button>
  ) : iconUsernameView ? (
    <a
      href={getSocialLink(user)}
      // target="_blank"
      rel="noreferrer"
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      target="_blank"
    >
      <p className="u-margin-right-small">{getSocialIcon()}</p>
      <p className="flex-column justify-center">
        {platform === "evm/0x1" || platform === "ethAddress"
          ? evmInfo
          : user[platform]?.replace(/https:\/\//g, "").replace(/www\./g, "")}
      </p>
    </a>
  ) : (
    <a
      href={getSocialLink()}
      // target="_blank"
      rel="noreferrer"
      target="_blank"
    >
      <span className="ns-socialIcon">{getSocialIcon()}</span>
    </a>
  );
};
