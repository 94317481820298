import { IOView } from "@newstackdev/iosdk/dist/types";
import { Menu, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { HomeIcon } from "../assets/Icons/HomeIcon";
import { Profile } from "../assets/Icons/Profile";
import { Explore } from "../assets/Icons/Explore";
import { Networks } from "../assets/Icons/Networks";
import { Info } from "../assets/Icons/Info";
import { useAppState, useActions } from "../overmind/overmind";
import { BellOutlined } from "@ant-design/icons";

export const MainMenu: IOView<{
  onClose?: () => void;
  admitted?: boolean;
  isMobile?: boolean;
}> = ({ onClose, admitted, isMobile }) => {
  const squishMainMenu = useMediaQuery({ maxHeight: 750 });
  const state = useAppState();
  const actions = useActions();
  const signupInProgress = state.routing.location.match(/^\/signup/);

  return (
    <Menu
      theme="light"
      style={
        isMobile
          ? squishMainMenu
            ? {
                fontSize: "2vh",
                justifyContent: "center",
                paddingBottom: "57px",
                alignItems: "center",
              }
            : {
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "57px",
              }
          : {}
      }
    >
      <Menu.Item style={{ margin: "0 0 5px 0" }}>
        <Row>
          <Link
            to={admitted ? "/console" : "/"}
            onClick={onClose}
            className="ns-antd-menu-item"
            style={{ marginRight: "24px" }}
          >
            <span style={{ padding: "5px" }}>
              <HomeIcon />
            </span>
            Home
          </Link>
        </Row>
      </Menu.Item>
      <Menu.Item style={{ margin: "0 0 5px 0" }}>
        <Row>
          <Col>
            <Link
              to={"/people"} // Edit connections page to support unregistered users
              onClick={onClose}
              className="ns-antd-menu-item"
              style={{ marginRight: "21px" }}
            >
              <span style={{ padding: "5px" }}>
                <Profile />
              </span>
              People
            </Link>
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item style={{ margin: "0 0 5px 0" }}>
        <Row>
          <Col>
            <Link
              to="/networks" // Edit networks page to support unregistered users
              onClick={onClose}
              className="ns-antd-menu-item"
              style={{ marginRight: "21px" }}
            >
              <span style={{ padding: "5px" }}>
                <Networks />
              </span>
              Networks
            </Link>
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item style={{ margin: "0 0 5px 0" }}>
        <Row>
          <Col>
            <Link
              to="/helpline"
              onClick={onClose}
              className="ns-antd-menu-item"
              style={{ marginRight: "25px" }}
            >
              <span style={{ padding: "5px" }}>
                <Info />
              </span>
              Helpline
              {/* {localStorage.getItem('__oneSignalEnalbed') && !state?.oneSignal?.sid ? "x" : "y"} */}
            </Link>
          </Col>
        </Row>
      </Menu.Item>
      {isMobile && (
        <Menu.Item style={{ margin: "0 0 5px 0" }}>
          <Row>
            <Col>
              {!(
                state.api.auth.user.id ||
                signupInProgress ||
                state.auth.authenticated
              ) ? (
                <Link
                  className="ns-antd-menu-item"
                  to="/SignInCrosswalk"
                  onClick={onClose}
                >
                  Sign in
                </Link>
              ) : (
                <Col>
                  <Link to="/signout">
                    {signupInProgress ? "Return to start" : "Sign Out"}
                  </Link>
                </Col>
              )}
            </Col>
          </Row>
        </Menu.Item>
      )}
      <Menu.Item>
        <Row gutter={16}>
          <Col>
            <Link
              to={location.pathname}
              className="ns-antd-menu-item"
              onClick={() => actions.oneSignal.subscribeToOneSignalWeb()}
            >
              <BellOutlined style={{ color: "#404040" }} />
              Notifications
            </Link>
          </Col>
        </Row>
      </Menu.Item>
      {/* <Menu.Item
        style={{
          display: "flex",
          flex: 1,
          alignItems: "end",
        }}
        className="text-modest"
      >
        <Link to="/signout" onClick={() => onClose}>
          Sign out
        </Link>
      </Menu.Item> */}
    </Menu>
  );
};
