export const Profile: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.06" y="0.06" width="29.88" height="29.88" stroke-width="0.12" />
    <circle
      cx="14.9263"
      cy="15.3141"
      r="10.8736"
      stroke="black"
      stroke-width="1.2"
    />
    <circle
      cx="14.9262"
      cy="13.5494"
      r="3.81291"
      stroke="black"
      stroke-width="1.2"
    />
    <path
      d="M6.10022 21.6796C7.32365 19.3208 10.9745 17.6091 15.2881 17.6091C19.3083 17.6091 22.7528 19.0959 24.1931 21.2077"
      stroke="black"
      stroke-width="1.2"
    />
  </svg>
);
