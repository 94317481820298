import { useAppState } from "../overmind/overmind";
import { Resource } from "../Components/Resource";
import { Link } from "react-router-dom";

export const Quotas = () => {
	const state = useAppState();
	const acc = state.accountManagement.auth.account;

	return (
		<>
			<Resource
				units="MB"
				resource="RAM"
				max={acc?.account.ram_quota / (1000 * 1000)}
				usage={acc?.account.ram_usage / (1000 * 1000)}
			/>
			<div className="text-center vertical-space"></div>
			<p className="text-center">
				<Link to={"/Space"}>Buy more RAM Space</Link>
			</p>
		</>
	);
};
