export const CloseIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M2 2.00024L55.97 55.9702" stroke="black" stroke-width="3.37" />
    <path d="M2 55.97L55.97 1.99997" stroke="black" stroke-width="3.37" />
  </svg>
);
