import { Action } from "src/iosdk/overmind/overmind";
import * as effects from "./effects";
import {
  SUBSCRIBE_ON_RELOAD_KEY,
  getOneSignal,
  getOneSignalInstance,
} from "./effects";

export const IN_PROGRESS = Symbol("onesignal-inprogress");
export const NOT_INITIALIZED = Symbol("onesignal-inprogress");

let addAliasOnSubscriptionListener: any = null;

const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
    return true;
  }
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    return true;
  }
  return false;
};

export const updateSignal: Action<string | undefined> = async (
  { state, effects, actions },
  oneSignalSessionId
) => {
  const os = effects.oneSignal.getOneSignalInstance();

  const eid = (await os?.coreDirector?.getIdentityModel())?.data;

  const sid = os.User.PushSubscription.optedIn && os.User.PushSubscription.id;

  state.oneSignal.sid = sid;

  state.oneSignal.oneSignalSubscription = sid
    ? JSON.stringify(sid) + " -> " + JSON.stringify(eid)
    : "";

  console.log("OneSignal User ID:", sid);
};

export const getOnesignalAuthHash: Action = async ({
  state,
  effects,
  actions,
}) => {
  //@ts-ignore
  const res = await state.api.client.auth.onesignalList({
    query: "newsafe",
  } as any);
  const authHash = await res.json();
  state.oneSignal.externalIdAuthHash = authHash?.externalIdAuthHash;
};

const subscriptionChangeListener = (ev: any, updateSignal) => {
  console.log(ev);
  updateSignal();
};

let osn = localStorage.getItem(SUBSCRIBE_ON_RELOAD_KEY) || "";

localStorage.removeItem(SUBSCRIBE_ON_RELOAD_KEY);

let readySubscribeTimer;

const ifreadySubscribe: Action = ({ state, actions }) => {
  // we should do this only when both os is loaded and the current user is known
  const os = getOneSignalInstance();

  const sid = os?.User?.PushSubscription?.id;

  // if(sid != osn)
  //   return console.warn("osn is not equal to current subscription id", osn, sid);
  if (!state.api.auth.user.id) return console.log("User id not ready");

  if (!osn)
    // || !state.oneSignal.calledLogin)
    return console.log("Not immediate subscribe reload");

  // const os = effects.oneSignal.getOneSignal();
  if (!sid)
    //!os?.User?.PushSubscription?.id)
    return console.log("Subscription id not ready");

  if (readySubscribeTimer) clearInterval(readySubscribeTimer);

  console.log("Subscription id ready, subscribing using osn", osn);

  os.push(() => actions.oneSignal.subscribeToOneSignalWeb()); //.addAlias("on reload after login"));
};

export const onInitializeOvermind: Action = ({
  state,
  actions,
  effects,
  reaction,
}) => {
  if (osn)
    readySubscribeTimer = setInterval(
      () => actions.oneSignal.ifreadySubscribe(),
      2000
    );

  effects.oneSignal.initOneSignal();

  getOneSignal().push(async () => {
    const os = getOneSignalInstance();

    const removeAddAliasListener = () =>
      os?.User?.PushSubscription?.removeEventListener(
        "change",
        addAliasOnSubscriptionListener
      );
    removeAddAliasListener();

    addAliasOnSubscriptionListener =
      addAliasOnSubscriptionListener ||
      (async (e: any) => {
        console.log("oshelper: addAliasOnSubscriptionListener got ", e);
        if (os.User.PushSubscription.id) {
          const a = await actions.oneSignal.ifreadySubscribe();
          console.log("oshelper: alias result:", a);

          removeAddAliasListener();
        } else {
          console.warn("oshelper: No os.User.PushSubscription.id to alias");
        }
        // }
      });

    os?.User?.PushSubscription?.addEventListener(
      "change",
      addAliasOnSubscriptionListener
    );
  });

  effects.oneSignal.addOsSubscriptionListener((e) => {
    console.log("oshelper: top level emitter:", e);
    if (!e?.current?.id) {
      state.oneSignal.oneSignalSubscription = NOT_INITIALIZED;
    } else {
      actions.oneSignal.updateSignal(e.current.id);
      actions.oneSignal.ifreadySubscribe();
    }
  });

  if (osn)
    reaction(
      (st) => st.api.auth?.user?.id,
      (id) => id && actions.oneSignal.ifreadySubscribe()
    );

  effects.oneSignal.addSubsciptionEventListeners((et) => (e: any) => {
    console.log("oshelper: lifecycle:", e, et);
    if (!e?.current?.id) {
      state.oneSignal.oneSignalSubscription = NOT_INITIALIZED;
    } else {
      actions.oneSignal.updateSignal(e.current.id);
      actions.oneSignal.ifreadySubscribe();
    }
    actions.oneSignal.updateSignal();
  });
};

const addAlias: Action<string> = async ({ state, actions, effects }, where) => {
  const os = getOneSignalInstance();

  const userId = state.api.auth.user.id || "";

  if (addedAlias) return console.log("oshelper: Alias already added");

  if (!osn && !state.oneSignal.calledLogin) return;

  const sid = os?.User?.PushSubscription?.id;

  if (!sid) {
    if (osn)
      return console.log(
        "subscription not ready but won't reload to avoid loop, osn=",
        osn
      );

    console.log(
      "oshelper: Wanted to add alias in ",
      where,
      "but no subscriptionId is available"
    );

    localStorage.setItem(SUBSCRIBE_ON_RELOAD_KEY, userId);
    osn = userId;

    // if(osn)
    // readySubscribeTimer = setInterval(() => actions.oneSignal.ifreadySubscribe(), 1000)

    // actions.oneSignal.ifreadySubscribe();

    setTimeout(() => {
      window.location.reload();
    }, 2000);

    return;
  }

  const im = await os.coreDirector.getIdentityModel().awaitOneSignalIdAvailable;
  const imm = os.coreDirector.getIdentityModel();

  console.log(im, imm);

  if (addedAlias) return console.log("oshelper: Alias already added");

  debugger;
  addedAlias = true;
  // await new Promise((res) => setTimeout(res, 2000));
  await os.User.addAlias(userId, sid); //sid);

  actions.ux.showNotification({
    message:
      "Thank you for subscribing. You will receive newsafe notifications on this device.",
  });

  osn = ""; // prevent further attempts

  console.log("oshelper: Added alias in ", where, { userId, sid });

  actions.oneSignal.updateSignal();
};

let addedAlias = false;
export const subscribeToOneSignalWeb: Action<
  | {
      userId: string;
    }
  | undefined
> = async ({ state, effects, actions }, params) => {
  const userId = params?.userId || state.api.auth.user.id || "";
  state.oneSignal.oneSignalSubscriptionTouched = true;

  let os = effects.oneSignal.getOneSignalInstance();

  if (!os) {
    effects.oneSignal.initOneSignal();
    os = effects.oneSignal.getOneSignalInstance();
  }

  state.oneSignal.oneSignalSubscription = IN_PROGRESS;

  const permission = Notification
    ? await Notification?.requestPermission()
    : null;

  const sw = effects.oneSignal.getServiceWorkerStatus();
  if (!sw) {
    console.log("oshelper: no sw, trying init again");
    // initOneSignal();
    throw new Error("No service worker");
  }

  console.log("Subscription permission: ", permission);

  if (permission != "granted") return actions.oneSignal.updateSignal();
  //@ts-ignore
  const _externalIdAuthHash = await state.api.client.auth.onesignalList({
    query: { app: "newsafe" },
  } as any);
  const { externalIdAuthHash } = await _externalIdAuthHash.json();

  const addAlias = (where) => () => actions.oneSignal.addAlias(where);

  os.push(function (os: any) {
    if (!os?.User?.PushSubscription) alert("no User.PubhSubscription");

    const optedIn = os?.User?.PushSubscription?.optedIn;

    os?.User?.PushSubscription?.removeEventListener(
      "change",
      subscriptionChangeListener
    );
    os?.User?.PushSubscription?.addEventListener(
      "change",
      subscriptionChangeListener
    );
    os?.User?.PushSubscription?.addEventListener("error", (err: any) => {
      console.log(err);
    });

    (window as any).__LOGGING__ = true;

    const optIn = async () => {
      console.log("oshelper: Opting in, current opt in status:", optedIn);
      // if (optedIn)
      //     return;
      !optedIn
        ? await os?.User?.PushSubscription?.optIn()
        : Promise.resolve(null);
      console.log("oshelper: Done opting in, opted in status", optedIn);
    };
    const login = async () => {
      console.log("oshelper Logging in...");
      // if (osn)
      //   return console.log("Logged in, skipping to add alias");

      if (!os.User.PushSubscription.id) {
        actions.oneSignal.updateSignal();
        console.log(
          "oshelper: No subscriptionId in login, reloading with opt-in"
        );

        localStorage.setItem(SUBSCRIBE_ON_RELOAD_KEY, userId);

        setTimeout(() => {
          window.location.reload();
        }, 2000);

        return;

        // console.log("oshelper: No subscriptionId in login but still logging in!");
        // return
      }

      state.oneSignal.externalIdAuthHash = externalIdAuthHash;

      // await os.User.addAlias(userId); //sid);
      const r = await os.login(userId, externalIdAuthHash);

      // const im = await os.coreDirector.getIdentityModel().awaitOneSignalIdAvailable;
      // console.log("Identity model in login:", im);

      console.log("oshelper Login returned with ", r);
      state.oneSignal.calledLogin = true;

      actions.oneSignal.updateSignal();

      // addAlias("in queue")
      os.push(addAlias("right after login"));
      // ();

      return r;
    };

    const initIfNotYet = () => {
      console.log("opted in");
      os.User.PushSubscription.id ? effects.oneSignal.initOneSignal() : null;
    };

    const queue: (() => void | any | Promise<any>)[] = [
      optIn,
      initIfNotYet,
      login,
      addAlias("in queue"),
      () => actions.oneSignal.updateSignal(),
    ];

    queue.forEach(os.push);
  });

  // if (immediate) {
  //     addAlias("immediate")
  // }
};

export const unSubscribeFromOneSignalWeb: Action = async ({
  state,
  actions,
}) => {
  state.oneSignal.oneSignalSubscriptionTouched = true;

  const os = getOneSignalInstance();

  await os.User?.PushSubscription?.optOut();
  await os.logout();

  actions.oneSignal.updateSignal();
};

export const resetSubscriptionTouched: Action = async ({ state, actions }) => {
  state.oneSignal.oneSignalSubscriptionTouched = false;
};

export default {
  actions: {
    onInitializeOvermind,
    addAlias,
    updateSignal,
    subscribeToOneSignalWeb,
    unSubscribeFromOneSignalWeb,
    ifreadySubscribe,
  },
  effects,
  state: {
    externalIdAuthHash: "",
    sid: "" as string | undefined,
    calledLogin: false,
    oneSignalSubscription: NOT_INITIALIZED as Symbol | string,
    oneSignalSubscriptionTouched: false,
  },
};
