import { Row, Form, Input, Button } from "antd";
import { useActions, useAppState } from "../../overmind/overmind";
import { useEffect } from "react";
import LogoOnlyIcon from "../../assets/Icons/LogoOnlyIcon";
import { AUTH_FLOW_STATUS } from "@newstackdev/iosdk/dist/overmind/auth/state";
import { UnsuccessfulLoginModal } from "./UnsuccessfulLoginModal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const SignIn: React.FC<{ signupMode?: boolean }> = ({ signupMode }) => {
  const actions = useActions();
  const state = useAppState();
  const [phoneForm] = Form.useForm();
  const [codeForm] = Form.useForm();
  // const [mode, setMode] = useState("phone");
  const reg = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$";
  const phoneNumArr = state.onboarding.form.phone?.split("");
  const maskedPhoneNum = phoneNumArr
    ?.map((char, i) => {
      if (i < phoneNumArr.length - 3 && i > 3) {
        return "X";
      }
      return char;
    })
    .join("");

  useEffect(() => {
    codeForm && codeForm.resetFields();
    if (
      state.auth.status == AUTH_FLOW_STATUS.AUTHORIZING ||
      state.auth.status != AUTH_FLOW_STATUS.RECEIVED
    )
      phoneForm && phoneForm.resetFields();
  }, [state.auth.status]);

  const mode = state.auth.status;

  return (
    <>
      <div id="sign-in-button" />
      {state.auth.authenticated ? (
        <UnsuccessfulLoginModal redirect={() => ({ location: "/signup" })} />
      ) : (
        <></>
      )}
      {/* <Tabs defaultActiveKey={"1"} type="card">
        <Tabs.TabPane tab="Web" key="1" className=""> */}
      <div className="u-margin-top-mega">
        <Row
          style={{
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 50,
          }}
        >
          <LogoOnlyIcon spin={true} width="180px" height="180px" />
        </Row>
        <Form
          form={phoneForm}
          hidden={mode !== AUTH_FLOW_STATUS.ANONYMOUS}
          onFinish={async (data) => {
            if (document.getElementById("recaptcha-container")?.outerHTML)
              document.getElementById("recaptcha-container")!.outerHTML =
                '<div id="recaptcha-container"></div>';
            await actions.firebase.requestToken({
              phone: data.phone,
            });
          }}
        >
          <div className="header-1 text-center u-margin-bottom-large">
            Enter phone number:
          </div>
          <Form.Item name="phone">
            <PhoneInput type="text" onChange={() => {}} className="ant-input" />
          </Form.Item>
          <Form.Item className="text-center">
            <Button htmlType="submit" type="primary" id="sign-in-button">
              Send Verification
            </Button>
          </Form.Item>
        </Form>
        <Form
          form={codeForm}
          hidden={mode !== AUTH_FLOW_STATUS.RECEIVED}
          onFinish={async (data) => {
            try {
              await actions.firebase.verifyPhone(data);
              actions.routing.historyPush({ location: "/connect" });
            } catch {
              actions.routing.historyPush({ location: "/" });
            }
          }}
        >
          <div className="header-1 text-center u-margin-bottom-large">
            Enter verification code:
          </div>
          <Form.Item name="phoneVerificationCode">
            <Input type="text" className="u-margin-bottom-medium" />
          </Form.Item>
          <Form.Item className="text-center">
            <Button htmlType="submit" type="primary" id="sign-in-button">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
