import { Tooltip, Col, Row, Button } from "antd";
import { badgeIcons } from "@newstackdev/iosdk/dist/Components/BadgeWidget";
import { useBadges } from "@newstackdev/iosdk/dist/hooks/useBadges";
import { useAppState } from "../../overmind/overmind";
import RotatableImage from "./RotatableImage";

export const toMonthName = (monthNumber: number) => {
	const date = new Date();
	date.setMonth(monthNumber - 1);

	return date.toLocaleString("en-US", {
		month: "long",
	});
};

type Props = {
	maxItems?: number;
	setActiveKey?: React.Dispatch<React.SetStateAction<string>>;
};

export const Minerals = ({ maxItems, setActiveKey }: Props) => {
	const state = useAppState();
	const badgesData = useBadges(state.api.auth?.user?.id || ""); //"c2c0a757-d3bf-eafd-3d2b-7d6379057933"); //);

	const badges = maxItems
		? badgesData.badges?.slice(0, maxItems)
		: badgesData.badges;

	const totalCount = badgesData.badges?.length;

	const mockUpImages = [
		"https://upload.wikimedia.org/wikipedia/commons/5/57/National_Park_Service_Thawing_permafrost_%2827759123542%29.jpg",
		"https://upload.wikimedia.org/wikipedia/commons/0/02/094-antartica-2017_39_%2833606274085%29.jpg",
	];

	return (
		<div className="u-margin-top-mega">
			<Row
				align="middle"
				justify="space-between"
				className="u-margin-bottom-medium"
			>
				<Col>
					<Row align="middle">
						<p className="header-1 text-left u-margin-right-large">
							Experience Minerals
						</p>
						{maxItems && (
							<Button onClick={() => setActiveKey!("3")}>
								See All Minerals
							</Button>
						)}
					</Row>
				</Col>
				{totalCount && (
					<Col>
						<Button>
							<p className="header-2">{totalCount}</p>
						</Button>
					</Col>
				)}
			</Row>
			<Row wrap className="ns-badges-wrapper" gutter={[0, 60]}>
				{badges?.map((badge, index) => {
					const dt = new Date(badge.created || "");

					const count =
						badge.type == "number" ? badge.value + "x" + " " : "";

					const date = `${toMonthName(
						dt.getMonth() + 1
					)} ${dt.getDate()}, ${dt.getFullYear()}`;

					return (
						<Row className="ns-badge">
							<Col className="u-margin-bottom-medium">
								<Tooltip
									trigger="hover"
									title={badge?.title}
									key={badge?.id}
								>
									{/* TODO Delete Mock up*/}
									{/* <RotatableImage
										src={`/badges/${
											badgeIcons[badge.name]
										}.png`}
									/> */}
									<RotatableImage src={mockUpImages[index]} />
								</Tooltip>
							</Col>
							<Row
								gutter={5}
								className="typography-overflow"
								justify="center"
							>
								<Col>
									<p className="paragraph-1b">{count}</p>
								</Col>
								<Col>
									<p className="paragraph-1b">
										{badge.title}
									</p>
								</Col>
							</Row>
							<Col>
								<p>{date}</p>
							</Col>
						</Row>
					);
				})}
			</Row>
		</div>
	);
};
