import {
  Web3OnboardProvider,
  init,
  useConnectWallet,
} from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
// import { ethers } from "ethers";
const ethers = require("ethers");
import { useEffect, useState, useCallback } from "react";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { Select, Button, Modal } from "antd";
import LogoIcon from "../iosdk/Icons/LogoIcon";

const INFURA_KEY = "";
const ethereumRopsten = {
  id: "0x3",
  token: "rETH",
  label: "Ethereum Ropsten",
  rpcUrl: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
};
const chains = [ethereumRopsten];
const wallets = [injectedModule()];
const web3Onboard = init({
  wallets,
  chains,
  appMetadata: {
    name: "Newsafe Crypto Id",
    icon: ` <svg width="44" height="44" version="1.1" viewBox="0 0 11.642 11.642" xmlns="http://www.w3.org/2000/svg">
        <path d="m5.4381 1.2472v9.1473h0.74933v-9.1473zm2.2935 1.1172h-3.8215v0.74932h3.8215zm-3.8215 0c-1.9879 0-3.6455 1.5074-3.6455 3.4217h0.74932c0-1.4513 1.2712-2.6724 2.8962-2.6724zm-3.6455 3.4217c0 1.9143 1.6576 3.4216 3.6455 3.4216v-0.74933c-1.625 0-2.8962-1.221-2.8962-2.6723zm7.467 3.4216c1.9879 0 3.6455-1.5074 3.6455-3.4216h-0.74933c0 1.4513-1.2712 2.6723-2.8962 2.6723zm3.6455-3.4216c0-1.9143-1.6576-3.4217-3.6455-3.4217v0.74932c1.625 0 2.8962 1.221 2.8962 2.6724zm-7.467 3.4216h3.8215v-0.74933h-3.8215z" stroke-width=".031248" />
    </svg>`,
    description: "Verify your identity",
  },
});

const ethereum = (window as any).ethereum!;
// export const EVMAuth = ({ Component, pageProps }) => {
//     return (
//         hello
//       {/* <Component {...pageProps} /> */ }
//   )
// }
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { useAppState, useActions } from "../overmind/overmind";

export const ConnectWallet: IOView = ({}) => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [ethersProvider, setProvider] =
    useState<any | null>();
  const [verifying, setVerifying] = useState<boolean>(false);

  const actions = useActions();
  const state = useAppState();

  const user = state.api.auth.user;

  const [selectedAddress, setSelectedAddress] = useState("");
  // const [selectedNetwork, setSelectedAddress] = useState("");

  const chain = `${wallet?.chains[0]?.namespace}/${wallet?.chains[0]?.id}`;

  const sign = useCallback(() => {
    if (!wallet) return;

    setVerifying(true);

    ethereum
      .enable()
      .then(async () => {
        const provider = new ethers.providers.Web3Provider(ethereum);

        // const signer = provider.getSigner(userAddress);

        // const chain = `${wallet.chains[0].namespace}/${wallet.chains[0].id}`;
        const signer = provider.getSigner(selectedAddress);

        const payload = {
          id: user?.id,
          username: user?.username,
          address: selectedAddress,
          chain: chain,
          wallet: wallet.label,
        };
        const signature = await signer.signMessage(JSON.stringify(payload));
        const message = {
          payload,
          signature,
        };
        console.log("Message signature:", message);

        actions.evm2.verifyAddress({ message });
      })
      .catch((error) => {
        console.error(error);
        // Handle the user's rejection or any errors.
      })
      .finally(() => setVerifying(false));
  }, [wallet, selectedAddress]);

  useEffect(() => {
    // If the wallet has a provider than the wallet is connected
    if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, "any"));

      if (wallet.accounts.length === 1)
        setSelectedAddress(wallet.accounts[0].address);

      // if using ethers v6 this is:
      // ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')
    }
  }, [wallet, setSelectedAddress]);

  useEffect(() => {
    sign();
  }, [selectedAddress]);

  const action = wallet?.provider && wallet.accounts.length ? sign : connect;
  const actionEnabled =
    !verifying &&
    ((!connecting && !wallet?.provider) ||
      (wallet?.provider && selectedAddress));
  const noAddress = wallet?.provider && !wallet.accounts.length;

  return (
    <>
      {wallet?.provider && (wallet?.accounts?.length || 0) >= 1 ? (
        !selectedAddress ? (
          <></>
        ) : (
          <></>
        )
      ) : (
        <>{wallet?.provider ? "No accounts" : <></>}</>
      )}
      <>
        <Button
          style={{
            borderRadius: 47,
            width: "66px",
            height: "28px",
            fontSize: "10px",
            padding: 0,
          }}
          disabled={!actionEnabled}
          onClick={() => {
            action();
          }}
        >
          VERIFY
        </Button>
      </>

      {!!(wallet?.provider && !selectedAddress) ? (
        <>
          <h3>Select address</h3>
          <Select
            style={{ width: 150 }}
            onChange={(val) => {
              setSelectedAddress(val);
            }}
          >
            {wallet?.accounts.map((a: { address: string }) => (
              <Select.Option value={a.address}>{a.address}</Select.Option>
            ))}
          </Select>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const EVMContext: IOView = ({ children }) => (
  <Web3OnboardProvider web3Onboard={web3Onboard}>
    {children}
  </Web3OnboardProvider>
);

export const EVMAuth = ConnectWallet;

// function useEffect(arg0: () => void, arg1: any[]) {
//     throw new Error('Function not implemented.')
// }

// function useState<T>(): [any, any] {
//     throw new Error('Function not implemented.')
// }
// const INFURA_KEY = ''
// const ethereumRopsten = {
//   id: '0x3',
//   token: 'rETH',
//   label: 'Ethereum Ropsten',
//   rpcUrl: `https://ropsten.infura.io/v3/${INFURA_KEY}`
// }
// const chains = [ethereumRopsten]
// const wallets = [injectedModule()]
// const web3Onboard = init({
//   wallets,
//   chains,
//   appMetadata: {
//     name: 'Web3-Onboard Demo',
//     icon: '<svg>App Icon</svg>',
//     description: 'A demo of Web3-Onboard.'
//   }
// })
// export const EVMAuth = ({ Component, pageProps }) => {
//   return (
//     <Web3OnboardProvider web3Onboard={web3Onboard}>
//       hello
//       {/* <Component {...pageProps} /> */}
//     </Web3OnboardProvider>
//   )
// }
