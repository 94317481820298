import { IOView } from "@newstackdev/iosdk/dist/types";
import { useEffect } from "react";
import { useActions, useAppState } from "../../overmind/overmind";

export const SignOut: IOView = () => {
  const actions = useActions();
  const state = useAppState();

  useEffect(() => {
    // if(state.routing.location != "/signout")
    //   return;

    setTimeout(async () => {
      // const u = ;

      await actions.app.signOut();
      window.localStorage.removeItem("consoleStatus");

      // if(state.api.auth.user.status == "preregisterd")
      await actions.firebase.logout();

      // window.location.pathname = "/";
    }, 1000);
  }, []);

  return <p className="header-1 text-center">See you later!</p>;
};
