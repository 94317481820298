export const SwapIcon = () => (
	<svg
		width="80"
		height="80"
		viewBox="0 0 153 177"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M42.719 173.771L6.02788 134.815L42.4925 96.5382"
			stroke="black"
			stroke-width="7.44"
			stroke-miterlimit="10"
		/>
		<path
			d="M8.97229 134.815L112.574 134.815C112.574 134.815 147 136.657 147 94.6559"
			stroke="black"
			stroke-width="7.44"
			stroke-miterlimit="10"
		/>
		<path
			d="M110.309 3.229L147 42.185L110.535 80.4615"
			stroke="black"
			stroke-width="7.44"
			stroke-miterlimit="10"
		/>
		<path
			d="M144.056 42.1848L40.4542 42.1848C40.4542 42.1848 6.02795 40.3424 6.02795 82.3438"
			stroke="black"
			stroke-width="7.44"
			stroke-miterlimit="10"
		/>
	</svg>
);
