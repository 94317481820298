import { Col, Row } from "antd";
import { ContentLayout } from "@newstackdev/iosdk/dist/Components/ContentLayout";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { useActions } from "../../../overmind/overmind";
import Button from "antd/lib/button";

export const OnboardingTypeSelector: IOView = () => {
  const actions = useActions();

  return (
    <ContentLayout customClass="app-content-layout">
      <Row gutter={[20, 20]} justify="center">
        <Col>
          <Button type="primary" onClick={actions.onboarding.startMetamaskFlow}>
            <p className="paragraph-1">
              I have a username purchased on OpenSea
            </p>
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              actions.routing.historyPush({
                location: "/",
              });
              actions.onboarding.stopMetamaskFlow();
            }}
          >
            <p className="paragraph-1">I want to create a new username</p>
          </Button>
        </Col>
      </Row>
    </ContentLayout>
  );
};
