import { newcounter } from "./newcounter";
import accountManagement from "./accountManagement";
import websockets from "./websockets";
import firebase from "@newstackdev/iosdk/dist/overmind/firebase";
import { newgraphAuth } from "./newgraphAuth";
import onboarding from "./onboarding";
import { Action } from "../iosdk/overmind/overmind";
import user from "./user";
import evm from "./accountManagement/evm";
import oneSignal from "./oneSignal";

const signIn: Action<{ jwt: string }> = async (
  { state, effects, actions },
  { jwt }
) => {
  await actions.newsafe.authorize({ jwt });
  // any post-auth initialization
};

const signOut: Action<
  { noRouting?: boolean; keepFbUser: boolean } | undefined
> = async ({ state, effects, actions }, props) => {
  const { noRouting, keepFbUser } = props || {};

  await actions.onboarding.stopLegacyImport({ noRedirect: true }); // take a walk on the safe side but this should be reviewed
  await actions.auth.logout({ noRouting: true, keepFbUser: true });
  await actions.api.auth.logout({ keepFbUser });

  !keepFbUser && (await actions.firebase.logout());

  await actions.newsafe.signOut();

  state.api.auth.user = {};

  window.localStorage.removeItem("emailForSignIn");
  window.localStorage.removeItem("legacyAuthToken");
  window.localStorage.removeItem("newsafe-auth-redirect-request");
  state.onboarding.form = {};
  state.onboarding.legacyToken = "";
  state.onboarding.isLegacyUpdateOngoing = false;
  state.onboarding.inviteHashVerified = false;
  state.onboarding.metamaskFlow = false;
  state.onboarding.legacyUsername = "";

  // if (!noRouting) actions.routing.historyPush({ location: "/" }); //
  // window.location.pathname = "/"
};

const onInitializeOvermind: Action = ({
  actions,
  state,
  effects,
  reaction,
}) => {
  // state.app.api = effects.app.api.initialize(<your init url>);

  // const newsafeJwtFromUrl = new URLSearchParams(window.location.search).get(
  //   "token"
  // );
  // const newsafeJwtFromLocalStore = newsafeJwtFromUrl
  //   ? `${newsafeJwtFromUrl}`
  //   : window.localStorage.getItem(`newsafe-auth-token`)?.toString() || "";
  // const newsafeJwt = newsafeJwtFromUrl || newsafeJwtFromLocalStore;
  // if (newsafeJwt) {
  //   actions.app.signIn({
  //     jwt: isEmpty(state.newsafe.token)
  //       ? `${newsafeJwt}`
  //       : state.newsafe.token,
  //   });
  // }

  // reaction(
  //   (state) => state.newsafe.token,
  //   async () => {
  //     await actions.app.signIn({ jwt: state.newsafe.token || newsafeJwt });
  //     if (/^\/?$/.test(state.routing.location))
  //       actions.routing.historyPush({
  //         location: state.config.settings.routing.defaults.onAuth,
  //       });
  //   }
  // );

  reaction(
    () => {},
    async () => {
      await actions.websockets.toggleWebSocket();
    }
  );

  // reaction(
  //   (state) => state.api.auth.user,
  //   async () => {
  //     debugger;
  //   }
  // );
};

export const core = {
  actions: {
    onInitializeOvermind,
    signIn,
    signOut,
  },
};

import { history } from "@newstackdev/iosdk-newcoin-stats";

export const app = {
  app: core,
  firebase,
  newgraphAuth,
  accountManagement,
  websockets,
  onboarding,
  newcounter,
  history,
  user,
  evm2: evm,
  oneSignal,
};
