import { useAppState } from "../../overmind/overmind";
import { CRYPTO_NETWORKS } from "./constants";
import CheckMark from "../../assets/Icons/Checkmark.svg";
import { ConnectWallet } from "../../Components/Evm";
import { ContentCard } from "../../Components/ContentCard";
import ETH from "../../assets/Icons/ETH.svg";

export const ProfileCryptoNetworkVerify: React.FC<{}> = () => {
  const state = useAppState();

  const isNetworkVerified = (networkCode: string) => {
    const sids = state.api.auth.user.verifiedSocialIds;
    const t = sids?.includes(networkCode);
    return t;
  };

  const verify = (provider: string): React.ReactNode => {
    return !isNetworkVerified(provider) ? (
      <>
        {/* {provider}{isNetworkVerified(provider) ? 1 : 0}{JSON.stringify(state.api.auth?.user?.verifiedSocialIds)} */}
        <ConnectWallet />
      </>
    ) : (
      <img src={CheckMark} alt={CheckMark} />
    );
  };

  const getProfileSocialMediaVerify = () => {
    return Object.keys(CRYPTO_NETWORKS).map((network: string) => {
      return (
        <ContentCard
          title={network}
          buttonTitle="VERIFY"
          description="Connect your Crypto Wallet"
          logoUrl={ETH}
          customButton={verify(CRYPTO_NETWORKS[network])}
        />
      );
    });
  };

  return <>{getProfileSocialMediaVerify()}</>;
};
