import { useActions, useAppState } from "../../overmind/overmind";
import { Button } from "antd";
import { useCachedPool } from "@newstackdev/iosdk/dist/hooks/useCached";
import { CrossCircleErr } from "../Auth/Onboarding/UserCreate";
import { Input } from "antd";
import { useState } from "react";
import { NCO_BlockchainAPI } from "@newfound8ion/newcoin-sdk";
import { ProgressButton } from "@newstackdev/iosdk/dist/Components/ProgressButton";

export const newcoin = new NCO_BlockchainAPI({
  urls: NCO_BlockchainAPI.defaults.devnet_urls,
  services: NCO_BlockchainAPI.defaults.devnet_services,
  is_proxy: false,
  debug: true,
});

export const ViewPool = () => {
  const state = useAppState();

  return state.api.auth.user.newcoinTicker ? <ViewPool /> : <CreatePool />;
};

export const CreatePool = () => {
  const state = useAppState();
  const actions = useActions();

  const [isValid, setIsValid] = useState();
  const [error, setError] = useState("");
  const [wantedTicker, setWantedTicker] = useState("");

  const existingTicker = useCachedPool({
    code: wantedTicker,
  });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.toUpperCase();
    const isValid = /^[A-Z]{3,7}$/.test(value);

    setError(isValid ? "" : "3 - 7 characters, latin alphabet only");

    if (!isValid) return;

    setWantedTicker(value);
    console.log("Will create your ticker");
  };

  const done = async () => {
    try {
      // await //newcoin.createPool({ owner: state.api.auth.user.username || "", owner_prv_active_key: state.newsafe.token.split(/ /)[1] })
      // window.location.reload();
      await actions.accountManagement.createPool({
        ticker: wantedTicker,
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  const available = !existingTicker.code;
  const canProceed = wantedTicker && !error && available;

  return (
    <>
      <p className="header-2">
        You have no pool (yet).
        <br />
      </p>
      <p>
        Create one now to allow anyone express their trust in your reputation.
        <br />
        The ticker you will create next is going to be the name of your personal
        currency.
      </p>
      <br />

      <Input
        onChange={onChange}
        placeholder="newcoin ticker"
        suffix={<CrossCircleErr />}
      />
      <div
        className={
          "text-center vertical-space " +
          (error ? " ant-form-item-has-error" : "")
        }
      >
        {error ||
          (wantedTicker && (
            <>
              Ticker {wantedTicker} is {available ? "available" : "taken"}
            </>
          ))}
      </div>
      {canProceed && (
        <div className="text-center">
          <ProgressButton
            disabledWhileProgress={true}
            actionName="accountManagement"
            onClick={done}
          >
            Create my ticker
          </ProgressButton>
          <br />
          <br />
          Please note: the ticker cannot be changed except at the mainnet golive
        </div>
      )}

      {/* </Form.Item> */}
    </>
  );
};
