import { useActions, useAppState } from "../overmind/overmind";
import { useEffect, useMemo } from "react";
import { IOView } from "@newstackdev/iosdk/dist/types";
import AreawithTooltipClosed from "./AreawithTooltipClosed";
import { useParams, Link } from "react-router-dom";
import { Row, Col } from "antd";
import { PoolInfoDataRow } from "@newstackdev/iosdk/dist/Components/UserWidget";

const AsPage: <T>(Component: IOView<T>, defaults) => IOView<T> = (Component, defaults?: any) => () => <Component {...useParams() as any} {...defaults} />

const niceRound = (num) => Math.round(num * 100) / 100;


type RowChartInputs = { ticker: string, width?: number, height?: number, showData?: boolean };
type ViewTemplate = (props: { RowChart: IOView }) => IOView<RowChartInputs>;



export const StatsTemplate:
    ({ title, cols, RowComponent }: { title: string, cols: string[], RowComponent: IOView<any>, request: string }) => IOView =
    ({ title, cols, RowComponent, request }) => () => {
        const state = useAppState();
        const actions = useActions();

        useEffect(() => {
            actions.history[request](); //.stakedOutTop();
        }, []);

        return <div className="iosdk-newcoin-history-report">
            <h1>{title || "Tokens with highest TVL"}</h1>
            {/* {request} */}
            <Row align="middle" className="table table-heading">
                <Col lg={8}>{cols[0]}</Col>
                <Col lg={8}>{cols[1]}</Col>
                <Col lg={8}>&nbsp;</Col>
            </Row>
            {(state.history[request]?.data?.Rows || []).map(
                r => <RowComponent row={r} />
                // <Link to={`/stats/ticker/${r.token}`}>
                //     <Row align="middle" className="table">
                //         <Col lg={8}>{r.token}</Col>
                //         <Col lg={8}>{niceRound(r.total)}</Col>
                //         <Col lg={8}>{<TokenStats ticker={r.token} />}</Col>
                //     </Row>
                // </Link>
            )}
        </div>
    }

export const StatsStakedInTopTotal = StatsTemplate({
    title: "Tokens with highest TVL",
    cols: ["Token", "TVL"],
    request: "stakedInTopSum",
    RowComponent: ({ row }) =>
        <Link to={`/stats/ticker/${row.token}`}>
            <Row align="middle" className="table">
                <Col lg={8}>{row.token}</Col>
                <Col lg={8}>{niceRound(row.total)}</Col>
                <Col lg={8}>{<TokenStats ticker={row.token} />}</Col>
            </Row>
        </Link>
});

export const StatsStakedOutTopTotal = StatsTemplate({
    title: "Accounts with highest stake",
    cols: ["Account", "Stake"],
    request: "stakedOutTopSum",
    RowComponent: ({ row }) =>
        <Link to={`/stats/account/${row.account}`}><Row align="middle" justify="space-around" className="table">
            <Col lg={8}>{row.account}</Col>
            <Col lg={8}>{niceRound(row.total)}</Col>
            <Col lg={8}>{<AccountStats account={row.account} />}</Col>
        </Row></Link>
});

export const StatsStakedInTopCount = StatsTemplate({
    title: "Most actively invested tokens",
    cols: ["Ticker", "Stake count"],
    request: "stakedInTopCount",
    RowComponent: ({ row }) =>
        <Link to={`/stats/ticker/${row.token}`}><Row align="middle" justify="space-around" className="table">
            <Col lg={8}>{row.token}</Col>
            <Col lg={8}>{niceRound(row.total)}</Col>
            <Col lg={8}>{<TokenStats ticker={row.token} />}</Col>
        </Row></Link>
});

export const StatsStakedOutTopCount = StatsTemplate({
    title: "Most active accounts",
    cols: ["Account", "Stake count"],
    request: "stakedOutTopCount",
    RowComponent: ({ row }) =>
        <Link to={`/stats/account/${row.account}`}><Row align="middle" justify="space-around" className="table">
            <Col lg={8}>{row.account}</Col>
            <Col lg={8}>{niceRound(row.total)}</Col>
            <Col lg={8}>{<AccountStats account={row.account} />}</Col>
        </Row></Link>
});

export const StatsStakedOutRecent = StatsTemplate({
    title: "Most recent operations",
    cols: ["Time", "Token", "Staked by"],
    request: "stakedRecent",
    RowComponent: ({ row }) =>
        <Row align="middle" justify="space-around" className="table">
            <Col lg={3}>{row.timestamp.split(/\./)[0]}</Col>
            <Col lg={7}>{niceRound(row.total)} {row.token}</Col>

            <Col lg={7}>
                <Link to={`/stats/ticker/${row.token}`}>
                    <TokenStats ticker={row.token} />
                </Link>
            </Col>

            <Col lg={7}>{
                <Link to={`/stats/account/${row.account}`}>
                    {row.account}
                    <AccountStats account={row.account} />
                </Link>
            }</Col>
        </Row>
});


export const TokenStats: IOView<RowChartInputs> =
    ({ ticker, width, height, showData }) => {
        const state = useAppState();
        const actions = useActions();
        // const { ticker: tickerFromParams } = useParams<{ticker: string}>();

        useEffect(() => {
            actions.history.stakedInByTicker({ ticker });
        }, [ticker])

        const data: any = [].slice.call((state.history.inTokenStats[ticker] || {}).data?.Rows || []);

        const chart = () =>
            <AreawithTooltipClosed key={ticker} width={width || 200} height={height || 80} data={data} />;

        if (!showData)
            return chart();

        return <div className="iosdk-newcoin-history account-stats">
            <Row justify="space-around">
                <Col lg={8}>
                    {chart()}
                </Col>
                <Col lg={12}>
                    Ticker stats
                    <h1>{ticker}</h1>
                    <div className="pool-info">
                        <PoolInfoDataRow stakeInNewsafe={true} pool={{ code: ticker }} />
                    </div>
                </Col>
            </Row>
            <h2>History</h2>

            {showData &&
                <>
                    <Row className="table table-heading">
                        <Col lg={5}>Date</Col>
                        <Col lg={5}>Account</Col>
                        <Col lg={5}>Stake</Col>
                        <Col lg={5}>Total</Col>
                        <Col lg={4}>&nbsp;</Col>
                    </Row>
                    {[...data].reverse().map(d =>
                        <Link to={`/stats/account/${d.account}`}>
                            <Row align="middle" className="table">
                                <Col lg={5}>{d.timestamp.split(/\./)[0]}</Col>
                                <Col lg={5}>{d.account}</Col>
                                <Col lg={5}>{niceRound(d.val)}</Col>
                                <Col lg={5}>{niceRound(d.total)}</Col>
                                <Col lg={4}>
                                    <AccountStats account={d.account} width={200} height={80} />
                                </Col>
                            </Row>
                        </Link>)}
                </>
            }
        </div>
    }


// const TokenStats = () => StatsTemplate();

const MaybeLink: IOView<{ to: boolean, withLink: boolean } & any> =
    ({ withLink, to, children, ...props }) =>
        withLink ?
            <Link to={to} {...props}>{children}</Link> :
            <>{children}</>;

export const AccountStats: IOView<{
    account: string,
    width?: number,
    height?: number,
    showData?: boolean,
    showChart?: boolean,
    infoOptions?: {
        showAccountInfo?: boolean,
        showRowChart?: boolean,
        rowLink?: boolean,
        // showHistory?: boolean
    }
}> = ({ account, width, height, showData, showChart, infoOptions }) => {
    const state = useAppState();
    const actions = useActions();
    // const { ticker: tickerFromParams } = useParams<{ticker: string}>();

    infoOptions = infoOptions || {};

    useEffect(() => {
        !state.history.outAccountStats[account]?.Rows &&
            actions.history.stakedOutByAccount({ account });
    }, [account])

    const rd = [].slice.call((state.history.outAccountStats[account] || {})?.data?.Rows || []);
    const data = rd.length ? rd : 
        [
            {timestamp: new Date(0).toISOString(), total: 0, val: 0, token: ""},
            {timestamp: new Date(100000).toISOString(), total: 0, val: 0, token: ""}
        ];

    const chart = () => //<>{JSON.stringify(state.history.outAccountStats[account])} {}</>
        showChart ?? true ?
            <AreawithTooltipClosed key={account} width={width || 200} height={height || 80} data={data} />
            :
            <></>;

    if (!showData)
        return chart();

    const rowCol = infoOptions.showRowChart ? 5 : 6;

    return <div className="iosdk-newcoin-history account-stats">
        <Row justify="space-around">
            <Col lg={infoOptions.showAccountInfo ? 8 : 24}>
                {chart()}
            </Col>
            {infoOptions.showAccountInfo ? <Col lg={12}>
                Account stats
                <h1>{account}</h1>
            </Col> : <></>}
        </Row>

        {<Row className="table table-heading">
            <Col lg={rowCol}>Date</Col>
            <Col lg={rowCol}>Ticker</Col>
            <Col lg={rowCol}>Stake</Col>
            <Col lg={rowCol}>Total</Col>
            {infoOptions.showRowChart ? <Col lg={4}>&nbsp;</Col> : <></>}
        </Row>}
        {!rd.length ? "" : [...data].reverse().map(d =>
            <MaybeLink to={`/stats/ticker/${d.token}`} withLink={infoOptions?.rowLink}>
                <Row className="table" align="middle">
                    <Col lg={rowCol}>{d.timestamp.split(/\./)[0]}</Col>
                    <Col lg={rowCol}>{d.token}</Col>
                    <Col lg={rowCol}>{niceRound(d.val)}</Col>
                    <Col lg={rowCol}>{niceRound(d.total)}</Col>
                    {infoOptions?.showRowChart ? <Col lg={4}><TokenStats ticker={d.token} /></Col> : <></>}
                </Row>
            </MaybeLink>)
        }
    </div>
}

export const TokenStatsPage = AsPage(TokenStats, { height: 250, width: 400, showData: true });
export const AccountStatsPage = AsPage(AccountStats, { height: 250, width: 400, showData: true, infoOptions: { showAccountInfo: true, showRowChart: true, rowLink: true } });


