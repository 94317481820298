export const HomeIcon: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.06" y="0.06" width="29.88" height="29.88" stroke-width="0.12" />
    <path
      d="M25.8692 14.7198C20.0462 14.7914 15.3047 10.1044 15.2979 4.13237C15.2979 3.41883 14.7078 3.42522 14.7201 4.13237L14.716 4.27434C14.5704 10.2893 9.90075 14.7773 4.13104 14.7198C3.41994 14.7198 3.42631 15.3119 4.13104 15.2995C10.1778 15.2927 14.5813 19.8756 14.7201 25.8669C14.7078 26.5758 15.2983 26.5786 15.2979 25.8669C15.3411 19.8505 20.0708 15.2069 25.8692 15.3C26.5757 15.3119 26.5784 14.7193 25.8692 14.7202V14.7198Z"
      fill="black"
    />
  </svg>
);
