import { Modal } from "antd";
import { useAppState } from "../../overmind/overmind";
import Form from "antd/lib/form";
import {
	useCachedPool,
	useCachedPoolByCode,
} from "@newstackdev/iosdk/dist/hooks/useCached";
import { CreatePool } from "./CreatePool";
import { TokenStats } from "@newstackdev/iosdk-newcoin-stats";

export const ViewPool = () => {
	const state = useAppState();
	// const pool = state.api.auth.user.newcoinTicker || "";
	const pool = useCachedPool({ owner: state.api.auth.user.username })?.code;

	return (
		<div className="ns-pool-wrapper">
			<TokenStats
				width={300}
				height={140}
				showData={true}
				ticker={pool.toUpperCase()}
			/>
			{/* <h1>Total in pool: {pool.total.quantity}</h1>
        <p>Pool code: { pool.code}</p>
        <p>Created: {(pool as any).creation_date}</p>
        <p>Last updated: {(pool as any).last_update_date}</p>
        <div style={{ paddingTop: 5 }}> */}
			{/* </div> */}
		</div>
	);
};

export const Pool = () => {
	const state = useAppState();
	// const pool = useCachedPool({ owner: state.api.auth.user.username });
	const pool = state.api.auth.user.newcoinTicker || ""; //useCachedPool({ owner: state.api.auth.user.username });
	const ind = state.indicators.specific["accountManagement.createPool"];
	const inProgress = !pool && ind >= 0;
	const wasInProgress = ind === 0;

	return (
		<>
			{/* {JSON.stringify(state.api.auth.user)} */}
			{inProgress && (
				<>
					{/* @ts-ignore */}
					<Modal
						title="Pool is being created"
						visible={inProgress}
						cancelButtonProps={{ style: { display: "none" } }}
					>
						<>
							Pool creation in progress. This should take less
							than 30 seconds. Please wait or continue exploring
							and come back later.
						</>
					</Modal>
					{wasInProgress && (
						<h3>
							Pool was created, waiting for your profile to
							update...
						</h3>
					)}
					<br />
					<br />
					This should take less than one minute. Please wait or
					continue exploring and come back later.
				</>
			)}
			{pool ? (
				<ViewPool />
			) : inProgress ? (
				<>Waiting...</>
			) : (
				<CreatePool />
			)}
			{/* {JSON.stringify(pool)}  */}
		</>
	);
};
