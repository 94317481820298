import {
  ApplicationReadResponse,
  ErrorResponse,
  SessionTokenRequest,
} from "src/lib/api-client";
import { Action } from "../../iosdk/overmind/overmind";
import effects from "./effects";
import { ApiType } from "./types";
import isEmpty from "lodash/isEmpty";
import { blockExplorerUrl } from "@newstackdev/iosdk/dist/Components/Links";

const getAppInfo: Action<{ username: string }> = async (
  { state, effects },
  app
) => {
  try {
    console.log("Getting app for user " + app.username);
    const r = await state.newgraphAuth.api.getAppInfo.getAppInfoList({
      username: app.username || "",
    });

    console.log("Got app info: " + r.data);

    state.newgraphAuth.apps[app.username] = r.data;
  } catch (_ex) {
    const ex: { error: ErrorResponse } = _ex as any;
    if (ex.error && ex.error.statusCode === 404) {
      return;
    }
    throw ex;
  }
};

const approvedAppsStorageName = (state) => {
  const currUser = state.api.auth.user.username;
  const storageName = `newstack-approved-apps-${currUser}`;
  return storageName || "";
};

const removeAppAccess: Action<{ username: string }> = async (
  { state, actions },
  { username }
) => {
  try {
    const approved = { ...state.newgraphAuth.approvedApps };
    delete approved[username];

    const updatedApprovedStr = JSON.stringify(approved);

    const sn = approvedAppsStorageName(state);

    localStorage.setItem(sn, updatedApprovedStr);

    actions.ux.showNotification({
      message:
        "App uninstalled from this browser. Install again by hitting Get App.",
    });

    state.newgraphAuth.approvedApps = approved;
  } catch (_ex) {
    const ex: { error: ErrorResponse } = _ex as any;
    if (ex.error && ex.error.statusCode === 404) {
      return;
    }
    throw ex;
  }
};

const approveAppAccess: Action<{ username: string }> = async (
  { state, effects },
  { username }
) => {
  try {
    const sn = approvedAppsStorageName(state);

    const _approved = localStorage.getItem(sn) || "{}";
    const approved = JSON.parse(_approved);
    const expiry = new Date(
      new Date().getTime() + 30 * 24 * 60 * 6000
    ).toISOString();

    const updatedApproved = { ...approved, [username]: { expiry } };
    const updatedApprovedStr = JSON.stringify(updatedApproved);

    localStorage.setItem(sn, updatedApprovedStr);
  } catch (_ex) {
    const ex: { error: ErrorResponse } = _ex as any;
    if (ex.error && ex.error.statusCode === 404) {
      return;
    }
    throw ex;
  }
};

export const catchSocialLogin: Action<{
  location: { pathname: string; search: string };
}> = async ({ state, actions }, { location: { pathname } }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams);

  if (state.api.auth.user.status === "admitted" && state.api.auth.authorized) {
    actions.routing.historyPush({ location: "/connect" });
  }

  if (
    pathname === "/connect" ||
    (pathname === "/signup/domain" && params?.token)
  ) {
    const user =
      state.api.auth.user?.username || state.firebase.user?.phoneNumber;

    if (!isEmpty(params?.token) && !user) {
      window.localStorage.setItem("newsafe-auth-token", `${params?.token}`);
      await actions.app.signIn({ jwt: `${params?.token}` || "" });

      actions.onboarding.updateForm(state.api.auth.user);
      state.onboarding.isSocialFirstSignUp = true;
      // window.sessionStorage.setItem("isSocialFirstSignUp", "true");
    }
    // else

    //TODO: delete if preregistered_premium is not wanted anymore
    // if (state.api.auth.user && state.api.auth.user.status !== "preregistered") {
    //   const userStatus = state.api.auth.user.status;
    //   const loc =
    //     userStatus === "preregistered_premium"
    //       ? "/signup/domain"
    //       : "/signup/hash";
    //   actions.routing.historyPush({ location: loc });
    // }
  }
};

const refreshSessionToken: Action<{ jwt: string }> = async (
  { state, effects, actions },
  { jwt: _jwt }
) => {
  const jwt = _jwt || state.newsafe.token;

  // alchemy
  const authApi = state.newgraphAuth.api;
  authApi.setSecurityData({ token: jwt });

  const tokenRes =
    await authApi.refreshSessionToken.refreshSessionTokenCreate();

  if (!tokenRes.data.jwt) {
    console.error("Couldn't refresh newsafe token");
  }

  const nw = tokenRes.data.jwt || "";

  // nw && (state.newsafe.token = nw);
  actions.newsafe.authorize({ jwt: nw });

  // referer: "newsafe.org",
  // appOwner: "newsafe",
  // scopes: json(app.consumerScopes || []),
  // });

  //   try {
  //     const r =
  //       await state.newgraphAuth.api.generateSessionToken.generateSessionTokenCreate(
  //         sessionTokenRequest
  //       );
  //     state.newgraphAuth.sessionToken = r.data;
  //   } catch (_ex) {
  //     const ex: { error: ErrorResponse } = _ex as any;
  //     if (ex.error && ex.error.statusCode === 404) {
  //       return;
  //     }
  //     throw ex;
  //   }
  // };
};

const generateSessionToken: Action<SessionTokenRequest> = async (
  { state, effects },
  sessionTokenRequest
) => {
  try {
    if (!state.newgraphAuth.sessionToken?.jwt?.length) return;

    const r =
      await state.newgraphAuth.api.generateSessionToken.generateSessionTokenCreate(
        sessionTokenRequest,
        {
          headers: {
            Authorization: state.newgraphAuth.sessionToken?.jwt,
          },
        }
      );
    state.newgraphAuth.sessionToken = r.data;
  } catch (_ex) {
    const ex: { error: ErrorResponse } = _ex as any;
    if (ex.error && ex.error.statusCode === 404) {
      return;
    }
    throw ex;
  }
};

const STORED_AUTH_REDIRECT_REQUEST = "newsafe-auth-redirect-request";

const saveAuthRedirectRequest: Action = ({ state }) => {
  localStorage.setItem(
    STORED_AUTH_REDIRECT_REQUEST,
    state.newgraphAuth.originalQuery || ""
  );
};

const restoreAuthRedirectRequest: Action<undefined> = ({ state }) => {
  state.newgraphAuth.originalQuery =
    localStorage.getItem(STORED_AUTH_REDIRECT_REQUEST) || "";
};

const consideredNew = (date) =>
  new Date(date).toISOString() >
  new Date(new Date().getTime() - 15 * 60000).toISOString();

type StakeWebsocketMessage = {
  updated: string;
  type: string;
  payload: {
    message: string;
    sender: string;
    recipient: string;
    ticker: string;
    quantity: string;
    tx: string;
  };
};

const updateOriginalRedirectRequest: Action<{ query: string }> = (
  { actions, state, effects },
  { query }
) => {
  state.newgraphAuth.originalQuery = query;
};

const onInitializeOvermind: Action = ({
  actions,
  state,
  effects,
  reaction,
}) => {
  const q = window.location.search;
  //  || actions.accountManagement.restoreAuthRedirectRequest() || "";
  if (q) {
    state.newgraphAuth.originalQuery = q;
    actions.newgraphAuth.saveAuthRedirectRequest();
  } else {
    actions.newgraphAuth.restoreAuthRedirectRequest();
  }

  localStorage.setItem(STORED_AUTH_REDIRECT_REQUEST, q);

  actions.newgraphAuth.catchSocialLogin({ location: window.location });

  const messageProcessors = {
    newcoin: {
      stake: (m: StakeWebsocketMessage) => {
        const updated = m.updated;
        if (!consideredNew(updated)) return;

        const { sender, recipient, quantity, ticker, tx } = m.payload;
        const currUser = state.api.auth.user.username;
        const opening =
          recipient == currUser
            ? `New stake of ${quantity} ${ticker} in your pool by ${sender}`
            : sender == currUser
            ? `You purchased ${quantity} ${ticker} by ${recipient}`
            : `${ticker} (${recipient}'s pool) received ${quantity} ${ticker} stake from ${sender}`;

        const message = `${opening}. Click this message to check the transaction.`;
        effects.ux.notification.info({
          type: "info",
          duration: 10,
          message,
          onClick: () => {
            debugger;
            window.open(blockExplorerUrl.newscan(tx), "newscan");
          },
        });

        console.log(m);
      },
    },
  };

  effects.websockets.emitter.on("modelUpdated", (e) => {
    if (e.backlog) return;

    if (
      e.payload.updatedProps?.includes("newcoinTicker") &&
      e.payload.value.newcoinTicker
    )
      effects.ux.notification.info({
        type: "info",
        duration: 10,
        message: `You now have a personal pool. Your token is ${(
          e.payload.value.newcoinTicker as string
        ).toUpperCase()}. Click to go to your pool.`,
        onClick: () => {
          // debugger;
          // window.open(blockExplorerUrl.newscan("/pool"), "newscan")
          actions.routing.historyPush({ location: "/pool" });
        },
      });
  });

  reaction(
    (state) => [
      state.api.auth.user,
      state.indicators.specific["api.authorize"],
    ],
    async () => {
      const auth = state.api.auth;
      const loc = state.routing.location;

      if (!state.api.auth.attempted) return;

      const isSigninRoute = /^\/(signin)/.test(loc) || /^\/?$/.test(loc);
      const isAuthRoute =
        (/^\/(connect|signup)/i.test(loc) && loc !== "/people") ||
        /^\/?$/.test(loc);

      // if (auth.admitted && (isAuthRoute || isSigninRoute))
      //   return actions.routing.historyPush({ location: "/console" });

      // if (auth.user.status == "preregistered_premium" && !isAuthRoute)
      //   return actions.routing.historyPush({ location: "/signup/domain" });

      // if(auth.user.status == "imported")
      //   return;

      // if (!auth.admitted && auth.attempted) {
      //   await actions.auth.logout();
      //   return actions.routing.historyPush({ location: "/" });
      // }
    }
  );

  const env = state.config.env.env;
  const apiDomain = env === "prod" ? "api" : `api-${state.config.env.stage}`;

  state.newgraphAuth.api = effects.newgraphAuth.initialize(
    `https://${apiDomain}.newsafe.org/v1`
  );

  reaction(
    (state) => {
      return [state.firebase.token, state.api.auth.admitted];
    },
    async () => {
      console.log(
        state.firebase.token,
        state.api.auth.admitted,
        state.newsafe.token
      );

      if (!state.api.auth.admitted || state.newsafe.token) {
        return;
      }

      const token = state.firebase.token;

      state.newgraphAuth.sessionToken = { jwt: token };
      // effects.newgraphAuth.updateToken(token);
      // actions.api.auth.authorize({ token: token })

      await actions.newgraphAuth.generateSessionToken({
        referer: "newsafe",
        redirectUrl: "newsafe",
        appOwner: "newsafe",
        scopes: ["*"],
      });

      const newNewsafeToken = state.newgraphAuth.sessionToken?.jwt || "";

      // effects.newgraphAuth.updateToken(newNewsafeToken);
      await actions.newsafe.authorize({ jwt: newNewsafeToken });

      // actions.api.auth.authorize({ jwt: newNewsafeToken });

      // if (/^\/?$/.test(state.routing.location))
      //   actions.routing.historyPush({ location: "/welcome" });
    }
  );

  reaction(
    (state) => [state.api.auth.admitted],
    () => {
      if (state.api.auth?.admitted)
        state.newgraphAuth.approvedApps = JSON.parse(
          localStorage.getItem(approvedAppsStorageName(state)) || "{}"
        );

      console.log(state.newgraphAuth.sessionToken);
      if (!isEmpty(state.newgraphAuth.sessionToken.jwt)) return;

      state.newgraphAuth.sessionToken = { jwt: effects.api.getCurrentToken() };
    }
  );

  reaction(
    (state) => [state.newsafe.token],
    () => {
      console.log("Newsafe token changed: ", state.newsafe.token);
      const token = state.newsafe.token; // [state.newsafe.token, state.firebase.token].find(Boolean) || "";

      state.newgraphAuth.sessionToken = { jwt: token };

      // if (/^\/?$/.test(state.routing.location))
      //   actions.routing.historyPush({ location: "/welcome" });
    }
  );

  reaction(
    (state) => state.newgraphAuth.sessionToken,
    () => {
      effects.newgraphAuth.updateToken(state.newgraphAuth.sessionToken.jwt);
    }
  );
};

export const newgraphAuth = {
  actions: {
    onInitializeOvermind,
    getAppInfo,
    approveAppAccess,
    removeAppAccess,
    generateSessionToken,
    updateOriginalRedirectRequest,
    saveAuthRedirectRequest,
    restoreAuthRedirectRequest,
    catchSocialLogin,
    refreshSessionToken,
  },
  state: {
    api: {} as ApiType,
    sessionToken: {} as any,
    originalQuery: "",
    apps: {} as Record<string, ApplicationReadResponse>,
    approvedApps: {} as Record<string, { expiry: string }>,
  },
  effects,
};

// reaction(
//   (state) => state.websockets.messages.newcoin,
//   async () => {
//     console.log(+"Message:",);
//     const m = state.websockets.messages.newcoin[0];
//     const processor = messageProcessors[m.original.type][m.original.payload.message];
//     processor && processor(m.original);
//   }
// )
// stake_received: (m) => {
//   const tx = m.payload.txid;

//   const sender = m.payload.sender.username;
//   const recipient = m.payload.recipient.username;
//   const amount = m.payload.amount;

//   const currUser = state.api.auth.user.username;
//   const opening =
//     recipient == currUser ? `New stake (+${amount * .83}) in your pool by ${sender}` :
//       sender == currUser ? `You staked ${amount} GNCO in ${recipient}` :
//         `${recipient}'s pool received a new ${amount * .83} stake from ${sender}`;

//   const message = `${opening}. Click this message to check the transaction.`

//   // actions.ux.showNotification({ message });
//   effects.ux.message.info(message);
//   effects.ux.message.info({
//     type: "info", content: message, onClick: () => {
//       debugger;
//       window.open(blockExplorerUrl.newscan(tx), "newscan")
//     }
//   });

// }
