export const CrossCircle = () => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_112_1030)">
      <path
        d="M29.1245 11.4265L28.2136 10.5156L19.6277 19.1015L11.0354 10.5156L10.1245 11.4265L18.7104 20.0189L10.1245 28.6047L11.0354 29.5156L19.6277 20.9298L28.2136 29.5156L29.1245 28.6047L20.5387 20.0189L29.1245 11.4265Z"
        fill="#000000"
      />
    </g>
    <rect
      x="1"
      y="1"
      width="37"
      height="37"
      rx="18.5"
      stroke="#ffffff"
      strokeWidth="2"
    />
    <defs>
      <clipPath id="clip0_112_1030">
        <rect
          width="19"
          height="19"
          fill="white"
          transform="translate(10.1245 10.5156)"
        />
      </clipPath>
    </defs>
  </svg>
);
