import { CollapseProps, Collapse } from "antd";
import PlusIcon from "../Icons/PlusIcon.svg";

export const FAQs: React.FC<{
  category: string;
  items: CollapseProps["items"];
  removeBottomMargin?: boolean;
}> = ({ category, items, removeBottomMargin }) => {
  return (
    <div
      className={removeBottomMargin ? "" : "u-margin-bottom-large"}
      style={{ width: " 100%" }}
    >
      <div
        className="u-margin-bottom-medium"
        style={{
          fontFamily: "Romie-Regular",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "42.5px",
        }}
      >
        {category}
      </div>
      <Collapse
        items={items}
        expandIcon={({ isActive }) => (
          <img
            src={PlusIcon}
            alt={PlusIcon}
            style={
              isActive
                ? {
                    transform: "rotate(45deg)",
                    transition: "transform 0.3s ease-in-out",
                  }
                : {
                    transform: "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }
            }
          />
        )}
      />
    </div>
  );
};
