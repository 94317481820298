export const TumblrIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_541_7798)">
      <path
        d="M15.4428 25.3596C11.7612 25.3596 8.99989 23.4676 8.99989 18.9166V11.6555H5.625V7.71811C9.30669 6.74656 10.8407 3.57621 11.0453 0.814941H14.8804V7.05336H19.3291V11.6555H14.8804V17.9962C14.8804 19.8882 15.8519 20.5529 17.386 20.5529H19.5336V25.3596H15.4428Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_541_7798">
        <rect
          width="13.9086"
          height="24.5446"
          fill="white"
          transform="translate(5.625 0.814941)"
        />
      </clipPath>
    </defs>
  </svg>
);
