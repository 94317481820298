import FullScreenLoading from "../Components/FullScreenLoading";
import { useAppState, useActions } from "../overmind/overmind";
import { useEffect } from "react";

export const OAuthConnect = () => {
  const state = useAppState();
  const actions = useActions();

  const defaultUser = {
    newcoinTicker: "",
    displayName: "",
    email: "",
    description: "",
    website: "",
    twitter: "",
    discord: "",
    instagram: "",
    tumblr: "",
    soundcloud: "",
    consentPrivacyPolicy: "",
    phone: state.firebase.user?.phoneNumber || "",
    legacyToken: "",
  };
  useEffect(() => {
    if (
      state.api.auth.admitted &&
      (state.api.auth.user.status === "registered" ||
        state.api.auth.user.status === "admitted")
    ) {
      actions.onboarding.authToNewlife({});
    } else if (
      state.auth.authenticated &&
      state.firebase.token &&
      state.indicators.specific["firebase.handleAuthChange"] === 0
    ) {
      actions.user.create({
        user: defaultUser,
      });
    }
  }, [
    state.api.auth.admitted,
    state.auth.authenticated,
    state.firebase.token,
    state.indicators.specific["firebase.handleAuthChange"],
  ]);
  return <FullScreenLoading />;
};
