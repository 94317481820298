import { useActions, useAppState } from "../overmind/overmind";
import { useEffect } from "react";
import { ApplicationReadResponse } from "../lib/api-client";

export const useCachedApplication = (
  { owner }: { owner?: string },
  force?: boolean
) => {
  const state = useAppState();
  const actions = useActions();

  useEffect(() => {
    if (
      !state.newgraphAuth.sessionToken.jwt ||
      !state.api.auth.admitted ||
      !owner
    ) {
      return;
    }
    const curr = state.newgraphAuth.apps[owner];
    state.api.auth.admitted &&
      (force || !curr?.id) &&
      actions.newgraphAuth.getAppInfo({ username: owner });
  }, [state.newgraphAuth.sessionToken, owner]);

  const curr = state.newgraphAuth.apps[owner || ""] || { id: -1 };
  return owner && curr.id ? curr : ({ author: {} } as ApplicationReadResponse);
};
