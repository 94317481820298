export const ShareIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 13 16"
    fill="none"
  >
    <path
      d="M9.57895 0.399902H1.36842C0.612368 0.399902 0 1.03454 0 1.81808V11.7454H1.36842V1.81808H9.57895V0.399902ZM11.6316 3.23627H4.10526C3.34921 3.23627 2.73684 3.8709 2.73684 4.65445V14.5817C2.73684 15.3653 3.34921 15.9999 4.10526 15.9999H11.6316C12.3876 15.9999 13 15.3653 13 14.5817V4.65445C13 3.8709 12.3876 3.23627 11.6316 3.23627ZM11.6316 14.5817H4.10526V4.65445H11.6316V14.5817Z"
      fill="black"
    />
  </svg>
);
