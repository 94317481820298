import { Tabs } from "antd";
import { StatsStakedInTopCount, StatsStakedInTopTotal, StatsStakedOutTopCount, StatsStakedOutTopTotal } from "../Components/Stats";

export const PoolsHome = () => {
    return <div className="iosdk-newcoin-history">
        <h1>Pools</h1>
        <Tabs
            type="card"
            destroyInactiveTabPane={true}
        >
            <Tabs.TabPane key="a" tab="Top active">
                <StatsStakedInTopCount />
            </Tabs.TabPane>

            <Tabs.TabPane key="b" tab="Top TVL">
                <StatsStakedInTopTotal />
            </Tabs.TabPane>

        </Tabs>
    </div>
}

export const InvestorsHome = () => {
    return <div className="iosdk-newcoin-history">
        <h1>Investors</h1>
        <Tabs
            type="card"
            destroyInactiveTabPane={true}
        >
            <Tabs.TabPane key="a" tab="Top TVL">
                <StatsStakedOutTopTotal />
            </Tabs.TabPane>
            <Tabs.TabPane key="b" tab="Most active">
                <StatsStakedOutTopCount />
            </Tabs.TabPane>
        </Tabs>
    </div>
}