import { Form, Input, Row } from "antd";
import { IOView } from "@newstackdev/iosdk/dist/types";
import { useLocation } from "react-router-dom";
import { NextButton } from "./NextButton";
import { UnsuccessfulLoginModal } from "../UnsuccessfulLoginModal";
import { useActions, useAppState } from "../../../overmind/overmind";
import { useCallback, useEffect, useState } from "react";
import { validUrl } from "@newstackdev/iosdk/dist/utils/urlHelpers";
import isEmpty from "lodash/isEmpty";
import FullScreenLoading from "../../../Components/FullScreenLoading";
import LogoOnlyIcon from "../../../assets/Icons/LogoOnlyIcon";

export const LinkHash: IOView = () => {
  const queryString = useLocation().search;
  const actions = useActions();
  const state = useAppState();
  const [isErrorSubmit, setIsErrorSubmit] = useState(false);
  const [isHashVerificationProcessing, setIsHashVerificationProcessing] =
    useState(false);

  const done = async () => {
    const code = state.onboarding.form.inviteHash;
    try {
      setIsHashVerificationProcessing(true);
      await actions.onboarding.verifyHash({ inviteHash: code || "" });
      if (state.onboarding.inviteHashVerified) {
        const location = "/signup/managementService";
        actions.routing.historyPush({ location });
      }
      setIsHashVerificationProcessing(false);
    } catch (e) {
      setIsErrorSubmit(true);
      setIsHashVerificationProcessing(false);
    }
  };

  const authorizeIndicator = state.indicators.specific["api.auth.authorize"];

  useEffect(() => {
    if (
      state.onboarding.form.couponCode ||
      ["preregistered_premium", "imported"].includes(
        state.api.auth.user.status || ""
      )
    ) {
      actions.routing.historyPush({ location: "/signup/domain" });
      return;
    }

    if (!state.api.auth.admitted) return;

    // user is signed up
    actions.ux.showNotification({
      message: `Welcome back ${state.api.auth.user.username}.`,
    });
    actions.routing.historyPush({ location: "/console" });
  }, [state.api.auth.user.id, authorizeIndicator]);

  useEffect(() => {
    const urlParams = new URLSearchParams(queryString);
    actions.onboarding.stopMetamaskFlow();
    if (!isEmpty(urlParams.get("invite"))) {
      actions.onboarding.updateForm({
        inviteHash: urlParams.get("invite") as string,
      });
      done();
    }
  }, []);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let val = e.target.value;
      if (validUrl(e.target.value)) {
        const params = new URL(e.target.value).searchParams;
        val = params.get("invite") || e.target.value;
      }
      actions.onboarding.updateForm({ inviteHash: val });
    },
    [actions]
  );

  return authorizeIndicator === 1 || isHashVerificationProcessing ? (
    <FullScreenLoading />
  ) : (
    <>
      <Row
        style={{
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 50,
        }}
      >
        <LogoOnlyIcon spin={true} width="180px" height="180px" />
      </Row>
      <Form onFinish={done}>
        <Form.Item>
          <div className="header-1 text-center u-margin-bottom-large">
            Enter your invite code:
          </div>
          <Input
            name="hash"
            onChange={onChangeHandler}
            defaultValue={state.onboarding.form.inviteHash}
            style={{ textAlign: "center" }}
          />
        </Form.Item>
        <Form.Item>
          <NextButton
            isErrorSubmit={isErrorSubmit}
            nextProps={{ text: "Next", command: () => done() }}
            visible={!isEmpty(state.onboarding.form.inviteHash)}
            contentDescription={
              // !state.onboarding.legacyToken && !state.auth.authenticated ? (
              //   <div>
              //     Enter hash or nft credentials. Or
              //     <Link
              //       to="/auth/newlife-members"
              //       className="paragraph-2u nl-onboarding-link"
              //     >
              //       {" "}
              //       click here{" "}
              //     </Link>
              //     if you are a member from the Newlife mobile app.
              //   </div>
              // ) : null
              null
            }
          />
        </Form.Item>
      </Form>
      {/* !{JSON.stringify(state.onboarding.legacyToken)}! */}

      <UnsuccessfulLoginModal redirect={actions.routing.historyPush} />
    </>
  );
};
