import { useActions, useAppState } from "../../overmind/overmind";
import { Button } from "antd";
import { SOCIAL_MEDIA } from "./constants";
import { ContentCard } from "../../Components/ContentCard";
import X from "../../assets/Icons/X.svg";
import Checkmark from "../../assets/Icons/Checkmark.svg";

export const ProfileSocialMediaVerify: React.FC = () => {
  const state = useAppState();
  const actions = useActions();

  const onVerifySocial = async (provider: string, username?: string) => {
    if (provider === "instagram") {
      const instagramVerified = //@ts-ignore
        (await state.api.client.auth.instagramCreate({ handler: username }))
          .data.isVerified;
      if (!instagramVerified) {
        actions.ux.showNotification({
          message: "Instagram verification was not successful",
        });
      }
    } else {
      const token = state.newsafe.token.substring(
        state.newsafe.token.indexOf(" ") + 1
      );

      window.open(
        `${state.config.settings.newgraph.baseUrl}/auth/provider/${provider}?token=${token}&redirect_url=${window.location}`,
        "_self"
      );
    }
  };

  const verify = (provider: string, username?: string): React.ReactNode => {
    const sanitizedProvider = provider.replace(/[0-9]/g, "");
    return !isSocialVerified(sanitizedProvider) ? (
      <>
        <Button
          onClick={() => onVerifySocial(provider, username)}
          style={{
            borderRadius: 47,
            width: "66px",
            height: "28px",
            fontSize: "10px",
            padding: 0,
          }}
        >
          VERIFY
        </Button>
      </>
    ) : (
      <img src={Checkmark} alt={Checkmark} />
    );
  };

  const isSocialVerified = (social: string) => {
    return state.api.auth.user.verifiedSocialIds?.includes(social);
  };

  const getProfileSocialMediaVerify = () => {
    return SOCIAL_MEDIA.map((social) => {
      const isVerified = isSocialVerified(social);

      return (
        <ContentCard
          title="X"
          description="Formerly Twitter"
          logoUrl={X}
          externalRedirectLink={
            isVerified
              ? `https://twitter.com/${state.api.auth.user[social]}`
              : undefined
          }
          customButton={verify(
            social === "twitter" || social === "tumblr" ? `${social}2` : social
          )}
        />
      );
    });
  };

  return <>{getProfileSocialMediaVerify()}</>;
};
