export const CloseModalIcon = () => (
	<svg
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.475 10.75L4.975 6.25L0.45 1.775L2.05 0.2L6.55 4.675L11 0.224999L12.55 1.75L8.1 6.2L12.575 10.725L10.975 12.3L6.5 7.8L2.025 12.275L0.475 10.75Z"
			fill="#BEBEBE"
		/>
	</svg>
);
