import Newcoin from "../Icons/Footer-Newcoin.svg";
export const Footer = () => (
  <footer className="footer-3">
    <div className="padding-global" />
    <div className="container-large-2">
      <div className="footer_top">
        <div className="footer_top-right">
          <a href="index.html" className="footer-logo_link-2 w-inline-block">
            <img
              src={Newcoin}
              loading="lazy"
              alt=""
              className="footer_logo-copy-3"
            />
          </a>
          <div className="div-block-78"></div>
          <a href="#" className="nav_link-copy-copy-copy-2 border">
            Apply for $NCO
          </a>
          <div className="div-block-78"></div>
          <div className="div-block-78"></div>
          <div className="div-block-76">
            <div className="div-block-77">
              <a href="#" className="text-size-tiny-copy-3">
                Terms and Conditions
              </a>
              <a
                href="https://newsafe.org/privacy_policy"
                target="_blank"
                className="text-size-tiny-copy-3"
              >
                Privacy Policy
              </a>
            </div>
            <div className="div-block-77">
              <a
                href="https://www.new.foundation/"
                target="_blank"
                className="text-size-tiny-copy-3"
              >
                Copyright © 2023 Newfoundation.{" "}
              </a>
              <div className="text-size-tiny-2">
                Built with meaning in mind.
              </div>
            </div>
          </div>
          <div className="div-block-78"></div>
        </div>
        <div className="footer_links-list-2">
          <div className="div-block-79">
            <div className="footer_links-column-2">
              <div className="text-size-medium text-weight-semibold">
                Ecosystem
              </div>
              <a
                href="https://www.newcoin.org/"
                target="_blank"
                className="footer_link-2025"
              >
                NEWCOIN
              </a>
              <a
                href="https://www.newlife.io/"
                target="_blank"
                className="footer_link-2025"
              >
                Newlife
              </a>
              <a
                href="https://www.newforum.xyz/"
                target="_blank"
                className="footer_link-2025"
              >
                Newforum
              </a>
              <a
                href="https://newsafe.org/"
                target="_blank"
                className="footer_link-2025"
              >
                Newsafe
              </a>
            </div>
            <div className="footer_links-column-2">
              <div className="text-size-medium text-weight-semibold">
                Social
              </div>
              <a
                href="https://twitter.com/newforum_nco"
                target="_blank"
                className="footer_link-2025"
              >
                Twitter
              </a>
              <a
                href="https://www.instagram.com/newcoin_nco/"
                target="_blank"
                className="footer_link-2025"
              >
                Instagram
              </a>
              <a
                href="https://blog.newcoin.org"
                target="_blank"
                className="footer_link-2025"
              >
                Medium
              </a>
              <a
                href="https://lenster.xyz/u/newforum"
                target="_blank"
                className="footer_link-2025"
              >
                Lenster
              </a>
              <a
                href="https://www.youtube.com/channel/UCWvHyau1nIJBffmaaj6FmbQ"
                target="_blank"
                className="footer_link-2025"
              >
                YouTube
              </a>
            </div>
            <div className="footer_links-column-2">
              <div className="text-size-medium text-weight-semibold">
                Resources
              </div>
              <a
                href="https://github.com/newfound8ion/"
                target="_blank"
                className="footer_link-2025"
              >
                GitHub
              </a>
              <a
                href="https://docs.google.com/document/d/17brnNGEOaRedthFskkFbK__hsIE7JndwlzLi_WvbW6o/edit"
                target="_blank"
                className="footer_link-2025"
              >
                White Paper
              </a>
              <a
                href="https://developer.newcoin.org/"
                target="_blank"
                className="footer_link-2025"
              >
                DEVELOPER
              </a>
            </div>
          </div>
          <div className="div-block-79">
            <div className="footer_links-column-2">
              <div className="text-size-medium text-weight-semibold">
                For builders
              </div>
              <a
                href="https://developer.newcoin.org/"
                target="_blank"
                className="footer_link-2025"
              >
                Developer
              </a>
              <a
                href="https://github.com/newfound8ion/newcoin-evm"
                target="_blank"
                className="footer_link-2025"
              >
                Newcoin EVM
              </a>
              <a
                href="https://www.newforum.xyz/identity-summer-hackathon-2023"
                target="_blank"
                className="footer_link-2025"
              >
                Identity hackathon
              </a>
              <a
                href="https://www.newcoin.org/identity-hackathon"
                target="_blank"
                className="footer_link-2025"
              >
                Hackathon results
              </a>
            </div>
            <div className="footer_links-column-2">
              <div className="text-size-medium text-weight-semibold">Forum</div>
              <a
                href="https://www.newforum.xyz/interviews"
                target="_blank"
                className="footer_link-2025"
              >
                Interviews
              </a>
              <a
                href="https://www.newforum.xyz/communities"
                target="_blank"
                className="footer_link-2025"
              >
                Research groups
              </a>
              <a
                href="https://www.newforum.xyz/category/stack"
                target="_blank"
                className="footer_link-2025"
              >
                Stack Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/apps-games"
                target="_blank"
                className="footer_link-2025"
              >
                Apps &amp; Games Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/ethics-safety"
                target="_blank"
                className="footer_link-2025"
              >
                ETHICS &amp; SAFETY Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/philosophy"
                target="_blank"
                className="footer_link-2025"
              >
                PHILOSOPHY Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/finance"
                target="_blank"
                className="footer_link-2025"
              >
                ECONOMICS Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/culture"
                target="_blank"
                className="footer_link-2025"
              >
                CULTURE Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/coordination"
                target="_blank"
                className="footer_link-2025"
              >
                coordination Forum
              </a>
              <a
                href="https://www.newforum.xyz/category/design"
                target="_blank"
                className="footer_link-2025"
              >
                DESIGN Forum
              </a>
            </div>
            <div className="footer_links-column-2">
              <div className="text-size-medium text-weight-semibold">
                NEWCOIN
              </div>
              <a
                href="https://www.newcoin.org/use"
                target="_blank"
                className="footer_link-2025"
              >
                USE
              </a>
              <a
                href="https://www.newcoin.org/learn"
                target="_blank"
                className="footer_link-2025"
              >
                LEARN
              </a>
              <a
                href="https://www.newcoin.org/build"
                target="_blank"
                className="footer_link-2025"
              >
                BUILD
              </a>
              <a
                href="https://www.newcoin.org/"
                data-w-id="ea28ff2d-d188-4c2b-8250-95d1b5bfce57"
                target="_blank"
                className="footer_link-2025"
              >
                ECOSYSTEM
              </a>
              <a
                href="https://www.newforum.xyz/"
                target="_blank"
                className="footer_link-2025"
              >
                FORUM
              </a>
              <a
                href="https://www.newcoin.org/apply"
                target="_blank"
                className="footer_link-2025"
              >
                APPLY
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
