import { Button, Modal, Tooltip, Row, Col } from "antd";
import { Clipboard } from "../Icons/Clipboard";
import { CrossCircle } from "../Icons/CrossCircle";
// import { PinterestIcon } from "../Icons/PinterestIcon";
import {
  // PinterestShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
} from "react-share";
import { PostReadResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { QRCodeSVG } from "qrcode.react";
import { ShareIcon } from "../Icons/ShareIcon3";
import { TelegramIcon } from "../Icons/socials/TelegramIcon";
import { TumblrIcon } from "../Icons/socials/TumblrIcon";
import { TwitterIcon } from "../Icons/socials/TwitterIcon";
// import { useContentImageUrl } from "./MediaComponents/ImageMediaComponent";
import CopyToClipboard from "react-copy-to-clipboard";
import React, { useCallback, useEffect, useState } from "react";
import "../styles/ShareModal.css";

const ShareModalContent: React.FC<{
  label?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, label, style }) => {
  return (
    <div
      className="white-box-content-row"
      style={{
        paddingBottom: 15,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        ...style,
      }}
    >
      {label ? (
        <label
          className="header-2"
          htmlFor={`share-btn-${label}`}
          style={{ width: "100%", cursor: "pointer" }}
        >
          {label}
        </label>
      ) : (
        false
      )}
      {children}
    </div>
  );
};

export const Share: React.FC<{
  currentPostProps?: PostReadResponse;
  urlToShare?: string;
  isProduction?: boolean;
}> = ({ currentPostProps, urlToShare, isProduction }) => {
  const URL =
    !currentPostProps && urlToShare
      ? urlToShare
      : isProduction
      ? window.location.href
      : `https://app-dev.newlife.io${window.location.pathname}`;
  const [isVisible, setIsVisible] = useState(false);
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [copyToClipboard, setCopyToClipboard] = useState<boolean>(false);
  const [viewQR, setViewQR] = useState(false);

  const onCancelHandler = useCallback(() => {
    setIsVisible(false);
    setViewQR(false);
  }, [setIsVisible, setViewQR]);

  useEffect(() => {
    if (currentPostProps?.tags) {
      const tags = currentPostProps.tags.map(
        (tag) => tag.value?.replace(/ /g, "") as string
      );
      setHashtags(tags);
    }
  }, [currentPostProps?.tags]);

  return (
    <>
      {isVisible ? (
        <Modal
          closeIcon={<CrossCircle />}
          visible={isVisible}
          onCancel={onCancelHandler}
          className="white-box-modal"
          style={{ pointerEvents: "all" }}
          footer={null}
          width={375}
          title={
            <>
              <div
                className="header-2"
                style={{
                  paddingBottom: 15,
                }}
              >
                Share to
              </div>
              <p className="paragraph-2r">
                Share this content to your networks!
              </p>
            </>
          }
          modalRender={() => (
            <>
              {viewQR ? (
                <div
                  style={{
                    paddingBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <QRCodeSVG value={URL} size={238} />
                </div>
              ) : (
                <>
                  <ShareModalContent label="X">
                    <TwitterShareButton
                      url={URL}
                      title={currentPostProps?.title}
                      className="share-btn-Twitter"
                      id="share-btn-Twitter"
                    >
                      <TwitterIcon />
                    </TwitterShareButton>
                  </ShareModalContent>
                  <ShareModalContent label="Tumblr">
                    <TumblrShareButton
                      url={URL}
                      title={currentPostProps?.title}
                      className="share-btn-Tumblr"
                      id="share-btn-Tumblr"
                      tags={hashtags}
                    >
                      <TumblrIcon />
                    </TumblrShareButton>
                  </ShareModalContent>
                  <ShareModalContent label="Telegram">
                    <TelegramShareButton
                      url={URL}
                      title={currentPostProps?.title}
                      className="share-btn-Telegram"
                      id="share-btn-Telegram"
                    >
                      <TelegramIcon />
                    </TelegramShareButton>
                  </ShareModalContent>
                  {/* <ShareModalContent label="Pinterest">
                <PinterestShareButton
                  url={URL}
                  title={currentPostProps?.title}
                  className="share-btn-Pinterest"
                  id="share-btn-Pinterest"
                  media={contentImageUrl}
                >
                  <PinterestIcon />
                </PinterestShareButton>
              </ShareModalContent> */}
                  <ShareModalContent label="Copy">
                    <CopyToClipboard text={URL}>
                      <Tooltip
                        title={"copied to clipboard"}
                        placement="right"
                        visible={copyToClipboard}
                        afterVisibleChange={(visible) => {
                          if (visible) {
                            setTimeout(() => {
                              setCopyToClipboard(false);
                            }, 500);
                          }
                        }}
                      >
                        <button
                          className="copy-to-clipboard-button share-btn-Copy"
                          id="share-btn-Copy"
                          onClick={() => {
                            setCopyToClipboard(true);
                          }}
                        >
                          <Clipboard fill={"#000000"} />
                        </button>
                      </Tooltip>
                    </CopyToClipboard>
                  </ShareModalContent>
                </>
              )}
              <ShareModalContent
                style={{ paddingBottom: "none", justifyContent: "center" }}
              >
                <Button
                  className="ant-btn ant-btn-primary"
                  onClick={() => {
                    setViewQR(!viewQR);
                  }}
                >
                  {viewQR ? "Back" : "QR Code"}
                </Button>
              </ShareModalContent>
            </>
          )}
        />
      ) : (
        false
      )}
      <div onClick={() => setIsVisible(true)} className="share-button-icon">
        <Row
          style={{
            width: "100%",
            height: "25.19px",
            gap: "10px",
          }}
          align="bottom"
          justify="center"
        >
          <span style={{ marginBottom: "2px", height: "21px" }}>
            <ShareIcon />
          </span>
          <p>Share</p>
        </Row>
      </div>
    </>
  );
};
