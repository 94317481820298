import { Action } from "../../iosdk/overmind/overmind";
import { UserCreateRequest } from "@newstackdev/iosdk-newgraph-client-js";
// import { WizardInput } from "./wizardStateMachine.ts.bak";
import { debounce, pipe } from "overmind";
import isEmpty from "lodash/isEmpty";

// const reduceState: (st: State) => WizardInput = ({
//   api: { auth },
//   auth: { authenticated },
//   config: { featureFlags },
//   flows: {
//     user: {
//       create: {
//         legacyToken,
//         form: { username, couponCode, inviteHash, phone },
//         formUsernameIsAvailable,
//         inviteHashVerified,
//         isLegacyUpdateOngoing,
//         metamaskFlow,
//       },
//     },
//   },
// }: State) => {
//   return {
//     ...auth,
//     ...{ authenticated },
//     formUsername: username || "",
//     inviteHash: inviteHash || "",
//     subscribed: !!auth.user?.subscriptionStatus?.startsWith("io-domain-sale"),
//     formUsernameIsAvailable,
//     user: auth.user,
//     formPhone: phone,
//     inviteHashVerified,
//     metamaskFlow,
//     legacyToken,
//     isLegacyUpdateOngoing,
//     featureFlags: featureFlags,
//     couponCode: couponCode || "",
//   };
// };

export const onInitializeOvermind: Action<any> = async ({
  actions,
  effects,
  state,
  reaction,
}) => {
  const ls = window.localStorage.getItem("legacyAuthToken");
  const legacy: {
    form: UserCreateRequest;
    legacyToken: string;
    updated: number;
  } = ls && JSON.parse(ls);

  // state.onboarding.isSocialFirstSignUp =
  //   window.location.pathname == "/connect" &&
  //   (window.sessionStorage.getItem("isSocialFirstSignUp") === "true");

  if (legacy?.updated && Date.now() - (legacy.updated || 0) < 30 * 60000) {
    // const cf = state.onboarding;
    // cf.form = legacy.form;
    // cf.legacyToken = legacy.legacyToken;
    //actions.auth.firebaseRequestToken
    effects.api.updateToken(legacy.legacyToken);
    state.firebase.token = legacy.legacyToken;
    await actions.api.auth.authorize();
    await actions.onboarding.startLegacyImport();
  }
  if (
    !state.auth.authenticated &&
    !state.api.auth.authorized &&
    !isEmpty(state.firebase?.token)
  ) {
    // actions.routing.historyPush({ location: "/welcome" });
  }

  // reaction(reduceState, actions.flows.user.create._wizardReact);
  // reaction(
  //   (s) => ({
  //     auth: s.api.auth,
  //     username: s.flows.user.create.form.username,
  //     legacyImportOngoing: s.flows.user.create.isLegacyUpdateOngoing,
  //   }),
  //   ({ username, auth, legacyImportOngoing }) => {
  //     if (!auth.authorized || legacyImportOngoing)
  //       actions.flows.user.create.checkAvailability({ username });
  //   }
  // );
};

export const updateForm: Action<
  Partial<UserCreateRequest & { couponCode?: string; inviteHash?: string }>
> = //({ state }, val) =>
  pipe(
    // debounce(200),
    ({ state }, val) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...val,
      };
    }
  );

const usernameToAccount = (username: string) => {
  // adjust any string to newlife account name
  username = username.replace(/\.nco/, "").replace(/[^a-z1-5]/g, "");

  if (/^[0-9]+/.test(username)) username = "z" + username;

  return username.replace(/^(.{0,9})(.*)$/, (...m) => `${m[1]}.io`);
};
export const startLegacyImport: Action = //({ state }, val) =>
  async ({ state, actions }) => {
    const user = { ...state.api.auth.user }; // wont be available after logout
    const legacyToken = state.firebase.token;

    const username = usernameToAccount(user.username || user.displayName || "");

    actions.onboarding.updateForm({
      ...user,
      username,
    });
    state.onboarding = {
      ...state.onboarding,
      legacyToken: legacyToken,
      legacyUsername: user.username || "",
      isLegacyUpdateOngoing: true,
    };

    await actions.auth.logout({ noRouting: true });
    actions.routing.historyPush({ location: "/signup/domain" });

    if (window.sessionStorage.getItem("e2eTestMode") === "true")
      await actions.api.auth.authorize({ token: legacyToken });

    window.localStorage.setItem(
      "legacyAuthToken",
      JSON.stringify({ legacyToken, updated: Date.now() })
    );
  };

export const stopLegacyImport: Action<{ noRedirect?: boolean } | undefined> = (
  _,
  params = {}
) => {
  window.localStorage.setItem("legacyAuthToken", "");
  if (!params.noRedirect) window.location.pathname = "/";
};

// export const wizardStepPrev: Action = ({ state }) => {
//   state.flows.user.create.wizard.send("PREV", reduceState(state));
// };

// const autoRedirectFrom = [
//   "",
//   "/",
//   "/auth",
//   "/signup/auth",
//   "/signup/domain",
//   "/signup/create",
//   "/signup/subscibe",
// ];

// export const _wizardReact: Action<WizardInput> = // ({ state, actions }, i: WizardInput) =>
//   pipe(debounce(300), ({ state, actions }: Context, i: WizardInput) => {
//     if (
//       state.api.auth.authorized &&
//       ["registered", "admitted"].includes(state.api.auth.user?.status || "") &&
//       !state.flows.user.create.isLegacyUpdateOngoing &&
//       state.api.auth.user?.username !== state.flows.user.create.form.username &&
//       autoRedirectFrom.includes(state.routing.location)
//       // &&
//       // state.newcoin.pools["CGY"]
//     ) {
//       actions.routing.historyPush({ location: "/welcome" });
//     }

//     state.flows.user.create.wizard.send("UPDATE", i);

//     const subscription = (state.api.auth.user.subscriptionStatus || "").split(
//       /_/
//     );
//     if (
//       subscription[0] === "io-domain-sale" &&
//       state.flows.user.create.form.username != subscription[1] &&
//       isEmpty(state.api.auth.user.username)
//     ) {
//       actions.flows.user.create.updateForm({ username: subscription[1] });
//       if (
//         !state.flows.user.create.isLegacyUpdateOngoing &&
//         !state.api.auth.authorized
//       ) {
//         actions.routing.historyPush({ location: "/signup/create" });
//       }
//     }
//   });

// export const wizardStepNext: Action = ({ state, actions }) => {
//   const { nextLink, hasNext, current, hasPrev } = state.onboarding.wizard;
//   if (!isEmpty(state.onboarding.wizard.nextLink)) {
//     actions.routing.historyPush({
//       location: state.onboarding.wizard.nextLink as string,
//     });
//   }

//   state.onboarding.progressedSteps = state.onboarding.progressedSteps.filter(
//     (step) => step.current !== current
//   );
//   state.onboarding.progressedSteps.push({
//     nextLink,
//     hasNext,
//     current,
//     hasPrev,
//   });

// state.flows.user.create.wizard.send("NEXT", reduceState(state));
// state.flows.user.create.wizard.send("UPDATE", reduceState(state));
// };

export const preregisterCreate: Action<{
  noRouting?: boolean;
  user?: UserCreateRequest;
}> = async ({ actions }, params) => {
  const u = params.user || ({} as UserCreateRequest);
  return actions.api.user.create({
    ...params,
    user: u,
    preregisterCreate: true,
  });
};

export const authToNewlife: Action<{}> = async ({ actions, state }) => {
  const goToRedirectUrl = () => {
    window.location.replace(
      "https://os.newcoin.org/feed" +
        "?newsafe_jwt=" +
        state.newgraphAuth.sessionToken.jwt
    ); // security hole, must be checked against user
  };

  await actions.newgraphAuth.approveAppAccess({
    username: "newgraph.io",
  });

  await actions.newgraphAuth.generateSessionToken({
    appOwner: "newgraph.io",
    redirectUrl: "https://os.newcoin.org",
    referer: "newgra.ph",
    scopes: [],
  });

  goToRedirectUrl();
};

export const create: Action<{
  noRouting?: boolean;
  user: UserCreateRequest;
  legacyToken?: string;
  couponCode?: string; // coupons provide special conditions
  inviteHash?: string; // a personal or non-personal invite code (not always a hash)
}> = async ({ actions, state }, params) => {
  // await new Promise(res => setTimeout(() => res(''), 3000));
  // return;
  try {
    await actions.user.create({ ...params });
    actions.onboarding.stopLegacyImport({ noRedirect: true });
    await actions.onboarding.authToNewlife({});
  } catch (e) {
    console.log(e);
  }

  // actions.api.user.cache({ user: u })
};

export const checkAvailability: Action<{ username?: string }> = pipe(
  // filter((_, { username }) => username.length > 6),
  debounce(300),
  async ({ actions, state }, { username }) => {
    const avstate = state.onboarding.usernameAvailability;
    if (!username) {
      return;
    }
    avstate[username] = "checking";

    // const promises = [
    //     state.api.client.user.availabilityList({ username }),
    //     actions.newcoin.getAccountHitory({ user: { username } })
    // ];

    // const [r, nc] = await Promise.all(promises);  //actions.onboarding._userCreate({ ...params });
    const r = await state.api.client.user.availabilityList({ username });

    const currUser = state.api.auth.user;
    const isOpenSeaOffer = !isEmpty(r.data?.offer);
    const availableOnNewlife =
      r.data?.available ||
      (currUser?.username === username && currUser.status === "imported");

    const av = availableOnNewlife
      ? isOpenSeaOffer
        ? "availableOnOpenSea"
        : state.onboarding.metamaskFlow
        ? "unavailable"
        : "available"
      : "unavailable"; // && availableOnNewcoin

    avstate[username] = av;
  }
);

export const verifyHash: Action<{ inviteHash: string }> = pipe(
  async ({ state, actions, effects }, { inviteHash }) => {
    try {
      const couponCode = ["NEWBETA", "NEWSAFE8"].includes(inviteHash)
        ? inviteHash
        : undefined;

      const res = couponCode
        ? {}
        : (await state.api.client.user.inviteHashList({ hash: inviteHash }))
            .data;

      if (!res) throw new Error();

      if (!couponCode && !/^\+/.test((res as any).phone))
        (res as any).phone = "+" + (res as any).phone;

      state.onboarding.inviteHashVerified = true;
      //@ts-ignore
      // const updateFormWithRes = !state.onboarding.isSocialFirstSignUp;

      actions.onboarding.updateForm({
        ...(res ? res : {}),
        inviteHash,
        couponCode,
      });
      window.localStorage.setItem("inviteData", JSON.stringify(res));
      //@ts-ignore
      // actions.onboarding.wizardStepNext();
    } catch (e) {
      if ((e as any).status == 404) {
        actions.routing.historyPush({ location: "/signup/notInvited" });
        return;
      }
      effects.ux.notification.error({
        message: "Something went wrong, please try again",
      });
    }
  }
);

export const startMetamaskFlow: Action<{}> = pipe(
  async ({ state, actions }) => {
    state.onboarding.metamaskFlow = true;
    actions.routing.historyPush({ location: "/signup/auth" });
  }
);

export const stopMetamaskFlow: Action = pipe(async ({ state }) => {
  state.onboarding.metamaskFlow = false;
});

export const bypassInviteFlow: Action<{ couponCode: string }> = pipe(
  async ({ state, actions }, { couponCode }) => {
    actions.onboarding.updateForm({ couponCode });
    state.onboarding.inviteHashVerified = true;
    actions.onboarding.wizardStepNext();
  }
);

export const startSocialSignUp: Action = pipe(({ state }) => {
  state.onboarding.isSocialFirstSignUp = true;

  // state.onboarding.wizard.send("UPDATE", reduceState(state));
});
