import { ContentCard } from "./ContentCard";
import { UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { useActions, useAppState } from "../overmind/overmind";
import { Flash } from "./Powerup";
import { useState } from "react";
import { message } from "antd";
import LoadingAnimation from "../assets/Icons/loading-animation.gif";
import { useContentImageUrl } from "./MediaComponents/useContentImageUrl";
import { NotificationButton } from "./ConsoleComponents/NotificationButton";

interface UserCardWithPowerupProps {
  user: UserReadPublicResponse;
}

export const UserCardWithPowerup: React.FC<UserCardWithPowerupProps> = ({
  user,
}) => {
  const state = useAppState();
  const action = useActions();
  const [messageApi, contextHolder] = message.useMessage();
  const [isPowerupProcessing, setIsPowerupProcessing] = useState(false);
  const isConsole = window.location.pathname === "/console";
  const admitted = state.api.auth.admitted;
  const poweredUpUserImg = useContentImageUrl({
    id: user?.id,
    contentUrl: user.contentUrl,
  });

  return (
    <>
      {contextHolder}
      <ContentCard
        logoRedirectLink={
          isConsole
            ? `/user/${user.username}?isConnection=true&isConsole=true`
            : `/user/${user.username}?isConnection=true`
        }
        contentId={user.id}
        contentUrl={user.contentUrl}
        title={user.displayName || user.username}
        description={user.description}
        customButton={
          <div
            className="ns-content-card-custom-btn"
            onClick={async () => {
              if (!admitted) {
                action.routing.historyPush({ location: "/signup" });
              }
              if (user.username) {
                try {
                  setIsPowerupProcessing(true);
                  await state.api.client.user.userRateCreate({
                    targetId: user.id,
                    value: 1,
                  });
                  setIsPowerupProcessing(false);
                  messageApi.open({
                    content: (
                      <NotificationButton
                        type="powerup-notification"
                        messageMode
                        notificationImg={poweredUpUserImg}
                      />
                    ),
                    duration: 5,
                  });
                } catch (e) {
                  setIsPowerupProcessing(false);
                  messageApi.open({
                    type: "error",
                    content: "Powerup failed, please try again",
                    duration: 5,
                  });
                }
              }
            }}
          >
            {isPowerupProcessing ? (
              <img
                src={LoadingAnimation}
                alt={LoadingAnimation}
                style={{ width: "28px", height: "28px" }}
              />
            ) : (
              <Flash flashColor={user.username ? "black" : "grey"} />
            )}
          </div>
        }
      />
    </>
  );
};
