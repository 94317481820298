export const FacebookIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_541_7760)">
      <path
        d="M24.5446 12.2723C24.5446 5.49449 19.0501 0 12.2723 0C5.49449 0 0 5.49449 0 12.2723C0 18.3977 4.48778 23.4749 10.3548 24.3955V15.8198H7.23874V12.2723H10.3548V9.56857C10.3548 6.49282 12.187 4.79387 14.9902 4.79387C16.3325 4.79387 17.7373 5.03356 17.7373 5.03356V8.0537H16.1899C14.6654 8.0537 14.1899 8.99977 14.1899 9.97125V12.2723H17.5935L17.0494 15.8198H14.1899V24.3955C20.0568 23.4749 24.5446 18.3977 24.5446 12.2723Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_541_7760">
        <rect width="24.5446" height="24.5446" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
