import { Button, Col, FormInstance, Modal, Row } from "antd";
import { CrossCircle } from "@newstackdev/iosdk/dist/Components/Icons/CrossCircle";
import { useAppState, useActions } from "../../overmind/overmind";
import { CloseOutlined } from "@ant-design/icons";
import { CopyClipboardHashInput } from "./CopyClipboardHash";

const UserInviteInfoDialog = ({
  hash,
  setVisible,
  visible,
  disableFooter = false,
}: {
  invitedUsername?: string;
  setStatus?: React.Dispatch<
    React.SetStateAction<"start" | "inprogress" | "failed" | "done">
  >;
  form?: FormInstance<any>;
  hash: string | undefined;
  setVisibleForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  disableFooter?: boolean;
}) => {
  const state = useAppState();

  if (hash) {
    navigator.clipboard.writeText(
      window.location.origin + "/signup" + `?invite=${hash}`
    );
  }

  const user = state.api.auth.user;

  const numberOfInvites = user.availableInvites || 0;

  return (
    <Modal
      closeIcon={<CrossCircle />}
      visible={visible}
      footer={null}
      className="user-invite-info_modal"
      maskClosable
      mask
      style={{ pointerEvents: "all" }}
      onCancel={() => {
        setVisible(false);
        window.location.reload();
      }}
      modalRender={() => (
        <>
          <div
            className="user-invite-info_close-button"
            onClick={() => {
              setVisible(false);
              window.location.reload();
            }}
          >
            <CloseOutlined className="user-invite-info_close-icon" />
          </div>
          <div className="user-invite-info_modal-content">
            <Row className="user-invite-info_modal-root-row">
              <Col>
                <p className="header-1 text-center u-margin-bottom-large">
                  Invitations
                </p>
                <p className="header-2 text-center">
                  {numberOfInvites - 1 > 0 ? numberOfInvites - 1 : 0} invites
                  left
                </p>
              </Col>
            </Row>

            {numberOfInvites > 0 ? (
              <>
                <Row className="user-invite-info_modal-root-row">
                  <Col>
                    <p className="paragraph-1r">
                      Copy this link to your primary browser. This code is a
                      one-off and can be used on sign up.
                    </p>
                  </Col>
                </Row>
                <Row className="user-invite-info_modal-root-row">
                  {hash && <CopyClipboardHashInput hash={hash} />}
                </Row>
                {!disableFooter && (
                  <Row className="user-invite-info_modal-root-row">
                    <Col>
                      <Button
                        type="primary"
                        className="u-margin-top-medium"
                        onClick={() => {
                          setVisible(false);
                          window.location.reload();
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <p className="header-2b">Oops you are out of invites!</p>
            )}
          </div>
        </>
      )}
    ></Modal>
  );
};

export default UserInviteInfoDialog;
