export const ShareIcon: React.FC = () => (
  <svg
    width="10"
    height="19"
    viewBox="0 0 26 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 6957">
      <path
        id="Vector"
        d="M18.2998 8.1L12.9998 2.8L7.69981 8.1L6.2998 6.7L12.9998 0L19.6998 6.7L18.2998 8.1Z"
        fill="black"
      />
      <path id="Vector_2" d="M12 1.3999H14V22.3999H12V1.3999Z" fill="black" />
      <path
        id="Vector_3"
        d="M23 34.3999H3C1.3 34.3999 0 33.0999 0 31.3999V13.3999C0 11.6999 1.3 10.3999 3 10.3999H10V12.3999H3C2.4 12.3999 2 12.7999 2 13.3999V31.3999C2 31.9999 2.4 32.3999 3 32.3999H23C23.6 32.3999 24 31.9999 24 31.3999V13.3999C24 12.7999 23.6 12.3999 23 12.3999H16V10.3999H23C24.7 10.3999 26 11.6999 26 13.3999V31.3999C26 33.0999 24.7 34.3999 23 34.3999Z"
        fill="black"
      />
    </g>
  </svg>
);
