import { Action } from "../../iosdk/overmind/overmind";

const verifyAddress: Action<{
  message: { payload: any; signature: string };
}> = async ({ actions, reaction, state }, { message }) => {
  //@ts-ignore
  const resp = await state.api.client.auth.evmVerifyAccountCreate(message);
  console.log(resp);
};

export default {
  actions: {
    verifyAddress,
  },
  state: {
    inProgress: false as boolean,
  },
  effects: {},
};
